import React, {useEffect, useRef, useState, useContext, useCallback} from "react";
import StockChart from "./StockChart";
import GameContext from "../contexts/GameContext";
import {useSearchParams} from "react-router-dom";
import LayoutConst from "../const/layoutConst";
import httpClient from "../shared/HttpClient";

/**
 *
 * @param {object} props { width: any, height: any }
 * @returns
 */
export default function ChartPane(props) {
    const now = new Date();
    const [paneWidth, setPaneWidth] = useState();
    const [paneHeight, setPaneHeight] = useState(LayoutConst.page.height / 2);
    const [chartData, setChartData] = useState([]);
    const [unit, setUnit] = useState('1d');
    const [begTs, setBegTs] = useState(parseInt(now / 1000) - 60 * 60 * 24 * 365);
    const [endTs, setEndTs] = useState(parseInt(now / 1000));
    const [searchParams, setSearchParams] = useSearchParams();

    const paneRef = useRef();

    const gameCtx = useContext(GameContext);

    useEffect(() => {
        setPaneWidth(paneRef.current.clientWidth);
    }, [window.innerWidth]);

    // useEffect(() => {
    //     setPaneHeight(paneRef.current.clientHeight);
    // }, [window.innerHeight]);

    useEffect(() => {
        if (gameCtx.viewStk && searchParams.get("stk")) {
            
            httpClient.get(`/stock/${gameCtx.viewStk.id}/hist-price?unit=${unit}&beg=${begTs}&end=${endTs}`)
                .then(function (data) {
                    let fmtData = data.map(e => ({
                        date: e.timeUTC,
                        high: e.high,
                        low: e.low,
                        open: e.open,
                        close: e.close,
                    }));
                    setChartData(fmtData);
                });
        }
    }, [gameCtx.viewStk, unit, begTs, endTs]);

    const [size, setSize] = useState({
        width: document.documentElement.clientWidth,
        hieght: document.documentElement.clientHeight
    })

    const onResize = useCallback(() => {
        setSize({
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
        })
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [])

    return (
        <div ref={paneRef} style={{textAlign: 'left'}}>
            <StockChart height={paneHeight} width={paneWidth} data={chartData}/>
        </div>
    )
}
