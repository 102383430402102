import React from "react";
import LoginPage from "../HomePage/LoginPage";
import NotAuth from "../ErrPage/403";
import NotFound from "../ErrPage/404";
import ResetPwPage from '../ResetPwPage/ResetPwPage';
import ForgotPwPage from '../ForgotPwPage/ForgotPwPage';
import FAQ from "../GamesPage/FAQ";

const publicRoutes = [
    {
        path: '/',
        element: <LoginPage/>,
        exact: true,
    },

    {
        path: '/reset-pw',
        element: <ResetPwPage />,
        exact: true,
    },
    {
        path:'/faq',
        element:<FAQ></FAQ>,
        exact:true,
    },

    {
        path: '/forgot',
        element: <ForgotPwPage />,
        exact: true,
    },

    {
        path: '/err/403',
        element: <NotAuth/>,
        exact: true,
    },

    {
        path: '/err/404',
        element: <NotFound/>,
    },
   
];

export default publicRoutes;
