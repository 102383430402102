import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import GameContext from '../contexts/GameContext';
import {wrapAuth} from "../shared/utils"
import httpClient from "./HttpClient";

import {styled, alpha} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {Button, Container} from '@mui/material';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LayoutConst from "../const/layoutConst";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import GamesIcon from '@mui/icons-material/Games';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LoginContext from '../contexts/LoginContext';

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 1),
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.selected,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AuthButton = wrapAuth(Button);

export default function NavBarBottom(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [userInfo, setUserInfo] = React.useState({});
    const [anchorPopover, setAnchorPopover] = React.useState(null);
    const [searchContent, setContent] = React.useState(null);
    const [searchRes, setRes] = React.useState([]);
    const [navbarStatus, setNavbar] = React.useState('inline');
    const location = useLocation();
    const [value, setValue] = React.useState(0);

    const {mode, setTheme} = props;

    useEffect(() => {
        if (userInfo === {} || userInfo === null || location.pathname === '/' || location.pathname === '/forgot') {
            setNavbar('none');
        } else {
            setNavbar('inline');
        }
    }, [location, userInfo]);

    useEffect(() => {
        setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
    }, [location]);

    useEffect(() => {
        if (searchContent) {
            httpClient.get('/stock/search?key=' + searchContent)
                .then(function (data) {
                    setRes(data);
                });
        } else {
            setRes([])
        }
    }, [searchContent]);

    const navigate = useNavigate();

    const gameCtx = useContext(GameContext)

    const [searchParams, setSearchParams] = useSearchParams();

    const clickSearchItem = (stockClicked) => {
        console.log("Stock：" + JSON.stringify(stockClicked) + " clicked!");
        setAnchorPopover(null);
        if (window.location.pathname.search('/mkt') === -1) navigate(`/mkt/${gameCtx.gid}?stk=${stockClicked.id}`);
        else setSearchParams({stk: stockClicked.id});
    }

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        handleMenuClose();
        console.log("Logging out");

        httpClient.post('/user/logout')
            .then(function (data) {
                localStorage.removeItem('userInfo');
                gameCtx.clear();
                navigate('/');
            });
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleSearchClick = (event) => {
        setAnchorPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorPopover(null);
    };

    const handleSearchContent = (event) => {
        setContent(event.target.value);
        setAnchorPopover(event.currentTarget);
    };

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    


    const loginCtx = useContext(LoginContext);
    let email = loginCtx.loginUser?.email || JSON.parse(localStorage.getItem('userInfo'))?.email;
    email = email ? email : '';

    const open = Boolean(anchorPopover);

    return (
      <>
        <AppBar position="fixed" sx={{display:{xs:navbarStatus,sm:'none'}}}>
         



          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
           
            
            <BottomNavigationAction label="Games" onClick={() => {
               navigate("/games");
             }}  icon={<GamesIcon />} />
              
               <BottomNavigationAction label="Portfolio" onClick={() => {
               navigate(`/acc/${gameCtx.gid}`);
             }} style={{ display: gameCtx.gid == null ? "none" : "" }} icon={<BusinessCenterIcon />} />
              

               
               <BottomNavigationAction label="Markets" onClick={() => {
               navigate(`/mkt/${gameCtx.gid}`);
             }} style={{ display: gameCtx.gid == null ? "none" : "" }} icon={<TrendingUpIcon />} />
              

              <BottomNavigationAction label="More" 
              aria-controls={open ? 'basic-menu1' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick1}
              icon={<MoreHorizIcon />} />
              
              <Menu
                id="basic-menu1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={()=>{navigate(`/faq`);}}>FAQ</MenuItem>
                <MenuItem onClick={()=>{window.location.href=`https://docs.google.com/forms/d/e/1FAIpQLSe0R6-zZf-MlgBS2jfdZCoMIS9WG8czsQihfBaAEUC8jP5-Aw/viewform?usp=pp_url&entry.567407623=${email}`}}>Bug Report</MenuItem>
                <MenuItem onClick={()=>{window.location.href=`https://docs.google.com/forms/d/e/1FAIpQLSeFIcp1vH2uOIfkarWJU24fo9Cq3KnSszC3UCASVKNy4vQn5g/viewform?usp=pp_url&entry.938703197=${email}`}}>Comment Form</MenuItem>
                <MenuItem onClick={()=>{window.location.href=`https://docs.google.com/forms/d/e/1FAIpQLScU_HUxJFA7K9Lkd1EXnbkzv2AWtJVaOS-frm1JK7AaDExtmg/viewform?usp=pp_url&entry.2086822270=${email}`}}>Naming Contest</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
           
           
           
           
            </Menu>
              
              
              
              <AuthButton
                role={["a", "r"]}
                variant="outlined"
                size="medium"
                className="font menu-items color-white"
                
              >
        
            <BottomNavigationAction label="" icon={ 
            <Button
                size="medium"
                className="font menu-items color-white"
                onClick={() => {
                    navigate("/game-mgt");
                  }}
              >
                   Manage Games
              </Button>} 
              />
              </AuthButton>
              <AuthButton
                role={["r"]}
                size="medium"
                className="font menu-items color-white"
                variant="outlined"
                sx={{ ml: 1 }}
                
              >
                
                <BottomNavigationAction label="" icon={ 
            <Button
                size="medium"
                className="font menu-items color-white"
                onClick={() => {
                    navigate("/app-mgt");
                  }}
              >
                   Manage App
              </Button>} 
              />
                </AuthButton>
            
          </BottomNavigation>

        </AppBar>
      </>
    );
}
