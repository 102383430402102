import React from "react";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function NotFound () {
    const navigate = useNavigate();
    const redirect = () => navigate('/games');

    return (
        <Alert severity="error" onClick={redirect} action={
            <Button color="inherit" variant="outlined" onClick={redirect}>
                Games
            </Button>
        }>
            Page not found! Click here back to games page!
        </Alert>
    );
}
