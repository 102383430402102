import React from "react";
import GamesPage from "../GamesPage/GamesPage";
import AccountPage from "../AccountPage/AccountPage";
import GameMgtPage from "../Management/GameMgtPage"
import AppMgtPage from "../Management/AppMgtPage"
import MarketPage from "../MarketPage/MarketPage";
import Profile from "../ProfilePage/Profile";

const privateRoutes = [
    {
        path: '/profile',
        element: <Profile/>,
        exact: true,
        role: ['u', 'a', 'r']
    },
    {
        path: '/games',
        element: <GamesPage/>,
        exact: true,
        role: ['u', 'a', 'r']
    },
    {
        path: '/acc/:gid',
        element: <AccountPage/>,
        exact: true,
        role: ['u', 'a', 'r']
    },
    {
        path: '/mkt/:gid',
        element: <MarketPage/>,
        exact: true,
        role: ['u', 'a', 'r']
    },
    {
        path: '/game-mgt',
        element: <GameMgtPage/>,
        exact: true,
        role: ['a', 'r']
    },
    {
        path: '/app-mgt',
        element: <AppMgtPage/>,
        exact: true,
        role: ['r']
    }
];

export default privateRoutes;
