import { Box } from "@mui/system";
import { validate, rules } from '../shared/validator';
import { useState, useEffect, useContext } from "react";
import { TextField } from '@mui/material';
import ExpiredDialog from "./ExpiredDialog";
import MsgDialog from "./MsgDialog";
import { useSearchParams } from "react-router-dom";
import { Button, Stack } from '@mui/material';
import httpClient from "../shared/HttpClient";


export default function ResetPwPage(props) {
    const [pw, setPw] = useState('');
    const [vldPw, setVldPw] = useState(true);
    const pwRules = [rules.required(), rules.password()];

    const [confPw, setConfPw] = useState('');
    const [vldConfPw, setVldConfPw] = useState(true);

    const [openMsg, setOpenMsg] = useState(false);
    const [msgClosable, setMsgClosable] = useState(true);
    const [msgTitle, setMsgTitle] = useState('');
    const [msgContent, setMsgContent] = useState('');

    const [openExp, setOpenExp] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState('');

    function validateFields() {
        let res = [];
        res.push(validate(pw, pwRules, setVldPw));
        setVldConfPw(confPw === pw ? true : false);
        res.push(confPw === pw);
        return res.every(e => e);
    }

    function promptInvTk() {
        setMsgClosable(false);
        setMsgTitle("Invalid Link");
        setMsgContent("This is an invalid link.")
        setOpenMsg(true);
    }

    function promptExpTk() {
        setOpenExp(true);
    }

    function promptInvPw() {
        setMsgClosable(true);
        setMsgTitle("Invalid password");
        setMsgContent("New password doesn't meet the requirement.")
        setOpenMsg(true);
    }

    function promptSvrErr() {
        setMsgClosable(false);
        setMsgTitle("Server error");
        setMsgContent("Sorry, there's a server error. Please try again later or report the issue to the administrator.")
        setOpenMsg(true);
    }

    function promptErr(res) {
        setMsgClosable(false);
        setMsgTitle("Error");
        setMsgContent('Error ' + res?.status + ': ' + res?.statusText);
        setOpenMsg(true);
    }

    function promptSuccess() {
        setMsgClosable(false);
        setMsgTitle("Success");
        setMsgContent('Your password has been reset.');
        setOpenMsg(true);
    }

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        var body = {
            token: token,
            newPw: pw
        };

        httpClient.post("/user/reset-pw", body)
            .then(function (data) {
                promptSuccess();
            })
            .catch(function (error) {
                switch (error.status) {
                    case 403:  // Invalid token
                        promptInvTk();
                        break;
                    case 410:  // Token expired
                        promptExpTk();
                        break;
                    case 422:  // Invalid password format
                        promptInvPw();
                        break;
                    case 500:  // Server error
                        promptSvrErr();
                        break;
                    default:
                        promptErr(error);
                        break
                }
            });
    };

    // Verify token and prompt on invalid token / token expiry
    useEffect(() => {
        let tk = searchParams.get('token');
        setToken(tk);

        httpClient.post("/user/verify-token", {token: tk})
            .then(function (data) {
                setTimeout(promptExpTk, data.expIn);
            })
            .catch(function (error) {
                switch (error.status) {
                    case 403:  // Invalid token
                        promptInvTk();
                        break;
                    case 410:  // Token expired
                        promptExpTk();
                        break;
                    case 500:  // Server error
                        promptSvrErr();
                        break;
                    default:
                        promptErr(error);
                        break;
                }
            });

    }, []);

    return (
        <Box>
            <Stack spacing={3} justifyContent="center" alignItems="center">
                <TextField
                    label={'New password'}
                    value={pw}
                    onChange={e => setPw(prev => {
                        validate(e.target.value, pwRules, setVldPw);
                        return e.target.value;
                    })
                    }
                    onBlur={e => setVldConfPw(prev => confPw === pw)}
                    error={!vldPw}
                    type='password'
                    sx={{ m: 2 }}
                />
                <TextField
                    label={'Confirm Password'}
                    value={confPw}
                    onChange={(e) => setConfPw(prev => {
                        setVldConfPw(e.target.value === pw ? true : false);
                        return e.target.value;
                    })
                    }
                    error={!vldConfPw}
                    type='password'
                    sx={{ m: 2 }}
                />
                <Button onClick={handleSubmit}>Submit</Button>
            </Stack>
            <MsgDialog open={openMsg}
                         closable={msgClosable}
                         onClose={() => setOpenMsg(false)}
                         title={msgTitle}
                         content={msgContent} />
            <ExpiredDialog open={openExp}
                           onClose={() => setOpenExp(false)} />

        </Box>
    );
}
