import React, { useEffect } from "react";
import GameListPane from "./GameListPane";
import AllGamesPane from "./AllGamesPane";
import GameContext from "../contexts/GameContext";
import LoginContext from '../contexts/LoginContext';
import httpClient from "../shared/HttpClient";

import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import { useContext } from "react";
import { useState } from "react";
import Box from '@mui/material/Box';
import FAQ from "./FAQ";

export default function GamesPage () {
    const gameCtx = useContext(GameContext);
    useEffect(() => gameCtx.clear(), []);

    const [allGames, setAllGames] = useState([]);
    const [joinedGames, setJoinedGames] = useState([]);
    const [tabStatus,settabStatus]=useState('2')
    const [activeStatus,setActiveStatus]=useState('2')
  

    const fetchAllGames = () => {
        httpClient.get('/game/find/all-games')
            .then(function (data) {
                setAllGames(data);
            });
    };

    const fetchGameList = () => {
        httpClient.get('/user/game-list')
            .then(function (data) {
                setJoinedGames(data);
                var gameList = [];
                data.map((game) => {
                    gameList.push(game.id)
                })
                gameCtx.setContext({
                    gameList: gameList
                });
            });
    };

    
    const refetch = () => {
        fetchGameList();
        fetchAllGames();
    }
    const findGames=()=>{
        settabStatus('1');
        setActiveStatus('1')
    }
    const yourGames=()=>{
        settabStatus('2')
        setActiveStatus('2')
    }
    
    useEffect(refetch, []);

    

    const loginCtx = useContext(LoginContext);
    let email = loginCtx.loginUser?.email || JSON.parse(localStorage.getItem('userInfo'))?.email;
    email = email ? email : '';

    return (
       <div >

        <Box sx={{ padding: { xs: '0.5rem', md: '3rem' } }}>
            <div className="row">
            <div className="col-3 d-none d-lg-inline">
            <div className='mt-3 mb-3'>
           <div class="card boxshadow allgames-section" title="Click to enter game" >
           <div class="card-body  p-2">
               <div className="row text-left font text-white">
                    <div className="col-12 ">
                    <h6 className="p-1 sise-15">Links</h6>
                    <hr className="border-secondary"></hr>
                    </div>
                    {/* <div className="col-12">
                        <p className="text-left color-white p-1 size-14" >
                        Get the Information You Need to Navigate Our Website Easily.
                        </p>
                    </div> */}
                    <div className="col-12 font size-14">
                        <a type="button" target='_blank' href={`https://docs.google.com/forms/d/e/1FAIpQLSe0R6-zZf-MlgBS2jfdZCoMIS9WG8czsQihfBaAEUC8jP5-Aw/viewform?usp=pp_url&entry.567407623=${email}`} className="mb-1 size-14 w-100 btn btn-outline-secondary color-white text-left p-2 text-capitalize">
                            Bug Report&nbsp;
                            <i class="fa fa-external-link float-right p-1" aria-hidden="true" />
                        </a>
                        <a type="button" target='_blank' href={`https://docs.google.com/forms/d/e/1FAIpQLSeFIcp1vH2uOIfkarWJU24fo9Cq3KnSszC3UCASVKNy4vQn5g/viewform?usp=pp_url&entry.938703197=${email}`} className="mb-1 size-14 w-100 btn btn-outline-secondary color-white text-left p-2 text-capitalize">
                            Comment Form&nbsp;
                            <i class="fa fa-external-link float-right p-1" aria-hidden="true" />
                        </a>
                        <a type="button" target='_blank' href={`https://docs.google.com/forms/d/e/1FAIpQLScU_HUxJFA7K9Lkd1EXnbkzv2AWtJVaOS-frm1JK7AaDExtmg/viewform?usp=pp_url&entry.2086822270=${email}`} className="mb-1 size-14 w-100 btn btn-outline-secondary color-white text-left p-2 text-capitalize">
                            Naming Contest!&nbsp;
                            <i class="fa fa-external-link float-right p-1" aria-hidden="true" />
                        </a>
                        
                        <h6 className="p-1 sise-15 mt-2">Let's Connect</h6>
                        <Divider sx={{ m: 1 }} />
                        <div className="mb-1 size-14 w-100 color-white text-left px-2" >
                           -  Join Our Simutrade Group:<br></br> <a target='_blank' style={{textDecoration:'underline !important'}} href='https://groups.google.com/g/simutrade'><i class="fa fa-external-link p-1" aria-hidden="true" />Simutrade User Group</a>
                        </div>
                        <div className="mb-1 size-14 w-100 color-white text-left px-2">
                            - Or Just Email :
                            <br/>
                            <a target='_blank' style={{textDecoration:'underline !important'}}  href='mailto:simutrade@googlegroups.com'><i class="fa fa-envelope p-1" aria-hidden="true" />simutrade@googlegroups.com</a>
                        </div>
                    </div>
               </div>
           </div>
           </div>
           </div>
           <div className='mt-3 mb-3'>
                    <FAQ></FAQ>
           </div>
                </div>
                <div className="col">
                <div className='mt-3 mb-3'>
           
                    <div class="card  boxshadow allgames-section" style={{cursor:'pointer'}} title="Click to enter game" >
                    <div class="card-body p-2">
                        <div className="row">
                            <div className="col text-left">
                            <button type="button"
                            style={{backgroundColor:activeStatus=='2'?'rgb(27, 117, 187)':'transparent'}}
                             onClick={yourGames} className="font size-15 btn btn-dark btn-sm font color-white p-2 w-auto d-inline-flex w-25">Your Games</button>&nbsp;
                            <button type="button" 
                            style={{backgroundColor:activeStatus=='1'?'rgb(27, 117, 187)':'transparent'}}
                            
                            onClick={findGames} className="font size-15 btn btn-dark btn-sm font color-white p-2 w-auto btn-sm d-inline-flex">Find A Game</button>
                                
                                </div>
                         
                        </div>
                    </div>
                    </div>
                    </div>
                    <Grid container-fluid  spacing={1}>

                    {tabStatus=='1' ? 
                    <Grid  item  sm={12} >
                    <Stack >
                        <AllGamesPane games={allGames} refetch={refetch}/>
                    </Stack>
                    </Grid> :

                    <Grid item   sm={12} className=" ">
                    <Stack>
                        <GameListPane games={joinedGames} refetch={refetch}/>
                    </Stack>
                    </Grid>
                    }



                    </Grid>
                </div>
            </div>
        </Box>
        </div>
    
        
    );
}
