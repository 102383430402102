import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import GameContext from "../contexts/GameContext";
import StkList from "./StkListPane.tsx";
import StkInfo from "./StkInfoPane";
import ChartPane from "./ChartPane";
import CustStkPane from "../shared/CustStkPane";
import CpyInfoPane from "./CpyInfoPane";
import LayoutConst from "../const/layoutConst";
import { Divider, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import httpClient from "../shared/HttpClient";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Box from '@mui/material/Box';

export default function MarketPage() {

    const gameCtx = useContext(GameContext);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();

    var stkId = searchParams.get("stk");
    const [isExpand, setExpand] = useState(true)

    const [open, setOpen] = React.useState(false);
    const [currentCompany, setCompany] = React.useState('');
    const [isShow, setisShow] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    

    const closeTab = () => {
        setExpand(!isExpand)
    }
    const fireFromChild = (str) => {

        setOpen((prevState) => {
            return !prevState
        })
        setCompany(str)
    }
    useEffect(() => {
        
        if (params.gid !== gameCtx.gid) {
            httpClient.get('/user/curr-game?gid=' + params.gid)
                .then(function (data) {
                    var player = {
                        id: data.pid,
                        username: userInfo.username,
                        dispName: userInfo.displayName,
                    }
                    var acc = data.defAcc;
                    gameCtx.setContext({
                        gid: params.gid,
                        currPlyr: player,
                        currAcc: acc,
                        viewPlyr: player,
                        viewAcc: acc,
                        players: data.players
                    });
                });
        }
    }, [params.gid]);

    useEffect(() => {
        if (stkId) {
            if (!(gameCtx.viewStk && stkId == gameCtx.viewStk.id)) {
                gameCtx.setContext({ viewStk: null });
                httpClient.get(`/stock/${stkId}/info`)
                    .then(function (data) {
                        gameCtx.setContext({ viewStk: data });
                    });
            }
        } else {
            gameCtx.setContext({ viewStk: null });
        }
    }, [stkId]);

    const trade=()=>{
       
        document.getElementById('tradebtn')?.click();
    }
    const addtowatch=()=>{
        document.getElementById("favbtn")?.click()
    }

    return (
        <Grid container
            sx={{
                '--Grid-borderWidth': '2px',
                borderTop: 'var(--Grid-borderWidth) solid',
                borderLeft: 'var(--Grid-borderWidth) solid',
                borderColor: theme.palette.background.default,
                '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderColor: theme.palette.background.default,
                },
            }}>
            <Grid item xs={0.5} sx={{display:{xs:'none',md:'block'}}}>
                <CustStkPane />
            </Grid>
            <Grid item md={isExpand == true ? 9 : 11} xs={12}>
                <Stack sx={{ height: LayoutConst.page.height }}>

                    <StkList events={fireFromChild} />
                </Stack>
            </Grid>
            <Grid item md={isExpand == true ? 2.5 : 0.5} sx={{display:{xs:'none',md:'block'}}}>
                <Stack spacing={1} sx={{ height: LayoutConst.page.height }}>

                    <Tooltip title={isExpand == true ? 'Fold Rightside' : 'Expand Rightside'} placement="right-start">
                        <ListItem key={1} disablePadding >
                            <ListItemButton>
                                <ListItemIcon className="drawer-list">
                                    <ExitToAppIcon onClick={closeTab} sx={{ transform: isExpand == true ? 'rotate(0deg)' : 'rotate(180deg)' }}></ExitToAppIcon>
                                </ListItemIcon>

                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                    <>
                        {
                            isExpand == true && <StkInfo />
                        }
                        {
                            isExpand == true && <CpyInfoPane />
                        }
                    </>
                </Stack>
            </Grid>
            <Dialog
                open={open}
                fullWidth
                
                maxWidth='md'
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                <Box sx={{display:{sm:'block',xs:'none'}}}>  {currentCompany}</Box>
                <Box sx={{display:{sm:'none'},cursor:'pointer'}} onClick={()=> setisShow(!isShow)}>  {currentCompany}&nbsp;<i className={isShow?'fa fa-angle-up':'fa fa-angle-down'}></i></Box>
              
                <Box sx={{display:{xs:'inline-flex',sm:'none'},justifyContent:'end',float:'right'}}>
               
                
                </Box>
                <Box sx={{display:isShow?'':'none'}}>
                
                <CpyInfoPane  showTitle={false}></CpyInfoPane>
                </Box>
            
                </DialogTitle>
                <DialogContent>
                    <ChartPane />
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose}>Close</Button>
                    <Button variant="contained" sx={{display:{sm:'none'}}} onClick={trade}>Trade</Button>
                   
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
