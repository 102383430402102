import {useRef, useState, useContext, useEffect} from "react";
import SignUpDialog from "./SignUpDialog";
import {useNavigate} from "react-router-dom";
import LoginContext from "../contexts/LoginContext";
import ReCAPTCHA from "react-google-recaptcha";

//import side imgages
import firstImg from '../assets/images/img1.webp';
import secondImg from '../assets/images/CPRO-SMC-1-CIE.webp';
import thirdImg from '../assets/images/CPRO-BUR-3.webp';
import fourthImg from '../assets/images/CPRO-Stud-2.webp';
import fifthImg from '../assets/images/CPRO-BUR-15.webp';
import Box from '@mui/material/Box';
import httpClient from "../shared/HttpClient";

export default function LoginPage(props) {
    const loginCtx = useContext(LoginContext);

    //states for username & password
    const [userName, setUserName] = useState('');
    const [userPassword, setUserPassword] = useState('');

    //helper states for showing action messages
    const [unameHelp, setUnameHelp] = useState('');
    const [pwdHelp, setPwdHelp] = useState('');
    const [capHelp, setCapHelp] = useState('');

    //eye-password icon
    const [eyeStatus, seteyeStatus] = useState(0);

    const [loginStatus, setLoginStatus] = useState('');
    const [openSU, setOpenSU] = useState(false);
    const captchaRef = useRef(null);
    const [captchaToken, setCaptchaToken] = useState(null);

    const navigate = useNavigate();

    function login() {
        if (userName === "" || userPassword === "") {
            if (userName === "") {
                setUnameHelp("* Username must be filled")
            } else {
                setUnameHelp("")
                setPwdHelp("* Password must be filled");
            }
        } else {
            if (captchaToken !== null) {
                setUnameHelp('');
                setPwdHelp("");
                setCapHelp("")

                var body = {
                    "username": userName,
                    "password": userPassword,
                    "captcha": captchaToken,
                };
                httpClient.post('/user/login', body)
                    .then(function (data) {
                        setLoginStatus("Login Success!");
                        loginCtx.setLoginUser(data);
                        localStorage.setItem("userInfo", JSON.stringify(data));
                        document.body.style.backgroundImage = "url('') ";
                        document.body.style.backgroundColor = "#1d1f23"
                        document.body.style.backgroundSize = " ";
                        document.body.style.backgroundPosition = " "
                        document.body.style.backgroundRepeat = " "
                        document.body.style.backgroundAttachment = " "
                        if (data.userType === 'u') {
                            navigate("/games");
                        } else if (data.userType === 'a') {
                            navigate("/game-mgt");
                        } else if (data.userType === 'r') {
                            navigate("/app-mgt");
                        } else {
                            navigate("/games");
                        }
                    })
                    .catch(function (error) {
                        captchaRef.current.reset();
                        setCaptchaToken(null);
                        if (error?.status === 401) {
                            setLoginStatus("* Login failed! Wrong username or password!");
                        } else if (error?.status === 403) {
                            setLoginStatus("* reCAPTCHA verification code failed. Please retry.")
                        } else {
                            setLoginStatus("* Unexpected error.")
                        }
                    });
            } else {
                setUnameHelp("")
                setPwdHelp("")
                setCapHelp("* Captcha must be filled.")
            }
        }
        ;
    }

    function isShowPass() {
        var x = document.getElementById("inppass");
        if (x.type === "password") {
            x.type = "text";
            seteyeStatus(1)
        } else {
            x.type = "password";
            seteyeStatus(0)
        }
    }

    onkeydown = (e) => {
        if (e.keyCode === 13 && captchaToken !== null) {
            login()
        }
    }


    const onChangeCaptcha = (value) => {
        console.log('Captcha value:', value);
        setCaptchaToken(value);
        setCapHelp("")
    }

    const onErrorCaptcha = () => {
        setCaptchaToken(null);
        captchaRef.current.reset();
    }

    const onExpiredCaptcha = () => {
        setCaptchaToken(null);
    }
    useEffect(() => {
        
        var imgPathArray=[firstImg,secondImg,thirdImg,fourthImg,fifthImg]
        var randomImg=(Math.floor(Math.random() * imgPathArray.length))
        var sideElem=document.getElementById("sideimg");

        if (sessionStorage.getItem("sideimg-ind") == undefined) {
            sideElem.style.backgroundImage = "url(" + imgPathArray[0] + ")";
            sessionStorage.setItem("sideimg-ind", 0);
        } else if (sessionStorage.getItem("sideimg-ind") == randomImg) {
            var a = Math.trunc(imgPathArray.length - 1 / 2)
            sideElem.style.backgroundImage = "url(" + imgPathArray[a] + ")"
            sessionStorage.setItem("sideimg-ind", a);
        } else {
            sideElem.style.backgroundImage = "url(" + imgPathArray[randomImg] + ")"
            sessionStorage.setItem("sideimg-ind", randomImg);
        }

    }, []);

    return (
        <div>
            <div className="container ">
                <div className="row pt-5 ">
                    <Box className="col-12 col-lg-8 ml-auto mr-auto" sx={{marginTop:{xs:2,sm:5}}}>
                        <div className="row ">
                            <Box className="col-md-6 col-12 no-gutters " sx={{padding:{xs:2,sm:0}}}>
                                <div className="card login-box">
                                    <div className="card-body text-left">
                                        <div className="color-blue">
                                            <i class="fa fa-bar-chart"></i>&nbsp;
                                            <span class="size-16 font weight-bold ">simutrade</span>
                                        </div>
                                        <div><h3 className="title color-white size-25 font weight-600 mt-2">Log In to
                                            Simutrade </h3></div>

                                        <div className="form-body text-left">

                                            <div className="row">
                                                <div className="col"><input type="text" className="inp-login"
                                                                            value={userName} onChange={(e) => {
                                                    setUserName(e.target.value);
                                                    setUnameHelp("")
                                                }} placeholder="Username or email"></input>
                                                    <div className="text-danger"><small>{unameHelp}</small></div>
                                                </div>

                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <input type="password" id="inppass" className="inp-login"
                                                           value={userPassword} onChange={(e) => {
                                                        setUserPassword(e.target.value);
                                                        setPwdHelp("")
                                                    }} placeholder="Password"></input>
                                                    <span className="color-white eye-icon" onClick={() => isShowPass()}
                                                          style={{'display': userPassword.length > 0 ? 'block' : 'none'}}><i
                                                        className={eyeStatus == 0 ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>


                                                    <div className="text-danger"><small>{pwdHelp}</small></div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">

                                                    <a style={{cursor: 'pointer'}} onClick={() => navigate('/forgot')}
                                                       className="font color-blue  size-14 ">Forgot your password?
                                                    </a>


                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <ReCAPTCHA
                                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                                        ref={captchaRef}
                                                        onChange={onChangeCaptcha}
                                                        onErrored={onErrorCaptcha}
                                                        onExpired={onExpiredCaptcha}

                                                    />
                                                    <div className="text-danger"><small>{capHelp}</small></div>
                                                </div>

                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <button className="btn btn-primary login-btn pt-3 pb-3"
                                                            onClick={() => login()}>
                                                        Log in
                                                    </button>
                                                </div>

                                            </div>
                                            <div className="row mt-3">
                                                <div className="col text-center">
                                                    <span className="font color-white size-14 ">New to Simutrade?<span
                                                        className="color-blue cursor-pointer"
                                                        onClick={() => setOpenSU(true)}>&nbsp;&nbsp;Join now</span></span>
                                                </div>

                                            </div>
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <p className={loginStatus === "Login Success!" ? "alert-msg mt-2 text-success" : "alert-msg mt-2 text-danger"}>{loginStatus}</p>
                                                </div>

                                            </div>


                                        </div>
                                    </div>


                                </div>

                            </Box>
                            <Box className="col-md-6 col-12 " sx={{ display: { xs: 'none',sm:'block' },padding:{xs:0,sm:0} }}>
                                <div className="side-img" id="sideimg"></div>
                            </Box>

                        </div>
                    </Box>
                </div>
            </div>

            <SignUpDialog open={openSU} setOpen={setOpenSU}/>

        </div>


    )
}
