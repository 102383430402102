const TradeConst = {
    status: {
        "New order": "1",
        "Partially filled": "2",
        "Filled": "3",
        "Cancelled": "4",
        "Rejected": "5",
        "Suspended": "6",
    },
    side: {
       "buy": 1,
        "sell": 2,
    },
    type: { 
        "enhancedLimit": 1,
        "market": 2,
    },
    tif: { 
        "day": 1,
        "gtc": 2,
    },
}

export default TradeConst;