import React, { useState, useContext } from 'react';
import NotificationModal from '../shared/NotificationModal';

import Box from '@mui/material/Box';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import httpClient from "../shared/HttpClient";

export default function JoinGameModal(props) {
    const [gid, setGID] = useState("");
    const [gidError, setGIDError] = useState(false);
    const [gidHelperText, setGIDHelperText] = useState("");

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");

    const [gameKey, setGameKey] = useState("");
    const [gameKeyError, setGameKeyError] = useState(false);
    const [gameKeyHelperText, setGameKeyHelperText] = useState("");

    const handleCloseNM = () => {
        props.handleClose();
        setOpenNotificationModal(false);
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} maxWidth="md">
            <DialogTitle className='font size-16'>Join Game</DialogTitle>
            <DialogContent>
                <Box >

                    <div className='font size-16'>
                    Game ID:
                    </div>
                    <div>
                    <TextField
                        required
                        label="Required"
                        type="text"
                        inputProps={{inputMode: 'numeric', pattern: '^\d+$'}}
                        error={gidError}
                        helperText={gidHelperText}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={gid}
                        variant="standard"
                        className="input-container mt-2"
                        onChange={(e) => {
                            console.log(e.target.value);
                            if (!Number.isInteger(parseInt(e.target.value)) || parseInt(e.target.value) <= 0) {
                                setGIDError(true);
                                setGIDHelperText("should be a positive integer");
                                setGID('');
                            } else {
                                setGIDError(false);
                                setGIDHelperText("");
                                setGID(parseInt(e.target.value));
                            }
                        }}
                    />
                    </div>

                </Box>

                <br/>

                <Box sx={{}}>


                    <div className='font size-15'>GAME KEY:</div>
                    <div>

                    <TextField
                        type="text"
                        error={gameKeyError}
                        helperText={gameKeyHelperText}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={gameKey}
                        variant="standard"
                        className="input-container mt-2"
                        onChange={(e) => {
                            if (false) { //TODO
                                //setGameKey('');
                                setGameKeyError(true);
                                setGameKeyHelperText("can not start with ...");
                            } else {
                                setGameKey(e.target.value);
                                setGameKeyError(false);
                                setGameKeyHelperText("");
                            }
                        }}
                    />
                    </div>


                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" className='font'
                        onClick={() => {
                            var body = {
                                gid: gid,
                                gameKey: gameKey,
                            };
                            httpClient.post("/game/join", body)
                                .then(function (data) {
                                    setNotification("Game joined successfully.");
                                    setLocation("/games");
                                    setButtonText("Back to game page")
                                    setOpenNotificationModal(true);
                                    props.refetch();
                                })
                                .catch(function (error) {
                                    setNotification("Failed to join. (" + error?.data?.msg + ")");
                                    setLocation("/games");
                                    setButtonText("Back to game page")
                                    setOpenNotificationModal(true);
                                    props.refetch();
                                });
                        }}
                >Join &nbsp;<i className='fa fa-paper-plane-o'></i></Button>
            </DialogActions>

            {openNotificationModal &&
            <NotificationModal open={openNotificationModal}
                               handleClose={handleCloseNM}
                               notification={notification}
                               location={location}
                               buttonText={buttonText}/>}
        </Dialog>
    );
}
