import React, { useRef } from "react";
import PropTypes from "prop-types";

export const useWillMount = (fn) => {
    const willMount = useRef(true);
    if (willMount.current && fn && typeof fn === 'function') {
        fn();
    }
    willMount.current = false;
}

export let wrapAuth = ComposedComponent => class WrapComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        auth: PropTypes.string.isRequired,
    };

    render() {
        if (localStorage.getItem('userInfo') &&
            this.props.role.indexOf(JSON.parse(localStorage.getItem('userInfo')).userType) > -1) {
            return <ComposedComponent  {...this.props} />
        } else {
            return null;
        }
    }
};
