import React, {useContext, useEffect, useRef, useState} from 'react';
import GameContext from "../contexts/GameContext";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as ChartTooltip,
    Legend,
    ResponsiveContainer,
    AreaChart,
    Area
} from 'recharts';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DatePicker from "@mui/lab/DatePicker";
import {Divider, TextField, Tooltip} from "@mui/material";
import TimelineIcon from '@mui/icons-material/Timeline';
import {useTheme} from "@mui/material/styles";
import httpClient from "../shared/HttpClient";

export default function HistNetValModal(props) {
    const descriptionElementRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [chartData, setChartData] = useState([]);

    const [beg, setBeg] = useState(new Date(new Date() - 7 * 24 * 3600 * 1000));
    const [end, setEnd] = useState(new Date());
    const [btState, setBtState] = useState(true);

    const gameCtx = useContext(GameContext);
    const theme = useTheme();

    const refreshChart = () => {
        if (gameCtx.viewAcc) {
            httpClient.get(`/account/${gameCtx.viewAcc}/hist-vals?beg=${beg.toISOString()}&end=${end.toISOString()}`)
                .then(function (data) {
                    for (var i = 0; i < data.length; i++) data[i].net_val = parseFloat(data[i].net_val);
                    setChartData(data);
                });
        }
    }

    useEffect(() => {
        if (Date.parse(beg) && Date.parse(end)) {
            setBtState(true);
            refreshChart()
        } else {
            setBtState(false)
        }
    }, [gameCtx.viewAcc, beg, end]);

    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatDate = (tickItem) => {
        return new Date(tickItem).toLocaleDateString();
    }
    const formatMoney = (tickItem) => {

        return "HK$ " + tickItem.toLocaleString('en-US')
    }

    return (
        <Box>
            <Tooltip title="Net Value History" placement="top" arrow>
                <TimelineIcon sx={{fontSize: '23px',borderRadius:'50%',padding:'3px',backgroundColor:'#fff',color:'#000'}} color="primary" cursor="pointer"
                              onClick={handleClickOpen('paper')}/>
            </Tooltip>
            <Dialog open={open} maxWidth={"lg"}
                    onClose={handleClose} scroll={scroll}>
                <DialogTitle className='font size-18'>Historical Change in Net Value</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <Box sx={{mb: 1, display: 'flex', justifyContent: 'space-evenly'}}>
                        <Typography className='font' fontWeight="bold" sx={{mt: 1}}>Time Range:</Typography>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="From"
                            value={beg}
                            onChange={(newValue) => {
                                setBeg(new Date(newValue));
                            }}
                        />
                        <DatePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="to"
                            value={end}
                            onChange={(newValue) => {
                                setEnd(new Date(newValue));
                            }}
                        />
                        <Button variant="outlined" className='font' onClick={() => refreshChart()}
                                disabled={!btState}><strong>Refresh</strong></Button>
                    </Box>
                    <Divider/>
                    <Box sx={{width: 800, height: 500,marginTop:'2', justifyContent: "center"}}>
                        {chartData && chartData.length > 0 ? (
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                    data={chartData}
                                    margin={{
                                        top: 10,
                                        right: 0,
                                        left: 50,
                                        bottom: 0,
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="upd_time_utc" interval="preserveStartEnd"
                                           tick={{fill: theme.palette.text.primary}}

                                           tickFormatter={formatDate} padding={{left: 40, right: 40}}/>
                                    <YAxis dataKey="net_val" interval="preserveStartEnd"
                                           tick={{fill: theme.palette.text.primary}}

                                           tickFormatter={formatMoney}
                                           domain={['auto', 'auto']}/>
                                    <ChartTooltip labelFormatter={formatDate}
                                                  labelStyle={{color: 'black', fontWeight: "bold"}}
                                                  formatter={(value, name, props) => [value, "Net Value"]}/>
                                    <Legend/>
                                    <defs>
                                        <linearGradient id="colorVal" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <Area type="monotone" dataKey="net_val" stroke={theme.palette.primary.dark}
                                          fillOpacity={1} fill="url(#colorVal)"
                                          dot={{fill: theme.palette.primary.dark}}/>
                                </AreaChart>
                            </ResponsiveContainer>
                        ) : <Typography variant="h4" className='font mt-3' fontWeight="bold">No Data Found!</Typography>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
