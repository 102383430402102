import React, {useContext, useEffect, useState} from "react";
import OrdersPane from "./OrdersPane";
import AccInfoPane from "./AccInfoPane";
import RankingsPane from "./RankingsPane";
import GameInfoPane from "./GameInfoPane";
import CustStkPane from "../shared/CustStkPane";
import {ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import GameContext from "../contexts/GameContext";
import UtilContext from "../contexts/UtilContext";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import LayoutConst from "../const/layoutConst";
import {useTheme} from "@mui/material/styles";
import MailIcon from '@mui/icons-material/Mail';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import httpClient from "../shared/HttpClient";

export default function AccountPage(props) {
    const gameCtx = useContext(GameContext);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();

    var pid = searchParams.get("pid");
    const [isExpand,setExpand]=useState(true)

    const closeTab=()=>{
        setExpand(!isExpand)
    }

    useEffect(() => {
        if (params.gid !== gameCtx.gid) {
            httpClient.get('/user/curr-game?gid=' + params.gid)
                .then(function (data) {
                    var player = {
                        id: data.pid,
                        username: userInfo.username,
                        dispName: userInfo.displayName,
                    }
                    var acc = data.defAcc;
                    var viewPlayer = player;
                    var viewAcc = acc;
                    if (pid) {
                        let exist = 0;
                        data.players.forEach((player) => {
                            if (player.id == pid) {
                                viewPlayer = {
                                    id: player.id,
                                    username: player.username,
                                    dispName: player.displayName,
                                }
                                viewAcc = player.defAcc;
                                exist = 1;
                            }
                        })
                        if (!exist) setSearchParams({})
                    }
                    gameCtx.setContext({
                        gid: params.gid,
                        currPlyr: player,
                        currAcc: acc,
                        viewPlyr: viewPlayer,
                        viewAcc: viewAcc,
                        players: data.players
                    });

                });
        } else if (gameCtx.viewPlyr && gameCtx.viewPlyr.id != pid) {
            if (!pid) setSearchParams({pid: gameCtx.currPlyr.id});
            else {
                let exist = 0;
                gameCtx.players.forEach((player) => {
                    if (player.id == pid) {
                        gameCtx.setContext({
                            viewPlyr: {
                                id: player.id,
                                username: player.username,
                                dispName: player.displayName,
                            },
                            viewAcc: player.defAcc,
                        });
                        exist = 1;
                    }
                })
                if (!exist) setSearchParams({pid: gameCtx.viewPlyr.id})
            }
        }
    }, [params.gid, pid]);

    const fetchWl = () => {
        if (gameCtx.viewPlyr) {
            httpClient.get(`/player/${gameCtx.currPlyr.id}/watchlist`)
                .then(function (data) {
                    gameCtx.setContext({wl: data});
                });
        }
    };

    // Add watchlist object to GameContext for the AddTo/RemoveFrom Watchlist Button to consume
    useEffect(() => { gameCtx.setContext({ fetchWl: fetchWl }); }, []);

    useEffect(fetchWl, [gameCtx.viewPlyr]);

    return (
        <Grid container
              sx={{
                  '--Grid-borderWidth': '2px',
                  borderTop: 'var(--Grid-borderWidth) solid',
                  borderLeft: 'var(--Grid-borderWidth) solid',
                  borderColor: theme.palette.background.default,
                  '& > div': {
                      borderRight: 'var(--Grid-borderWidth) solid',
                      borderColor: theme.palette.background.default,
                  },
              }}>
            <Grid item  xs={0.5}>
                <CustStkPane/>
                
            </Grid>
            <Grid item xs={isExpand==true ? 9:11}>
                <Stack sx={{height: LayoutConst.page.height}}>
                    <AccInfoPane/>
                    <OrdersPane/>
                </Stack>
            </Grid>
            <Grid item xs={isExpand==true ? 2.5 :0.5}   >
                <Stack spacing={1} sx={{height: LayoutConst.page.height}}>
                <Tooltip title={isExpand==true?'Fold Rightside':'Expand Rightside'} placement="right-start">
                <ListItem key={1} disablePadding >
                <ListItemButton>
                  <ListItemIcon className="drawer-list">
                  <ExitToAppIcon onClick={closeTab} sx={{transform:isExpand==true?'rotate(0deg)':'rotate(180deg)'}}></ExitToAppIcon>
                  </ListItemIcon>
                 
                </ListItemButton>
              </ListItem>
            </Tooltip>
               
                    <>
                    {isExpand==true && 
                    <GameInfoPane/>
                   
                    }
                    {isExpand==true && 
                     <RankingsPane/>
                   
                    }
                    
                    
                    </>
                </Stack>
                
            </Grid>
        </Grid>
    );
}
