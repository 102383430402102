import React, {useState, useContext} from 'react';
import NotificationModal from '../shared/NotificationModal';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import httpClient from "../shared/HttpClient";

export default function ExitGameModal(props) {
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");

    const handleCloseNM = () => {
        props.handleClose();
        setOpenNotificationModal(false);
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle className='font size-16'>
                Request the game administrator to exit the game (id: {props.gid}) ?
            </DialogTitle>
            <DialogActions>
                <Button color="error" size="medium" variant="outlined" className='font'
                        onClick={() => {
                            httpClient.post("/game/exit", {gid: props.gid})
                                .then(function (data) {
                                    setNotification("Request sent successfully.");
                                    setLocation("/games");
                                    setButtonText("Back to game page")
                                    setOpenNotificationModal(true);
                                    props.refetch();
                                })
                                .catch(function (error) {
                                    setNotification("Failed to request (" + error?.data?.msg + ")");
                                    setLocation("/games");
                                    setButtonText("Back to game page")
                                    setOpenNotificationModal(true);
                                    props.refetch();
                                });
                        }}
                >Exit  Now&nbsp;<i className='fa fa-paper-plane-o'></i></Button>
            </DialogActions>

            {openNotificationModal &&
                <NotificationModal open={openNotificationModal}
                                   handleClose={handleCloseNM}
                                   notification={notification}
                                   location={location}
                                   buttonText={buttonText}/>}
        </Dialog>
    );
}
