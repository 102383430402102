import React, {useState, useEffect, useContext} from 'react';
import NumberFormat from 'react-number-format';
import NotificationModal from '../shared/NotificationModal';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Button, ToggleButtonGroup, ToggleButton, TextField} from '@mui/material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import httpClient from "../shared/HttpClient";

export default function CreateGameModal(props) {
    const [name, setName] = useState(JSON.parse(localStorage.getItem('userInfo'))?.displayName?.trim().split(' ')[0] + "'s Game");
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState("");

    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionHelperText, setDescriptionHelperText] = useState("");

    const now = new Date();
    now.setSeconds(0);
    const [startTimeUTC, setStartTimeUTC] = useState(now);

    const nextYear = new Date(now);
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    const [endTimeUTC, setEndTimeUTC] = useState(nextYear);


    const [initCash, setInitCash] = useState(1000000.00);
    const [initCashError, setInitCashError] = useState(false);
    const [initCashHelperText, setInitCashHelperText] = useState("");

    const [minFee, setMinFee] = useState(50.0);
    const [minFeeError, setMinFeeError] = useState(false);
    const [minFeeHelperText, setMinFeeHelperText] = useState("");

    const [commRate, setCommRate] = useState(3.5);
    const [commRateError, setCommRateError] = useState(false);
    const [commRateHelperText, setCommRateHelperText] = useState("");

    const [cashInterest, setCashInterest] = useState(0);
    const [cashInterestError, setCashInterestError] = useState(false);
    const [cashInterestHelperText, setCashInterestHelperText] = useState("");

    const [marginInterest, setMarginInterest] = useState(0);
    const [marginInterestError, setMarginInterestError] = useState(false);
    const [marginInterestHelperText, setMarginInterestHelperText] = useState("");

    const [enblMargin, setEnblMargin] = useState("0");
    const [enblShort, setEnblShort] = useState("0");
    const [enblLimit, setEnblLimit] = useState("0");
    const [enblStop, setEnblStop] = useState("0");

    const [privateGame, setPrivateGame] = useState("0");
    const [gameKey, setGameKey] = useState("");
    const [gameKeyError, setGameKeyError] = useState(false);
    const [gameKeyHelperText, setGameKeyHelperText] = useState("");

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");

    const handleCloseNM = () => setOpenNotificationModal(false);

    const [scroll, setScroll] = React.useState('paper');

    // TODO: Debug only! REMOVE BEFORE FLIGHT!
    useEffect(() => {
        console.log(JSON.stringify(startTimeUTC));
    }, [startTimeUTC]);
    useEffect(() => {
        console.log(endTimeUTC);
    }, [endTimeUTC]);

    const handleValidation = () => {
        let formIsValid = !(nameError || descriptionError || initCashError ||
            minFeeError || commRateError || cashInterestError || marginInterestError);

        return formIsValid;
    };

    const handleSubmit = () => {
        var body = {
            name: name,
            description: description, // optional
            startTimeUTC: startTimeUTC,
            endTimeUTC: endTimeUTC,
            initCash: initCash,
            minFee: minFee,
            commRate: commRate,
            cashInterest: cashInterest,
            marginInterest: marginInterest, //optional
            enblMargin: enblMargin,
            enblShort: enblShort,
            enblLimit: enblLimit,
            enblStop: enblStop,
            gameKey: gameKey,
            privateGame: privateGame,
        };

        httpClient.post("/game/create", body)
            .then(function (data) {
                setNotification("Game (id: " + data.gameId + ") created successfully.");
                setLocation("/games");
                setButtonText("Back to game page");
                setOpenNotificationModal(true);
            })
            .catch(function (error) {
                setNotification("Failed to create game");
                setLocation("/games");
                setButtonText("Back to game page");
                setOpenNotificationModal(true);
            });
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={scroll}>
            <DialogTitle>Create Game</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <Box>
                    {openNotificationModal && <NotificationModal open={openNotificationModal}
                                                                 handleClose={handleCloseNM} notification={notification}
                                                                 location={location} buttonText={buttonText}/>}

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1}}>
                                Name</Typography>
                            <TextField
                                required
                                type="text"
                                error={nameError}
                                helperText={nameHelperText}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={name}
                                variant="outlined"
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setNameError(true);
                                        setNameHelperText("Name can not be empty.");
                                    } else {
                                        setNameError(false);
                                        setNameHelperText("");
                                    }
                                    setName(e.target.value);
                                }}
                            />
                        </Box>

                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1}}>
                                Initial Cash</Typography>

                            <NumberFormat
                                thousandSeparator={true}
                                customInput={TextField}
                                error={initCashError}
                                helperText={initCashHelperText}
                                onValueChange={(values) => {
                                    if (values.value === "") {
                                        setInitCashError(true);
                                        setInitCashHelperText("Initial cash can not be empty.");
                                    } else {
                                        setInitCashError(false);
                                        setInitCashHelperText("");
                                    }
                                    setInitCash(parseFloat(values.value));
                                }}
                                value={initCash}
                                decimalScale={3}
                                variant="outlined"
                            />
                        </Box>
                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1}}>
                                Minimum Commission</Typography>

                            <NumberFormat
                                thousandSeparator={true}
                                customInput={TextField}
                                error={minFeeError}
                                helperText={minFeeHelperText}
                                onValueChange={(values) => {
                                    if (values.value === "") {
                                        setMinFeeError(true);
                                        setMinFeeHelperText("Minimum fee can not be empty.");
                                    } else {
                                        setMinFeeError(false);
                                        setMinFeeHelperText("");
                                    }
                                    setMinFee(parseFloat(values.value));
                                }}
                                value={minFee}
                                decimalScale={3}
                                variant="outlined"
                            />
                        </Box>

                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1}}>
                                Commission Rate</Typography>

                            <NumberFormat
                                thousandSeparator={true}
                                customInput={TextField}
                                error={commRateError}
                                helperText={commRateHelperText}
                                onValueChange={(values) => {
                                    if (values.value === "") {
                                        setCommRateError(true);
                                        setCommRateHelperText("Commercial rate can not be empty.");
                                    } else {
                                        setCommRateError(false);
                                        setCommRateHelperText("");
                                    }
                                    setCommRate(parseFloat(values.value));
                                }}
                                value={commRate}
                                decimalScale={3}
                                variant="outlined"
                            />
                        </Box>
                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography id="modal-modal-description" sx={{mt: 1}}>
                            Description</Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <TextField
                            type="text"
                            error={descriptionError}
                            helperText={descriptionHelperText}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={description}
                            variant="outlined"
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    setDescriptionError(true);
                                    setDescriptionHelperText("Description can not be empty.");
                                } else {
                                    setDescriptionError(false);
                                    setDescriptionHelperText("");
                                }
                                setDescription(e.target.value);
                            }}
                        />
                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography id="modal-modal-description" sx={{mt: 1}}>
                            Start Time</Typography>
                        &nbsp;
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Start Time"
                            value={startTimeUTC}
                            onChange={(newValue) => {
                                setStartTimeUTC(newValue);
                            }}
                        />
                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography id="modal-modal-description" sx={{mt: 1}}>
                            End Time</Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="End Time"
                            value={endTimeUTC}
                            onChange={(newValue) => {
                                setEndTimeUTC(newValue);
                            }}
                        />

                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1.5}}>
                                Game Access
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={privateGame}
                                exclusive
                                onChange={(e) => {
                                    setPrivateGame(e.target.value);
                                    if (privateGame === "0") {
                                        setGameKey("");
                                    }
                                }}
                            >
                                <ToggleButton value="0">PUBLIC</ToggleButton>
                                <ToggleButton value="1">PRIVATE</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {privateGame === "1" &&
                            <Box>
                                <Typography id="modal-modal-description" sx={{mt: 1}}>
                                    Game Key</Typography>
                                <br/>
                                <TextField
                                    type="text"
                                    error={gameKeyError}
                                    helperText={gameKeyHelperText}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={gameKey}
                                    variant="outlined"
                                    onChange={(e) => {
                                        if (false) { //TODO\
                                            //setGameKey('');
                                            setGameKeyError(true);
                                            setGameKeyHelperText("can not start with ...");
                                        } else {
                                            setGameKey(e.target.value);
                                            setGameKeyError(false);
                                            setGameKeyHelperText("");
                                        }
                                    }}
                                />
                            </Box>}
                    </Box>
                    <br/>
                    <Button onClick={handleSubmit}>SUBMIT</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
