import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function MsgDialog(props) {

    const navigate = useNavigate();

    const back = () => {
        navigate('/');
    };

    return (
        <Dialog className="dialog-styling" open={props.open}
                onClose={props.closable ? props.handleClose : () => {/* Do nothing */}}>
            <DialogTitle className="font size-20">{props.title}</DialogTitle>
            <DialogContent>
                <Typography className="font size-15">{props.content}</Typography>
            </DialogContent>
            <DialogActions>
                <Button className="bg-blue font color-white" size="large" onClick={back} variant="contained" >Back to Login</Button>
            </DialogActions>
        </Dialog>
    );
}
