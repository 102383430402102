import { useState, useContext, useEffect } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import GameContext from '../contexts/GameContext';
import httpClient from "../shared/HttpClient";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

export default function Holdings() {
    const [holdings, setHoldings] = useState({});
    const gameCtx = useContext(GameContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const theme = useTheme();

    useEffect(() => {
        httpClient.get(`/account/${gameCtx.viewAcc}/holdings`)
            .then(function (data) {
                setHoldings(data);
            });
    }, [gameCtx.viewAcc]);

    const clickHandler = (id) => {
        if (window.location.pathname.search('/mkt') === -1) navigate(`/mkt/${gameCtx.gid}?stk=${id}`);
        else setSearchParams({stk: id});
    }

    return (
        <TableContainer>
            <Table stickyHeader  size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ alignItems: 'right' }}>
                            <Typography  className="font size-13 font-weight-bold">COMPANY</Typography>
                            <Typography  className="font size-13 font-weight-bold">SYMBOL</Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography  className="font size-13 font-weight-bold">PRICE</Typography>
                            <Typography  className="font size-13 font-weight-bold">(SHARES)</Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography  className="font size-13 font-weight-bold">VALUE</Typography>
                            <Typography  className="font size-13 font-weight-bold">CHG%</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(holdings).map((key) => (
                        <TableRow hover key={key} onClick={() => clickHandler(key)}>
                            <TableCell>
                                <Typography className="font size-13 " color={theme.palette.warning.main}>
                                    {holdings[key].name}
                                </Typography>
                                <Typography className="font size-13 ">
                                    {holdings[key].symbol}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align='right' className="font size-13 " sx={{ color: holdings[key].chgRate >= 0 ? theme.palette.success.main : theme.palette.error.main }}>
                                    ${holdings[key].price.toFixed(3)}
                                </Typography>
                                <Typography align='right' className="font size-13 ">
                                    ({holdings[key].qty})
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align='right' className="font size-13 " sx={{ color: holdings[key].chgRate >= 0 ? theme.palette.success.main : theme.palette.error.main }}>
                                    ${parseFloat(holdings[key].val).toFixed(3)}
                                </Typography>
                                <Typography
                                className="font size-13 "
                                    align='center'
                                    sx={{
                                        background: holdings[key].chgRate >= 0 ? theme.palette.success.main : theme.palette.error.main,
                                        pl: 0.5,
                                        pr: 0.5,
                                        borderRadius: 1,
                                }}>
                                    {Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        signDisplay: 'always',
                                    }).format(holdings[key].chgRate * 100)}%
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
