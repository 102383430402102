import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://53d8b38b13924616aa8b9474f3c1f6e0@o1323201.ingest.sentry.io/6654724",
    integrations: [new BrowserTracing({
        tracingOrigins: [process.env.REACT_APP_SERVER_URL.match(/(?:https?:\/\/)([^:\/]+)/)[1]]
    })],
    release: process.env.REACT_APP_SENTRY_RELEASE,
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
  });
  
ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
    , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
