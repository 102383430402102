import React, {useState, useContext, useEffect, forwardRef} from 'react';
import TradeConst from '../const/TradeConst';
import NotificationModal from '../shared/NotificationModal';
import GameContext from '../contexts/GameContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, ToggleButtonGroup, ToggleButton, TextField, Tooltip,Divider, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from "@mui/material/styles";
import httpClient from "../shared/HttpClient";

export default function TradeModal(props) {
    const [price, setPrice] = useState(props.quote.curr);
    const [priceError, setPriceError] = useState(false);
    const [priceHelperText, setPriceHelperText] = useState("");
    const [side, setSide] = useState('buy');
    const [type, setType] = useState('enhancedLimit');
    const [tif, setTIF] = useState('gtc');
    const [qty, setQty] = useState(1);
    const [qtyError, setQtyError] = useState(false);
    const [qtyHelperText, setQtyHelperText] = useState("");
    const [showPrice, setShowPrice] = useState(true);

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");

    const [scroll, setScroll] = React.useState('paper');
    const [submitStatus, setSubmitStatus] = useState('UNSUBMITTED');

    const handleCloseNM = () => setOpenNotificationModal(false);
    const [autoClose, setAutoClose] = useState(false);

    const gameCtx = useContext(GameContext);

    const theme = useTheme();

    const submitOrder = () => {
        var body = {
            "acc": gameCtx.currAcc,
            "stk": props.stock.id,
            "side": TradeConst.side[side], // side, need to transcribe
            "qty": parseInt(qty), // qty
            "type": TradeConst.type[type], // type, need to transcribe
            "tif": TradeConst.tif[tif], // tif, need to transcribe
            "lmt": price, // price
            "stp": undefined, // temp
            "exp": undefined, // temp
            "lotSize": parseInt(props.stock.lotSize),
        };

        httpClient.post("/trade/place-order", body)
            .then(function (data) {
                setNotification("Order (id: " + data.id + ") created successfully.");
                setLocation(`/acc/${gameCtx.gid}`);
                setButtonText("View my orders");
                setOpenNotificationModal(true);
                setAutoClose(true);
                setSubmitStatus('SUBMITTED');
            })
            .catch(function (error) {
                setNotification(error?.data?.msg);
                setLocation(`/acc/${gameCtx.gid}`);
                setButtonText("View my orders");
                setOpenNotificationModal(true);
                setSubmitStatus('UNSUBMITTED');
            });
    };

    useEffect(() => {
        if (type === "market") setShowPrice(false);
        else setShowPrice(true);
    }, [type]);

    useEffect(() => {
        if (submitStatus === 'SUBMITTING') {
            submitOrder();
        }
    }, [submitStatus])

    useEffect(() => {
        if (autoClose && !openNotificationModal) {
            props.handleClose();
        }
    }, [autoClose, openNotificationModal]);

    useEffect(() => {
        setSubmitStatus('UNSUBMITTED');
    }, [props.open]);

    const TooltipToggleButton = forwardRef(({title, ...props}, ref) => (
        <Tooltip title={title}>
            <Box>
                <ToggleButton ref={ref} {...props} />
            </Box>
      </Tooltip>
    ));

    return (
        <Box>
            <Dialog maxWidth={'sm'} fullWidth={'sm'} open={props.open} onClose={props.handleClose} >
                <DialogTitle

                 className='font size-16'>Trade</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <Box>
                        {openNotificationModal && <NotificationModal open={openNotificationModal}
                            handleClose={handleCloseNM} notification={notification} location={location} buttonText={buttonText} />}

                        <table className='w-100'>
                        <tbody>
                            <tr>
                                <td className="font size-13 font-weight-bold">SYMBOL</td>
                                <td className="font size-13 font-weight-bold">NAME</td>
                            </tr>
                            <tr>
                                <td className='font size-13'>  {props.stock.symbol}</td>
                                <td className='font size-13'>{props.stock.stkName}</td>
                            </tr>
                            <br></br>
                            <tr >
                                <td  className="font size-13 font-weight-bold">CHANGE</td>
                                <td  className="font size-13 font-weight-bold w-25">CHANGE%</td>
                                <td  className="font size-13 font-weight-bold">CURRENT</td>
                            </tr>
                            <tr>
                                <td className='font size-13'  style={{color: props.quote.curr - props.quote.lastClose > 0 ? theme.palette.success.main : theme.palette.error.main}}> {(props.quote.curr - props.quote.lastClose).toFixed(3)}</td>
                                <td className='font size-13'  style={{color: props.quote.curr - props.quote.lastClose > 0 ? theme.palette.success.main : theme.palette.error.main}}>  {((props.quote.curr - props.quote.lastClose) / props.quote.curr * 100).toFixed(3)}%</td>
                                <td className='font size-13'>{props.quote.curr}</td>
                            </tr><br></br>
                            <tr>
                                <td className='font size-13 font-weight-bold'>High</td>
                                <td className='font size-13 font-weight-bold'>Low</td>
                                <td className='font size-13 font-weight-bold'>Open</td>
                                <td className='font size-13 font-weight-bold'>Close</td>
                            </tr>
                            <tr>
                                <td className='font size-13 '>{props.quote.high}</td>
                                <td className='font size-13'>{props.quote.low}</td>
                                <td className='font size-13'>{props.quote.open}</td>
                                <td className='font size-13 '>{props.quote.lastClose}</td>
                            </tr><br></br>
                            <tr>
                            <td>
                                 <Typography id="modal-modal-description" className="font size-13 font-weight-bold"  sx={{ mt: 1.5 }} color="inherit">
                                SIDE:
                            </Typography>
                            </td>
                                <td colSpan={3}>
                                <ToggleButtonGroup
                                color="primary"
                                value={side}
                                exclusive
                                onChange={(e) => {
                                    setSide(e.target.value);
                                }}
                            >
                                <ToggleButton value="buy" size='medium'  className="font size-13 ">Buy</ToggleButton>
                                <ToggleButton value="sell" size='medium'   className="font size-13">Sell</ToggleButton>
                            </ToggleButtonGroup>
                                </td>
                            </tr>
                            <br></br>
                            <tr>
                                <td><Typography className="font size-13 font-weight-bold" id="modal-modal-description" sx={{ mt: 2 }}>
                                TYPE:
                            </Typography></td>
                                <td  colSpan={3}>
                                <ToggleButtonGroup
                                color="primary"
                                value={type}
                                exclusive
                                onChange={(e) => {
                                    setType(e.target.value);
                                }}
                            >
                                <ToggleButton value="enhancedLimit" className="font size-13 ">Enhanced Limit</ToggleButton>
                                <ToggleButton value="market" className="font size-13">Market</ToggleButton>
                            </ToggleButtonGroup>
                                </td>
                            </tr>

                            <tr style={{display:showPrice ? '':'none'}}>
                                <td>  <Typography className="font size-13 font-weight-bold" id="modal-modal-description" sx={{ mt: 1 }}>
                                PRICE:</Typography></td>
                                <td  colSpan={3}>
                                <TextField
                                required
                                label="Required"
                                className="font size-13 "
                                value={price}
                                id="standard-number"
                                type="number"
                                variant="standard"
                                error={priceError}
                                helperText={priceHelperText}
                                onChange={(e) => {
                                    setPrice(parseFloat(e.target.value));
                                    console.log(parseFloat(e.target.value));
                                    if (Number.isNaN(parseFloat(e.target.value))) {
                                        setPriceError(true);
                                        setPriceHelperText("should be a float number");
                                    } else {
                                        setPriceError(false);
                                        setPriceHelperText("");
                                    }
                                }}
                            />
                                </td>
                            </tr><br></br>

                            <tr>
                                <td> <Typography className="font size-13 font-weight-bold" id="modal-modal-description" sx={{ mt: 1 }}>
                                QTY:</Typography></td>
                                <td  colSpan={3}> <TextField
                                required
                                className="font size-13"
                                label="Required"
                                type="text"
                                inputProps={{ inputMode: 'numeric', pattern: '^\d+$' }}
                                error={qtyError}
                                helperText={qtyHelperText}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={qty}
                                variant="standard"
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    if (!Number.isInteger(parseInt(e.target.value)) || parseInt(e.target.value) <= 0) { // TO DO: deal with Float
                                        setQtyError(true);
                                        setQtyHelperText("should be a positive integer");
                                        setQty('');
                                    }else {
                                        setQtyError(false);
                                        setQtyHelperText("");
                                        setQty(parseInt(e.target.value));
                                    }
                                }}
                            /></td>
                            </tr><br></br>

                            <tr>
                                <td><Tooltip title="Time in Force">
                                <Typography className="font size-13 font-weight-bold" id="modal-modal-description" sx={{ mt: 1.5 }}>
                                    TIF:
                                </Typography>
                            </Tooltip></td>
                                <td  colSpan={3}><ToggleButtonGroup
                                color="primary"
                                value={tif}
                                exclusive
                                onChange={(e) => {
                                    setTIF(e.target.value);
                                }}
                            >
                                <ToggleButton value="gtc" className="font size-13 ">
                                    Good Til Cancelled
                                </ToggleButton>
                                <TooltipToggleButton title="Coming soon" disabled value="day" className="font size-13">
                                    Day Order
                                </TooltipToggleButton>
                            </ToggleButtonGroup></td>
                            </tr>
                        </tbody>
                    </table>
                        <br />
                            <div className='text-right '>
                            <Divider className="pb-1"/>
                            <Button size='medium' onClick={props.handleClose}>Close</Button>&nbsp;
                                <Button size='medium' className='mt-2' variant='outlined' sx={{ width: 100 }}
                                        onClick={() => setSubmitStatus('SUBMITTING')}
                                        disabled={submitStatus !== 'UNSUBMITTED'}
                                >SUBMIT&nbsp;<Box sx={{ alignItems: 'center', display: 'flex' }}>{submitStatus === 'UNSUBMITTED' ? <i className='fa fa-paper-plane-o' width="20px" /> : (submitStatus === 'SUBMITTING' ? <CircularProgress size={20}/> : <CheckIcon />)}</Box></Button>
                            </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
