import React, {useState, useEffect, useContext} from 'react';
import NotificationModal from '../shared/NotificationModal';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Button, ToggleButtonGroup, ToggleButton, TextField} from '@mui/material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import httpClient from "../shared/HttpClient";

export default function ModifyGameModal(props) {
    const [name, setName] = useState("Post Game");
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState("");

    const [description, setDescription] = useState("This game is for posting test");
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionHelperText, setDescriptionHelperText] = useState("");

    const now = new Date();
    now.setSeconds(0);
    const [startTimeUTC, setStartTimeUTC] = useState(now);

    const nextYear = new Date(now);
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    const [endTimeUTC, setEndTimeUTC] = useState(nextYear);


    const [initCash, setInitCash] = useState(1000000.1);
    const [initCashError, setInitCashError] = useState(false);
    const [initCashHelperText, setInitCashHelperText] = useState("");

    const [minFee, setMinFee] = useState(50.5);
    const [minFeeError, setMinFeeError] = useState(false);
    const [minFeeHelperText, setMinFeeHelperText] = useState("");

    const [commRate, setCommRate] = useState(3.5);
    const [commRateError, setCommRateError] = useState(false);
    const [commRateHelperText, setCommRateHelperText] = useState("");

    const [cashInterest, setCashInterest] = useState(1.12);
    const [cashInterestError, setCashInterestError] = useState(false);
    const [cashInterestHelperText, setCashInterestHelperText] = useState("");

    const [marginInterest, setMarginInterest] = useState(0.1);
    const [marginInterestError, setMarginInterestError] = useState(false);
    const [marginInterestHelperText, setMarginInterestHelperText] = useState("");

    const [enblMargin, setEnblMargin] = useState("0");
    const [enblShort, setEnblShort] = useState("0");
    const [enblLimit, setEnblLimit] = useState("0");
    const [enblStop, setEnblStop] = useState("0");

    const [privateGame, setPrivateGame] = useState("0");
    const [gameKey, setGameKey] = useState("");
    const [gameKeyError, setGameKeyError] = useState(false);
    const [gameKeyHelperText, setGameKeyHelperText] = useState("");

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");

    const [scroll, setScroll] = React.useState('paper');

    const handleCloseNM = () => setOpenNotificationModal(false);

    const getGameInfo = () => {
        setOpenNotificationModal(false);
        window.location.reload();
    }

    const handleModify = () => {
        var body = {
            name: name,
            description: description, // optional
            startTimeUTC: startTimeUTC,
            endTimeUTC: endTimeUTC,
            initCash: initCash,
            minFee: minFee,
            commRate: commRate,
            cashInterest: cashInterest,
            marginInterest: marginInterest, //optional
            enblMargin: enblMargin,
            enblShort: enblShort,
            enblLimit: enblLimit,
            enblStop: enblStop,
            gameKey: gameKey,
            privateGame: privateGame,
        };

        httpClient.post("/game/" + props.gameInfo.id + "/modify", body)
            .then(function (data) {
                setNotification("Game (id: " + props.gameInfo.id + ") modified successfully.");
                setLocation("/games");
                setButtonText("Back to game page");
                setOpenNotificationModal(true);
            })
            .catch(function (error) {
                setNotification("Failed to modify game\n" + error?.data?.msg);
                setLocation("/games");
                setButtonText("Back to game page");
                setOpenNotificationModal(true);
            });
    }

    //TODO: get info of the game
    useEffect(() => {
        setName(props.gameInfo.name);
        setInitCash(props.gameInfo.init_cash);
        setDescription(props.gameInfo.description);
        setStartTimeUTC(props.gameInfo.start_time_utc);
        setEndTimeUTC(props.gameInfo.end_time_utc);
        setEnblMargin(props.gameInfo.enbl_margin + "");
        setEnblShort(props.gameInfo.enbl_short + "");
        setEnblLimit(props.gameInfo.enbl_limit + "");
        setEnblStop(props.gameInfo.enbl_stop + "");
        setGameKey(props.gameInfo.game_key);
        setPrivateGame(props.gameInfo.private_game + "");
        setGameKey(props.gameInfo.game_key);
        setMinFee(props.gameInfo.min_fee);
        setCommRate(props.gameInfo.comm_rate);
        setCashInterest(props.gameInfo.cash_interest);
        setMarginInterest(props.gameInfo.margin_interest);
    }, []);

    // TODO: Debug only! REMOVE BEFORE FLIGHT!
    useEffect(() => {
        console.log(JSON.stringify(startTimeUTC));
    }, [startTimeUTC]);
    useEffect(() => {
        console.log(endTimeUTC);
    }, [endTimeUTC]);

    return (
        <Dialog open={props.open}
                onClose={props.handleClose}
                scroll={scroll}>
            <DialogTitle>Modify Game</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <Box>
                    {openNotificationModal && <NotificationModal open={openNotificationModal}
                                                                 handleClose={handleCloseNM} notification={notification}
                                                                 location={location} buttonText={buttonText}/>}

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>

                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1}}>
                                NAME:</Typography>
                            <TextField
                                required
                                label="Required"
                                type="text"
                                error={nameError}
                                helperText={nameHelperText}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={name}
                                variant="outlined"
                                onChange={(e) => {
                                    if (false) { //TODO\
                                        //setName('');
                                        setNameError(true);
                                        setNameHelperText("can not start with ...");
                                    } else {
                                        setName(e.target.value);
                                        setNameError(false);
                                        setNameHelperText("");
                                    }
                                }}
                            />
                        </Box>

                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1}}>
                                INIT CASH:</Typography>
                            <TextField
                                required
                                label="Required"
                                id="standard-number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={initCash}
                                variant="outlined"
                                error={initCashError}
                                helperText={initCashHelperText}
                                onChange={(e) => {
                                    setInitCash(parseFloat(e.target.value));
                                    if (Number.isNaN(parseFloat(e.target.value))) {
                                        setInitCashError(true);
                                        setInitCashHelperText("should be a float number");
                                    } else {
                                        setInitCashError(false);
                                        setInitCashHelperText("");
                                    }
                                }}
                            />
                        </Box>
                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography id="modal-modal-description" sx={{mt: 1}}>
                            DESCRIPTION:</Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <TextField
                            type="text"
                            error={descriptionError}
                            helperText={descriptionHelperText}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={description}
                            variant="outlined"
                            onChange={(e) => {
                                if (false) { //TODO\
                                    //setDescription('');
                                    setDescriptionError(true);
                                    setDescriptionHelperText("can not start with ...");
                                } else {
                                    setDescription(e.target.value);
                                    setDescriptionError(false);
                                    setDescriptionHelperText("");
                                }
                            }}
                        />
                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography id="modal-modal-description" sx={{mt: 1}}>
                            START TIME:</Typography>
                        &nbsp;
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Start Time"
                            value={startTimeUTC}
                            onChange={(newValue) => {
                                setStartTimeUTC(newValue);
                            }}
                        />
                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Typography id="modal-modal-description" sx={{mt: 1}}>
                            END TIME:</Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="End Time"
                            value={endTimeUTC}
                            onChange={(newValue) => {
                                setEndTimeUTC(newValue);
                            }}
                        />

                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>

                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1.5}}>
                                MARGIN:
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={enblMargin}
                                exclusive
                                onChange={(e) => {
                                    setEnblMargin(e.target.value);
                                }}
                            >
                                <ToggleButton value="1">Enable</ToggleButton>
                                <ToggleButton value="0">Disable</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1.5}}>
                                SHORT:
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={enblShort}
                                exclusive
                                onChange={(e) => {
                                    setEnblShort(e.target.value);
                                }}
                            >
                                <ToggleButton value="1">Enable</ToggleButton>
                                <ToggleButton value="0">Disable</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1.5}}>
                                LIMIT:
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={enblLimit}
                                exclusive
                                onChange={(e) => {
                                    setEnblLimit(e.target.value);
                                }}
                            >
                                <ToggleButton value="1">Enable</ToggleButton>
                                <ToggleButton value="0">Disable</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1.5}}>
                                STOP:
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={enblStop}
                                exclusive
                                onChange={(e) => {
                                    setEnblStop(e.target.value);
                                }}
                            >
                                <ToggleButton value="1">Enable</ToggleButton>
                                <ToggleButton value="0">Disable</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>

                    <br/>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography id="modal-modal-description" sx={{mt: 1.5}}>
                                PRIVATE OR PUBLIC GAME?
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={privateGame}
                                exclusive
                                onChange={(e) => {
                                    setPrivateGame(e.target.value);
                                    if (privateGame === "0") {
                                        setGameKey("");
                                    }
                                }}
                            >
                                <ToggleButton value="0">PUBLIC</ToggleButton>
                                <ToggleButton value="1">PRIVATE</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {privateGame === "1" &&
                            <Box>
                                <Typography id="modal-modal-description" sx={{mt: 1}}>
                                    GAME KEY:</Typography>
                                <br/>
                                <TextField
                                    type="text"
                                    error={gameKeyError}
                                    helperText={gameKeyHelperText}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={gameKey}
                                    variant="outlined"
                                    onChange={(e) => {
                                        if (false) { //TODO\
                                            //setGameKey('');
                                            setGameKeyError(true);
                                            setGameKeyHelperText("can not start with ...");
                                        } else {
                                            setGameKey(e.target.value);
                                            setGameKeyError(false);
                                            setGameKeyHelperText("");
                                        }
                                    }}
                                />
                            </Box>}
                    </Box>
                    <br/>
                    <Button onClick={handleModify}>SUBMIT</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
