import React, { useState, useEffect, useContext } from 'react';
import { rules, validate } from '../shared/validator';
import LayoutConst from "../const/layoutConst";
import {
    Box,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Stack,
    ToggleButtonGroup,
    ToggleButton,
    DialogContentText
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Alert from "@mui/material/Alert";
import {AdminPanelSettings} from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import httpClient from "../shared/HttpClient";

export default function UserMgtPane(props) {
    const [users, setUsers] = useState(null);
    const [open, setOpen] = useState(false);
    const [userMgt, setUserMgt] = useState(null);
    const [confirm, setConfirm] = useState(false);

    /* Fields */
    const [uname, setUname] = useState('');
    const [vldUname, setVldUname] = useState(true);
    const unameRules = [rules.required(), rules.alphanumericunder(), rules.minLength(3), rules.maxLength(15)];
    const [unameHT, setUnameHT] = useState('3~15 letters/numbers/underscores')

    const [disp, setDisp] = useState('');
    const [vldDisp, setVldDisp] = useState(true);
    const dispRules = [rules.required(), rules.minLength(3), rules.maxLength(60)];

    const [pw, setPw] = useState('');
    const [vldPw, setVldPw] = useState(true);
    const pwRules = [rules.required(), rules.password()];

    const [confPw, setConfPw] = useState('');
    const [vldConfPw, setVldConfPw] = useState(true);

    const [email, setEmail] = useState('');
    const [vldEmail, setVldEmail] = useState(true);
    const emailRules = [rules.required(), rules.email()];

    const [verifCode, setVerifCode] = useState('');
    const [vldVerifCode, setVldVerifCode] = useState(true);
    const verifCodeRules = [rules.required()];
    const [verifCodeHT, setVerifCodeHT] = useState('');

    const [verifId, setVerifId] = useState('');

    const [wait, setWait] = useState(false);
    const [cntDn, setCntDn] = useState(0);
    const [btnTxt, setBtnTxt] = useState('Send Code');

    const [userType, setUserType] = useState('');

    /* pagination */
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions(props) {
        const theme = useTheme();
        const {count, page, rowsPerPage, onPageChange} = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{flexShrink: 0, ml: 2.5}}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
                </IconButton>
            </Box>
        );
    }

    function validateFields() {
        let res = [];
        res.push(validate(uname, unameRules, setVldUname));
        res.push(validate(disp, dispRules, setVldDisp));
        res.push(validate(pw, pwRules, setVldPw));
        setVldConfPw(confPw === pw ? true : false);
        res.push(confPw === pw);
        res.push(validate(email, emailRules, setVldEmail));
        res.push(validate(verifCode, verifCodeRules, setVldVerifCode));
        return res.every(e => e);
    }

    useEffect(() => {
        if (wait === true) {
            const waitMs = 5000;
            setCntDn(p => {
                const n = waitMs / 1000;
                setBtnTxt(`Send again (${n})`);
                return n;
            });

            let hdl = setInterval(() => {
                setCntDn(p => {
                    const n = p - 1;
                    setBtnTxt(`Send again (${n})`);
                    return n;
                });
            }, 1000);

            setTimeout(() => {
                setBtnTxt('Send again');
                setWait(false);
                clearInterval(hdl);
            }, waitMs);
        }
    }, [wait]);

    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        var body = {
            username: uname,
            password: pw,
            email: email,
            dispName: disp,
            verifCode: verifCode,
            verifId: verifId,
        };
        httpClient.post('/root/add-admin', body)
            .then(function (data) {
                setOpen(false);
                window.location.reload();
            })
            .catch(function (error) {
                alert(JSON.stringify(error))
            });

        setOpen(false);
    };

    const editUser = () => {
        if (!validate(disp, dispRules, setVldDisp)) {
            return
        }

        var body = {
            id: userMgt.id,
            dispName: disp,
            userType: userType
        };
        httpClient.post('/root/edit-user', body)
            .then(function (data) {
                setUserMgt(null);
                window.location.reload();
            })
            .catch(function (error) {
                alert(JSON.stringify(error))
            });

        setOpen(false);
    };

    const deleteUser = () => {
        httpClient.post('/root/del-user?id=' + userMgt.id)
            .then(function (data) {
                setConfirm(false);
                setUserMgt(null);
                window.location.reload();
            })
            .catch(function (error) {
                alert(JSON.stringify(error))
            });
    };

    const handleSend = () => {
        if (!validate(email, emailRules, setVldEmail)) { return; }

        setWait(true);

        httpClient.post('/user/verify-email', {email: email})
            .then(function (data) {
                setVerifId(data.id);
                setVerifCodeHT(`Code ID: ${data.id}`);
            })
            .catch(function (error) {
                alert(JSON.stringify(error))
            });
    };

    // Check username duplication
    const checkUname = () => {
        return;
    }

    // Check email duplication
    const checkEmail = () => {
        return;
    }

    useEffect(() => {
        httpClient.get('/root/list-user?page=' + page + '&pageSize=' + rowsPerPage)
            .then(function (data) {
                setUsers(data);
            })
    }, [page, rowsPerPage]);

    return (
        <>
            <TableContainer component={Paper} sx={{height: LayoutConst.page.height}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <AdminPanelSettings/> &nbsp;
                    <Typography component='span' variant='h6'>
                        User Management
                    </Typography>
                </Box>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>
                                <Button variant='contained' onClick={() => {
                                    setOpen(true)
                                    setDisp('')
                                }}>New Admin</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.userInfo?.map((user) => (
                            <TableRow>
                                <TableCell><Box>{user.displayName}</Box><Box>@{user.username}</Box></TableCell>
                                <TableCell>{user.userType === 'a' ? 'Admin' : 'User'}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    <Button variant='outlined' color='warning' onClick={() => {
                                        setUserMgt(user)
                                        setUserType(user.userType)
                                        setDisp(user.displayName)
                                    }}>Edit User</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20, {label: 'All', value: -1}]}
                                count={users?.userNo}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose} scroll='paper'>
                <DialogTitle>Add NEW Admin</DialogTitle>
                <DialogContent sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Stack>
                        <Box>
                            <TextField
                                label={'Username'}
                                value={uname}
                                onChange={(e) => setUname(prev => {
                                    validate(e.target.value, unameRules, setVldUname);
                                    return e.target.value;
                                })
                                }
                                onBlur={checkUname}
                                error={!vldUname}
                                helperText={unameHT}
                                sx={{ m: 2 }}
                            />
                            <TextField
                                label={'Display Name'}
                                value={disp}
                                onChange={(e) => setDisp(prev => {
                                    validate(e.target.value, dispRules, setVldDisp);
                                    return e.target.value;
                                })
                                }
                                onBlur={(e) => setDisp((prev => prev.trim().replace(/\s+/ug, ' ')))}
                                error={!vldDisp}
                                sx={{ m: 2 }}
                            />
                        </Box>
                        <Box>
                            <TextField
                                label={'Password'}
                                value={pw}
                                onChange={e => setPw(prev => {
                                    validate(e.target.value, pwRules, setVldPw);
                                    return e.target.value;
                                })
                                }
                                onBlur={e => setVldConfPw(prev => confPw === pw)}
                                error={!vldPw}
                                type='password'
                                sx={{ m: 2 }}
                            />
                            <TextField
                                label={'Confirm Password'}
                                value={confPw}
                                onChange={(e) => setConfPw(prev => {
                                    setVldConfPw(e.target.value === pw ? true : false);
                                    return e.target.value;
                                })
                                }
                                error={!vldConfPw}
                                type='password'
                                sx={{ m: 2 }}
                            />
                        </Box>
                        <Box display='flex' flexWrap='wrap'>
                            <Box component='span'>
                                <TextField
                                    label={'Email'}
                                    value={email}
                                    onChange={(e) => setEmail(prev => {
                                        validate(e.target.value, emailRules, setVldEmail);
                                        return e.target.value;
                                    })
                                    }
                                    onBlur={checkEmail}
                                    error={!vldEmail}
                                    sx={{ m: 2 }}
                                />
                            </Box>
                            <Box component='span' width='300px' display='flex' alignContent='center' justifyContent='flex-start'>
                                <TextField
                                    label={'Verification code'}
                                    value={verifCode}
                                    onChange={(e) => setVerifCode(prev => {
                                        validate(e.target.value, verifCodeRules, setVldVerifCode);
                                        return e.target.value;
                                    })
                                    }
                                    helperText={verifCodeHT}
                                    error={!vldVerifCode}
                                    sx={{ mt: 2, mb: 2, ml: 2, mr: 1, width: '200px' }}
                                />
                                <Button
                                    onClick={handleSend}
                                    disabled={wait}
                                    sx={{ mt: 2, mb: 2, width: '90px' }}
                                >
                                    {btnTxt}
                                </Button>
                            </Box>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                    <Button color="error" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={userMgt} onClose={() => setUserMgt(null)} scroll='paper'>
                <DialogTitle>Edit User {userMgt?.username} (ID:{userMgt?.id})</DialogTitle>
                <DialogContent sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                    <Stack>
                        <TextField
                            label={'Display Name'}
                            defaultValue={userMgt?.displayName}
                            onChange={(e) => setDisp(prev => {
                                validate(e.target.value, dispRules, setVldDisp);
                                return e.target.value;
                            })
                            }
                            onBlur={(e) => setDisp((prev => prev.trim().replace(/\s+/ug, ' ')))}
                            error={!vldDisp}
                            sx={{m: 2}}
                        />
                        <Box sx={{display: 'grid', ml: 2}}>
                            <Typography variant="body2" sx={{ml: 1, color:"gray"}}>
                                User Type:
                            </Typography>
                            <ToggleButtonGroup
                                color="primary"
                                value={userType}
                                exclusive
                                onChange={(e) => {
                                    setUserType(e.target.value);
                                }}
                            >
                                <ToggleButton value="u">User</ToggleButton>
                                <ToggleButton value="a">Admin</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions disableSpacing>
                    <Button color="error" variant="outlined" sx={{ml: 1}}
                            onClick={() => setConfirm(true)}>Delete User</Button>
                    <Button color="primary" variant="contained" sx={{marginLeft: 'auto'}}
                            onClick={editUser}>Submit</Button>
                    {/*<Button color="error" onClick={() => setUserMgt(null)}>Close</Button>*/}
                </DialogActions>
            </Dialog>
            <Dialog open={confirm} onClose={() => setConfirm(false)}>
                <DialogTitle>Are you sure to delete this user?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="error">
                            Once you delete this user, there is no going back. Please be certain!
                        </Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirm(false)}>Cancel</Button>
                    <Button color="error" variant="contained" onClick={deleteUser}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
