import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import NotificationModal from "../shared/NotificationModal";
import ModifyGameModal from "./ModifyGameModal";
import LayoutConst from "../const/layoutConst";
import CreateGameModal from "./CreateGameModal";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {CardActions, Container, Divider, Tooltip} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import httpClient from "../shared/HttpClient";


const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function details(label, value, format) {
    return (
        <Typography variant="body2" color="text.primary">
            <strong>{label}</strong>
            {format ?
                new Date(value).toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric'})
                + " " + new Date(value).toLocaleTimeString()
                : value}
        </Typography>
    )
}

function Row(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");
    const handleCloseNM = () => {
        setOpenNotificationModal(false);
        window.location.reload();
    }

    const [openMGModal, setOpenMGModal] = useState(false);
    const handleCloseMGM = () => setOpenMGModal(false);

    const [gameInfo, setGameInfo] = useState("");

    const rmPlayer = (pid) => {
        httpClient.post(`/game/rm-player?pid=${pid}`)
            .then(function (data) {
                setNotification('User Removed from this game');
                setLocation('/game-mgt');
                setButtonText("Close");
                setOpenNotificationModal(true);
            })
            .catch(function (error) {
                setNotification('Error: ' + error?.data?.msg);
                setLocation('/game-mgt');
                setButtonText("Close");
                setOpenNotificationModal(true);
            });
    }

    const rejectReq = (pid) => {
        httpClient.post(`/game/reject-leave-request?pid=${pid}`)
            .then(function (data) {
                setNotification(`Reject request from player ${pid}`);
                setLocation('/game-mgt');
                setButtonText("Close");
                setOpenNotificationModal(true);
            })
            .catch(function (error) {
                setNotification('Error: ' + error?.data?.msg);
                setLocation('/game-mgt');
                setButtonText("Close");
                setOpenNotificationModal(true);
            });
    }

    const exportRankings = () => {
        httpClient.get(`/game/${row.id}/rankings-export`, {responseType: 'blob'})
            .then((data) => {
                const href = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `game${row.id}_rankings.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }

    const exportOrders = () => {
        httpClient.get(`/game/${row.id}/export`, {responseType: 'blob'})
            .then((data) => {
                const href = URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `game${row.id}_orders.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }

    return (
        <React.Fragment>
            {openNotificationModal &&
                <NotificationModal
                    open={openNotificationModal}
                    handleClose={handleCloseNM}
                    notification={notification}
                    location={location}
                    buttonText={buttonText}/>}
            {openMGModal &&
                <ModifyGameModal
                    open={openMGModal}
                    handleClose={handleCloseMGM}
                    gameInfo={gameInfo}
                />}
            <StyledTableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">{row.id}</StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{row.display_name}</StyledTableCell>
                <StyledTableCell>
                    {row.private_game ?
                        <Box sx={{display: 'flex', alignItems: 'bottom', justifyItems: 'center'}}>
                            <LockIcon fontSize={"small"} sx={{mr: 1}}/>
                            <Typography>Private</Typography>
                        </Box>
                        : 'Public'}
                </StyledTableCell>
                <StyledTableCell align="center">{row.player_num}</StyledTableCell>
                <StyledTableCell>
                    <Button variant="outlined" size="small"
                            onClick={() => {
                                setGameInfo(row);
                                console.log(JSON.stringify(row));
                                setOpenMGModal(true);
                            }}>Modify</Button>
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" fontWeight="bold" sx={{mt: 1}}>
                                            Details
                                        </Typography>
                                        <Divider/>
                                        <Stack sx={{mt: 1}} spacing={1}>
                                            {details('Owner Info: ', (row.display_name + ' @' + row.username))}
                                            {details('Game Type: ', row.private_game ? 'Private Game' : 'Public Game')}
                                            {details('Description: ', row.description)}
                                            {details('Initial Cash: ', '$' + row.init_cash)}
                                            {details('Create Time: ', row.create_time_utc, true)}
                                            {details('Start Time: ', row.start_time_utc, true)}
                                            {details('End Time: ', row.end_time_utc, true)}
                                        </Stack>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" variant='contained' onClick={exportOrders}>Export
                                            Orders</Button>
                                        <Button size="small" variant='contained' onClick={exportRankings}>Export
                                            Rankings</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={9}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Display Name</StyledTableCell>
                                            <StyledTableCell>Username</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Request Leave</StyledTableCell>
                                            <StyledTableCell align='center'>Options</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.players.map((player) => (
                                            <StyledTableRow key={player.pid}>
                                                <TableCell>{player.display_name}</TableCell>
                                                <TableCell>{player.username}</TableCell>
                                                <TableCell>{player.email}</TableCell>
                                                <TableCell>
                                                    {player.leave_time ? "At " + new Date(player.leave_time).toLocaleString() : 'No'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Button size='small' variant='outlined'
                                                            color={player.leave_time ? "warning" : 'error'}
                                                            onClick={() => rmPlayer(player.pid)}>
                                                        {player.leave_time ? 'Approve' : "Remove Player"}
                                                    </Button>
                                                    {player.leave_time &&
                                                        <Button size='small' variant='outlined' sx={{ml: 1}}
                                                                onClick={() => rejectReq(player.pid)}>
                                                            Reject
                                                        </Button>
                                                    }
                                                </TableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

export default function GameMgtPage(props) {
    const [games, setGames] = useState([]);
    const [openCGModal, setOpenCGModal] = useState(false);
    const handleCloseCGM = () => setOpenCGModal(false);

    useEffect(() => {
        httpClient.get('/game/manage/all-games')
            .then(function (data) {
                setGames(data);
            });
    }, []);

    return (
        <>
            <TableContainer component={Paper} sx={{height: LayoutConst.page.height}}>
                <Typography variant="h5" sx={{p: 1}}>Your Games:</Typography>
                <Table stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell/>
                            <StyledTableCell align="center">Game ID</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Owner</StyledTableCell>
                            <StyledTableCell>Game Type</StyledTableCell>
                            <StyledTableCell align="center">No. of Players</StyledTableCell>
                            <StyledTableCell>
                                <Button variant="contained" size="small" color="warning"
                                        onClick={() => setOpenCGModal(true)}>
                                    Create New Game
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {games.map((row) => (
                            <Row row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CreateGameModal open={openCGModal} handleClose={handleCloseCGM}/>
        </>
    );
}
