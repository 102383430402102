import React, {useContext} from "react";
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider";
import {Skeleton, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import httpClient from "../shared/HttpClient";
import GameContext from "../contexts/GameContext";

export default function OwnerLabel(props) {
    const gameCtx = useContext(GameContext);

    const exportOrders = () => {
        if (gameCtx.gid) {
            httpClient.get(`/game/${gameCtx.gid}/user-export`,{responseType: 'blob'})
                .then((data) => {
                    const href = URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `game${gameCtx.gid}_${props.owner?.username}_orders.csv`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                });
        }
    }

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h3" className="font size-20 pb-2" color="text.primary">Player:&nbsp; <span
                    className="font-weight-bold">{props.owner?.dispName} (@{props.owner?.username})</span></Typography>
                <Tooltip title="Export your orders" placement="bottom" arrow>
                    <IconButton onClick={exportOrders}>
                        <DownloadForOfflineIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider />
            <Typography variant="subtitle1" color="text.secondary" align="left">
                <span className="font size-13"><i class="fa fa-info-circle"></i>&nbsp;Account values updated at: </span>
                <Box sx={{ display: 'inline', width: 300 }} component="span">
                    {props.updTime
                        ? <Typography component="span">{(new Date(props.updTime)).toLocaleString()}</Typography>
                        : <Skeleton sx={{ display: 'inline-block', width: 300 }} variant="text" />}
                </Box>
            </Typography>
        </Box>
    )
}
