import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

export default function ExpiredDialog(props) {

    const [resent, setResent] = useState(false);

    const closeWindow = () => {
        window.close();
    };

    const handleResend = () => {
        console.log('handle Resend')
        

        // if ok
        setResent(true);
    };

    return (
        <Dialog open={props.open}
                onClose={props.handleClose}>
            <DialogTitle>Link Expired</DialogTitle>
            <DialogContent>
                <Typography>This password reset link has expired.</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={resent} onClick={handleResend}>Resend Link</Button>
                <Button color="primary" onClick={closeWindow}>Close Window</Button>
            </DialogActions>
        </Dialog>
    );
}
