import React, { useState, useContext, useEffect } from 'react';
import {BrowserRouter, Route, Routes, Navigate, useLocation} from "react-router-dom";
import './App.css';
import GameContext from './contexts/GameContext';
import UtilContext from './contexts/UtilContext';
import { useWillMount } from './shared/utils';
import NavBar from "./shared/NavBar";
import Box from '@mui/material/Box';
import publicRoutes from "./routes/publicRoutes";
import privateRoutes from "./routes/privateRoutes";
import DateAdapter from '@mui/lab/AdapterLuxon';
import { LocalizationProvider } from '@mui/lab';

import {ThemeProvider, useTheme, createTheme} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
// import LinksWidget from './shared/LinksWidget';
import LoginContext from './contexts/LoginContext';
import image from './loginbg.jpg';
import NavBarBottom from './shared/NavbarBottom';

function RequireAuth(props) {
    let location = useLocation();
    if (localStorage.hasOwnProperty("userInfo") &&
        props.role.indexOf(JSON.parse(localStorage.getItem('userInfo')).userType) > -1) {
        return props.element;
    }
    return <Navigate to="/err/403" state={{from: location}}/>;
}

function App() {

    const [gameCtx, setGameCtx] = useState({});  // Update gameCtx ONLY by calling `mergeGameCtx`!
    const [loginUser, setLoginUser] = useState(undefined);
   
    const [mode, setMode] = useState(localStorage.getItem('themeMode') ? localStorage.getItem('themeMode') : 'dark');
    const getDesignTokens = (mode) => ({
        palette: {mode},
        typography: {fontSize: 12}
    });
    const darkModeTheme = createTheme(getDesignTokens(mode));
    const [bgImg, setBgImg] = useState(undefined);

    const setTheme = () => {
        if (mode === 'light') setMode('dark')
        else setMode('light')
    }

    useEffect(() => {

        localStorage.setItem('themeMode', mode);

        if (mode === 'dark') {
      // document.querySelector('body').setAttribute('style', 'background: #1d1f23');
        } else {
           // document.querySelector('body').setAttribute('style', 'background: #FFFFFF');
        }
    }, [mode])

    const mergeGameCtx = (ctx) => {
        setGameCtx(prev => {
            const curr = { ...prev, ...ctx };
            localStorage.setItem('gameCtx', JSON.stringify(curr));
            return curr;
        });
    }
    const clearGameCtx = () => {
        setGameCtx(prev => {
            const curr = {};
            localStorage.setItem('gameCtx', JSON.stringify(curr));
            return curr;
        });
    }

    useWillMount(() => {
        const ctx = JSON.parse(localStorage.getItem('gameCtx'))
        if (ctx != null) { setGameCtx(ctx); }
    }, [])

    const crashFallback = () => {
        window.addEventListener('click', e => {
            if (e.target.className === 'close') {
                localStorage.clear();
                window.location.replace('/');
            }
        })
    };

    return (
        <div className="App" style={{
            backgroundImage:`url(${bgImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            width: "100vw",
            height: "100vh" }}>
            <Sentry.ErrorBoundary showDialog fallback={crashFallback}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <LoginContext.Provider value={{setLoginUser, loginUser}}>
                        <GameContext.Provider value={{setContext: mergeGameCtx, clear: clearGameCtx, ...gameCtx}}>
                            <ThemeProvider theme={darkModeTheme}>
                                <UtilContext.Provider value={{ setBgImg: setBgImg }}>
                                    <BrowserRouter basename={process.env.REACT_APP_DEPLOY_DIR}>  {/* env var REACT_APP_DEPLOY_DIR is optional, e.g. /sub-dir/foo */}
                                        <NavBar mode={mode} setTheme={() => setTheme()}/>
                                        <Routes>
                                            {publicRoutes.map(
                                                ({path, element, ...routes}) =>
                                                    <Route key={path} path={path} element={element} {...routes}/>
                                            )}
                                            {privateRoutes.map(
                                                ({path, element, role, ...routes}) =>
                                                    <Route key={path} path={path} element={<RequireAuth role={role} element={element}/>} {...routes}/>
                                            )}
                                        </Routes>
                                        <Box>
                                        
                                    <NavBarBottom  mode={mode} setTheme={() => setTheme()}></NavBarBottom>
                                   
                                        </Box>
                                    </BrowserRouter>
                                </UtilContext.Provider>
                                {/* <LinksWidget /> */}
                            </ThemeProvider>
                        </GameContext.Provider>
                    </LoginContext.Provider>
                </LocalizationProvider>
            </Sentry.ErrorBoundary>
    </div>
);

}

export default App;
