//@ts-check
import { useState, useEffect, useContext } from 'react';
import { validate, rules } from '../shared/validator';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, Stack, Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ResDialog from './ResDialog';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import httpClient from "../shared/HttpClient";


export default function SignUpDialog(props) {
    /* Fields */
    const uhtEnum = { TIPS: '3~15 letters, numbers or "_"',
                      OCC: 'This username is taken' }
    const [uname, setUname] = useState('');
    const [vldUname, setVldUname] = useState(true);
    const unameRules = [rules.required(), rules.alphanumericunder(), rules.minLength(3), rules.maxLength(15)];
    const [unameHT, setUnameHT] = useState(uhtEnum.TIPS);

    const [disp, setDisp] = useState('');
    const [vldDisp, setVldDisp] = useState(true);
    const dispRules = [rules.required(), rules.minLength(1), rules.maxLength(60)];

    const [pw, setPw] = useState('');
    const [vldPw, setVldPw] = useState(true);
    const pwRules = [rules.required(), rules.password()];

    const [confPw, setConfPw] = useState('');
    const [vldConfPw, setVldConfPw] = useState(true);

    const [email, setEmail] = useState('');
    const [vldEmail, setVldEmail] = useState(true);
    const emailRules = [rules.required(), rules.email()];

    const [verifCode, setVerifCode] = useState('');
    const [vldVerifCode, setVldVerifCode] = useState(true);
    const verifCodeRules = [rules.required()];
    const [verifCodeHT, setVerifCodeHT] = useState('');

    const [verifId, setVerifId] = useState('');

    const [wait, setWait] = useState(false);
    const [cntDn, setCntDn] = useState(0);
    const [btnTxt, setBtnTxt] = useState('Send Code');

    const chkEnum = { INIT: 0, ING: 1, OK: 2, OCC: 3 };
    const [chkSts, setChkSts] = useState(chkEnum.INIT);

    /* ResDialog props */
    const [resOpen, setResOpen] = useState(false);
    const [resTitle, setResTitle] = useState('');
    const [resContent, setResContent] = useState('');
    const [resBtnTxt, setResBtnTxt] = useState('');
    const [resOnBtnClick, setResOnBtnClick] = useState(() => {/* Do nothing */});

    /* ResDialog actions */
    const closeRes = () => setResOpen(false);

    const closeAll = () => {
        setResOpen(false);  // Close ResDialog
        props.setOpen(false);  // Close SignUpDialog (current)
    }

    function promptRes(title, content, btnTxt, onBtnClick) {
        setResTitle(title);
        setResContent(content);
        setResBtnTxt(btnTxt);
        setResOnBtnClick(prev => onBtnClick);
        setResOpen(true);
    }

    function validateFields() {
        let res = [];
        res.push(validate(uname, unameRules, setVldUname));
        res.push(validate(disp, dispRules, setVldDisp));
        res.push(validate(pw, pwRules, setVldPw));
        setVldConfPw(confPw === pw ? true : false);
        res.push(confPw === pw);
        res.push(validate(email + '@' + domain, emailRules, setVldEmail));
        res.push(validate(verifCode, verifCodeRules, setVldVerifCode));
        return res.every(e => e);
    }

    useEffect(() => {
        if (wait === true) {
            const waitMs = 30000;
            setCntDn(p => {
                const n = waitMs / 1000;
                setBtnTxt(`Send again (${n})`);
                return n;
            });

            let hdl = setInterval(() => {
                setCntDn(p => {
                    const n = p - 1;
                    setBtnTxt(`Send again (${n})`);
                    return n;
                });
            }, 1000);

            setTimeout(() => {
                setBtnTxt('Send again');
                setWait(false);
                clearInterval(hdl);
            }, waitMs);
        }
    }, [wait]);

    useEffect(() => {
        setChkSts(chkEnum.INIT);
    }, [uname]);

    // Change username helper text according to chkSts
    useEffect(() => {
        if (chkSts === chkEnum.INIT) {
            setUnameHT(uhtEnum.TIPS);
        } else if (chkSts === chkEnum.OCC) {
            setUnameHT(uhtEnum.OCC);
        }
    }, [chkSts]);

    const renderChkSts = () => {
        switch (chkSts) {
            case chkEnum.INIT:
                return '';
            case chkEnum.ING:
                return (<Box sx={{ display: 'flex' }}>
                            <CircularProgress size={20}/>
                        </Box>);
            case chkEnum.OK:
                return <CheckIcon color='success' size={20} />;
            case chkEnum.OCC:
                return <CloseIcon color='error' size={20} />;
        }
    }

    const handleClose = () => props.setOpen(false);

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        var body = {
            username: uname,
            password: pw,
            email: email,
            dispName: disp,
            verifCode: verifCode,
            verifId: verifId,
        };

        httpClient.post('/user/sign-up', body)
            .then(function (data) {
                promptRes('Success', <Typography>Sign up completed. Enjoy trading!</Typography>,
                    'Close', closeAll);
            })
            .catch(function (error) {
                switch (error.status) {
                    case 400:
                        promptRes('Input Invalid', <Typography>{error.data}</Typography>,
                            'Back', closeRes);
                        break;
                    case 401:
                        promptRes('Verification Failed', <Typography>Wrong verification code</Typography>,
                            'Back', closeRes);
                        break;
                    case 409:
                        promptRes('Duplicated', <Typography>{error.data}</Typography>,
                            'Back', closeRes);
                        break;
                    case 500:
                        promptRes('Server Error', <Typography>Server error. Please try again later or report the issue to the administrator.</Typography>,
                            'Back', closeAll);
                        break;
                }
            });

    };


    const handleSend = () => {
        if (!validate(email + '@' + domain, emailRules, setVldEmail)) { return; }

        setWait(true);

        httpClient.post('/user/verify-email', { email: email + '@' + domain })
            .then(function (data) {
                setVerifId(data.id);
                setVerifCodeHT(`Code ID: ${data.id}`);
            })
            .catch(function (error) {
                alert(error.data);
            });
    };

    // Check username duplication
    const checkUname = () => {

        if (!vldUname) { return; }  // Skip, if frontend checking failed already
        setChkSts(chkEnum.ING);  // Change chkSts to ING
        httpClient.get('/user/check-user?user=' + uname)
            .then(function (data) {
                switch (data.code) {
                    case 200:
                        setChkSts(chkEnum.OK);  // Change chkSts to OK
                        break;
                    case 400:
                        setChkSts(chkEnum.INIT);  // Change chkSts to INIT (User has to re-enter)
                        setVldUname(false);
                        break;
                    case 409:
                        setChkSts(chkEnum.OCC);  // Change chkSts to OCC (username is taken)
                        setVldUname(false);
                        break;
                }
            });
    }

    // Check email duplication
    const checkEmail = () => {
        return;
    }

    // Email domain validation
    const [domain, setDomain] = useState('');
    const [allowDomains, setAllowDomains] = useState([]);
    
    useEffect(() => {
        httpClient.get('/allow-domain').then(function (data) {
            setAllowDomains(data);
            if (!domain) {
                setDomain(data[0].domain);
            }
        });
    }, []);

    return(
        <Dialog open={props.open}
            onClose={props.handleClose}
            scroll='paper'
            className="dialog-styling"

            >
            <DialogTitle>
            <div className="color-blue"><i className="fa fa-bar-chart"></i>&nbsp;<span class="size-16 font weight-bold ">simutrade</span></div>
            <div><h3 className="title color-white size-25 font weight-600 mt-2">Sign Up to Simutrade </h3></div>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap',padding:{xs:'20px 5px'} }} >



           <Box className="container" >
               <div className="row">
                   <div className=" col-12">
                   <TextField
                            label={'Username'}
                            value={uname}
                            onChange={(e) => setUname(prev => {
                                validate(e.target.value, unameRules, setVldUname);
                                return e.target.value;
                            })
                            }
                            variant="standard"
                            onBlur={checkUname}
                            error={!vldUname}
                            helperText={unameHT}
                            
                           className="input-container mt-0"
                            InputProps={{ endAdornment: renderChkSts() }}
                        />
                   </div>
                   <div className=" col-12">
                   <TextField
                            label={'Display Name'}
                            value={disp}
                            variant="standard"
                            onChange={(e) => setDisp(prev => {
                                validate(e.target.value, dispRules, setVldDisp);
                                return e.target.value;
                            })
                            }
                            onBlur={(e) => setDisp((prev => prev.trim().replace(/\s+/ug, ' ')))}
                            error={!vldDisp}
                            className="input-container mt-4"
                        />
                   </div>
               </div>
               <div className="row">
                   <div className=" col-12">
                   <TextField
                            label={'Password'}
                            variant="standard"
                            helperText={
                                '1+ digits, 1+ letters; length ≥ 8'
                            }
                            value={pw}
                            onChange={e => setPw(prev => {
                                validate(e.target.value, pwRules, setVldPw);
                                return e.target.value;
                            })
                            }
                            onBlur={e => setVldConfPw(prev => confPw === pw)}
                            error={!vldPw}
                            type='password'
                            className="input-container mt-4"
                        />
                   </div>
                   <div className=" col-12">
                   <TextField
                            label={'Confirm Password'}
                            variant="standard"
                            value={confPw}
                            onChange={(e) => setConfPw(prev => {
                                setVldConfPw(e.target.value === pw ? true : false);
                                return e.target.value;
                            })
                            }
                            error={!vldConfPw}
                            type='password'
                            className="input-container mt-4"
                        />
                   </div>
               </div>
               <div className="row">
                   <div className="col-8">
                   <TextField
                                label={'Email'}
                                value={email}
                                onChange={(e) => setEmail(prev => {
                                    validate(e.target.value + '@' + domain, emailRules, setVldEmail);
                                    return e.target.value;
                                })
                                }
                                variant="standard"
                                onBlur={checkEmail}
                                error={!vldEmail}
                                className="input-container"
                                sx={{ mt: 5 }}
                            />
                   </div>
                   <div className="col-4 d-flex align-items-end">
                   <FormControl variant='standard' sx={{ minWidth: 120, mt: 5 }} className="input-container">
                    {/* <InputLabel id="email-domain-label">Domain</InputLabel> */}
                    <Select
                        // className="input-container"
                        labelId="email-domain-label"
                        id="email-domain"
                        value={domain}
                        // label="Domain"
                        onChange={(e) => setDomain(e.target.value)}
                    >
                    {allowDomains.map((domain, index) => (
                        <MenuItem key={index} value={domain.domain}>@{domain.domain}</MenuItem>
                    ))}
                    </Select>
                   </FormControl>
                   </div>
                   <div className=" col-sm-8 col-12">
                   <TextField
                                label={'Verification code'}
                                value={verifCode}
                                onChange={(e) => setVerifCode(prev => {
                                    validate(e.target.value, verifCodeRules, setVldVerifCode);
                                    return e.target.value;
                                })
                                }
                                variant="standard"
                                helperText={verifCodeHT}
                                error={!vldVerifCode}
                                className="input-container mt-4"
                            />
                   </div>
                   <div className="col-sm-4 col-12 ">
                   <Button
                                onClick={handleSend}

                                disabled={wait}
                                className="input-container color-blue mt-4"
                                size="large"


                            >
                                {btnTxt}
                            </Button>
                   </div>
               </div>
           </Box>

            </DialogContent>
            <DialogActions>

                <Button  className="color-white font" variant="outlined" size="large" onClick={handleClose}>Close</Button>
                <Button color="info" className="color-white font bg-blue" variant="contained" size="large" onClick={handleSubmit}>Sign Up</Button>
            </DialogActions>
            <ResDialog open={resOpen} title={resTitle} content={resContent} btnTxt={resBtnTxt} onBtnClick={resOnBtnClick} />
        </Dialog>
    )
}
