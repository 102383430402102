import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import NotificationModal from "../shared/NotificationModal";
import JoinGameModal from "./JoinGameModal";
import GameKeyModal from "./GameKeyModal";
import GameContext from "../contexts/GameContext";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {Divider, Tooltip} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LockIcon from '@mui/icons-material/Lock';
import { func } from 'prop-types';
import httpClient from "../shared/HttpClient";
import PeopleIcon from '@mui/icons-material/People';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function details(label, value, format) {
    return (
        <Typography variant="body2"  className='font size-14' sx={{fontWeight:'600'}}>
            <strong>{label}</strong>
            {format ?
                new Date(value).toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric'})
                + " " + new Date(value).toLocaleTimeString()
                : value}
        </Typography>
    )
}
function get_formated_date(value){
    return  new Date(value).toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric'})+ " " + new Date(value).toLocaleTimeString();
}

function get_game_status(created,start,ended){
    return <span>
        {
            new Date()>new Date(get_formated_date(ended))?  <span className='font weight-600 text-danger'>Ended</span>:
            new Date()<new Date(get_formated_date(start))?
            <span className='font weight-600 text-primary '>Not Started</span>: <span className='font weight-600 text-info'>In Process</span>
        
        }
    </span>
}
function get_simplified_date(date){
    const current_date=new Date();
    const create_date=new Date(date);
   
    return ''

}
function Row(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const gameCtx = useContext(GameContext);
    const ifJoin = (gameCtx.gameList && gameCtx.gameList.includes(row.id))

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");
    
    const handleCloseNM = () => {
        setOpenNotificationModal(false);
    }

    const join = (gid) => {
        httpClient.post("/game/join", {gid: gid})
            .then(function (data) {
                setNotification("Game joined successfully.");
                setLocation("/games");
                setButtonText("Back to game page")
                setOpenNotificationModal(true);
                props.refetch();
            })
            .catch(function (error) {
                setNotification("Failed to join. (" + error?.data?.msg + ")");
                setLocation("/games");
                setButtonText("Back to game page")
                setOpenNotificationModal(true);
                props.refetch();
            });
    };

    const [openGKModal, setOpenGKModal] = useState(false);
    const handleCloseGKM = () => setOpenGKModal(false);

    const [gid, setGID] = useState(1);
  

    return (
        <React.Fragment>
            {openNotificationModal &&
                <NotificationModal
                    open={openNotificationModal}
                    handleClose={handleCloseNM}
                    notification={notification}
                    location={location}
                    buttonText={buttonText}/>}
 <TableRow>
            <div className='mt-3'>
           
           <div class="card boxshadow allgames-section">
           <div class="card-body">
               <div className='row'>
                   <div className='col-7 color-white text-left'><strong className='font size-15 weight-600'>{row.name}&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                   <span style={{'display':row.private_game == 1 ? '':'none'}}></span>
                   <i class="fa fa-lock" style={{'display':row.private_game == 1 ? '':'none'}} >&nbsp;&nbsp;</i>
                   <small className=' text-uppercase size-13 font' style={{'display':row.private_game == 1 ? '':'none'}}>Private&nbsp;&nbsp;</small>
                   
                 
                   
                   <i class="fa fa-clock-o"></i><small className='text-uppercase font size-13'> &nbsp;{get_game_status(row.create_time_utc,row.start_time_utc,row.end_time_utc)}</small>
                   
                   &nbsp;&nbsp;&nbsp;
                   <i class="fa fa-users" title='players'>&nbsp;&nbsp;</i>
                   <small className=' text-uppercase size-13 font'>{row.player_num} </small>
                   
                   </div>
                   <div className='col-5 text-right '><span class="badge badge-pill badge-secondary p-2 pl-3 pr-3 font size-13 weight-500">Owner: {row.display_name}</span>
                
                
                   <span class="d-block d-sm-inline mt-2 mt-sm-0 ml-sm-1 ml-0"  >
                   <button 
                   
                   onClick={() => {
                    setGID(row.id);
                    console.log(row.private_game);
                    if (row.private_game === 1) {
                        setOpenGKModal(true);
                    } else {
                        join(row.id);
                    }
                }}
                   className='badge badge-pill border-0 badge-primary p-2 pl-3 pr-3 font size-13 weight-500' style={{background: ifJoin ? 'rgb(27 117 187 / 64%)':'#1b75bb',color: ifJoin ? 'rgba(255, 255, 255, 0.16) !important' :'#fff' ,cursor:ifJoin?'not-allowed':'pointer'}}    type='button' disabled={ifJoin}>
                                
                                {ifJoin ? 'Joined ':'Join '}
                                
                                {ifJoin ? '': <i className='fa fa-paper-plane-o'></i>}
                                </button>
                   </span>
                   </div>
               </div>
               <div className='row'><div className='col-12'><hr className='border-color-secondary'></hr></div></div>
               <div className='row'><div className='col-12'><p className='color-white text-left size-15 font '>{ row.description}</p></div></div>
               <div className='row color-white text-left'>
                 <div className='col-12'>
                     <div className='row font size-15 text-left'>
                         <div className='col'>Game Id</div>
                         <div className='col '>Created</div>
                         <div className='col '>Starts On</div>
                         <div className='col '>Ends On</div>
                         <div className='col'>Initial Cash</div> 
                 
                     </div>
                     <div className='row font size-14 mt-2 text-left'>
                       <div className="col">{row.id}</div>
                       <div className='col text-info ' >
                       {details('', row.create_time_utc, true)}
                       
                   </div>
                       <div className='col text-primary '> {details('', row.start_time_utc, true)}</div>
                      <div className='col text-danger '>{details('', row.end_time_utc, true)}</div>
                    
                      
                       <div className='col'> { '$' + (Number(row.init_cash)).toLocaleString()}</div> 
                       
               
                   </div>
                
                 
                 </div>
              
                 
               </div>
               <div className='row mt-2'>
               <div className='col-12 text-right'>
                
                 </div>
               </div>
               
              
           </div>
           
           </div>
           
           </div>
           </TableRow>
            <GameKeyModal open={openGKModal} handleClose={handleCloseGKM} gid={gid} refetch={props.refetch}/>
        </React.Fragment>
    );
}

export default function AllGamesPane(props) {
    const [openJGModal, setOpenJGModal] = useState(false);
    const handleCloseJGM = () => setOpenJGModal(false);
    const [allGamesLoadMore, setAllGamesLoadMore] = useState("5");
    const  loadMoreGames = () => {
        setAllGamesLoadMore(Number(allGamesLoadMore)+5)
      }

    return (
        <>
            <Typography variant="h5" className='font size-18 color-white text-left'  sx={{p: 1,align:'center'}}>
                Find a Game:
            </Typography>
         
                        
            <div className='card'>
            <Button size="small" className='p-4 position-relative' onClick={() => setOpenJGModal(true)}>
            <span className='font size-15 color-white' style={{position:'absolute',left:'15px',textTransform:'none'}}>Enter Game ID*, Key for Private Games to Join</span>
                                    <span style={{position:'absolute',right:'15px'}}>
                                 
                                    <SearchIcon/>
                                    </span>
                                   
                                        
                            </Button>
            </div>

            {props.games.slice(0,allGamesLoadMore).map((row) => (
                        <Row row={row} refetch={props.refetch}/> 
                        
                    ))}
            <div style={{display:Number(allGamesLoadMore)>=props.games.length?'none':''}}>
                <div className='card cursor-pointer allgames-section'>
                    <div className='card-body p-2 color-white size-12 font' onClick={loadMoreGames}>
                        Load More &nbsp; <i className='fa fa-angle-down'></i>
                    </div>
                </div>
            </div>
            <JoinGameModal open={openJGModal} handleClose={handleCloseJGM} refetch={props.refetch}/>
        </>
    );
}
