import {notifyNegRes, notifyServiceDown} from "../errors/sentry-handlers";

const errorInterceptor = (axiosInstance) => {

    axiosInstance.interceptors.response.use((response) => {
        console.log(response)
        if (response.headers['x-set-token']) {
            var userInfo = JSON.parse(localStorage.getItem('userInfo'));
            userInfo.authToken = response.headers['x-set-token'];
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
        return response.data
    }, (error) => {
        if (error?.config?.method === 'get') {
            if (error?.response?.status === 401 || error?.response?.status === 403) {
                window.location.replace('/err/403');
            } else if (error?.response?.status === 404) {
                window.location.replace('/err/404');
            } else {
                notifyServiceDown(error);
            }
        } else {
            if (error.response) {
                notifyNegRes(error.response);
            } else {
                notifyServiceDown(error);
            }
            return Promise.reject(error.response);
        }
    });

};

export default errorInterceptor;
