import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
export default function ResDialog(props) {
    
    return (
        <Dialog open={props.open}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>{props.content}</DialogContent>
            <DialogActions>
                <Button color='primary' onClick={props.onBtnClick}>{props.btnTxt}</Button>
            </DialogActions>
        </Dialog>
    );
}
