import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import GameContext from '../contexts/GameContext';
import {wrapAuth} from "../shared/utils"
import httpClient from "./HttpClient";

import {styled, alpha} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {Button, Container} from '@mui/material';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LayoutConst from "../const/layoutConst";
import PersonIcon from '@mui/icons-material/Person';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 1),
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.selected,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AuthButton = wrapAuth(Button);

export default function NavBar(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [userInfo, setUserInfo] = React.useState({});
    const [anchorPopover, setAnchorPopover] = React.useState(null);
    const [searchContent, setContent] = React.useState(null);
    const [searchRes, setRes] = React.useState([]);
    const [navbarStatus, setNavbar] = React.useState('inline');
    const location = useLocation();

    const {mode, setTheme} = props;

    useEffect(() => {
        if (userInfo === {} || userInfo === null || location.pathname === '/' || location.pathname === '/forgot') {
            setNavbar('none');
        } else {
            setNavbar('inline');
        }
    }, [location, userInfo]);

    useEffect(() => {
        setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
    }, [location]);

    useEffect(() => {
        if (searchContent) {
            httpClient.get('/stock/search?key=' + searchContent)
                .then(function (data) {
                    setRes(data);
                });
        } else {
            setRes([])
        }
    }, [searchContent]);

    const navigate = useNavigate();

    const gameCtx = useContext(GameContext)

    const [searchParams, setSearchParams] = useSearchParams();

    const clickSearchItem = (stockClicked) => {
        console.log("Stock：" + JSON.stringify(stockClicked) + " clicked!");
        setAnchorPopover(null);
        if (window.location.pathname.search('/mkt') === -1) navigate(`/mkt/${gameCtx.gid}?stk=${stockClicked.id}`);
        else setSearchParams({stk: stockClicked.id});
    }

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        handleMenuClose();
        console.log("Logging out");

        httpClient.post('/user/logout')
            .then(function (data) {
                localStorage.removeItem('userInfo');
                gameCtx.clear();
                navigate('/');
            });
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleSearchClick = (event) => {
        setAnchorPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorPopover(null);
    };

    const handleSearchContent = (event) => {
        setContent(event.target.value);
        setAnchorPopover(event.currentTarget);
    };

    const open = Boolean(anchorPopover);

    return (
        <>
            <AppBar position="static" style={{display: navbarStatus}}>
                <Toolbar variant='dense' sx={{pl:1}}>
                    <Box sx={{cursor: 'pointer'}}>
                        <Typography variant='h6' edge="start" className='font size-16 '
                                    sx={{ mr: 2, ml: 1 }}>
                                       <i class="fa fa-bar-chart"></i>&nbsp;<span class="size-17 font weight-bold ">Simutrade</span>
                                        </Typography>
                    </Box>
                    <Typography
                        noWrap
                        component="div"
                        sx={{display: {xs: 'none', sm: 'block'}}}
                        variant='h6'
                    >
                        <Button size='medium' className='font menu-items color-white' onClick={() => navigate('/games')}>
                            Games
                        </Button>
                        <Button size='medium' className='font menu-items color-white'
                                onClick={() => {
                                    navigate(`/acc/${gameCtx.gid}`);
                                }}
                                style={{display: gameCtx.gid == null ? 'none' : 'inline'}}>Account</Button>
                        <Button size='medium' className='font menu-items color-white'
                                onClick={() => {
                                    navigate(`/mkt/${gameCtx.gid}`);
                                }}
                                style={{display: gameCtx.gid == null ? 'none' : 'inline'}}>Market</Button>
                        <AuthButton role={['a', 'r']}

                                    variant="outlined"
                                    size='medium' className='font menu-items color-white'
                                    onClick={() => {
                                        navigate('/game-mgt')
                                    }}>
                            Manage Games</AuthButton>
                        <AuthButton role={['r']}
                                   size='medium'
                                   className='font menu-items color-white'
                                    variant="outlined"

                                    sx={{ml:1}}
                                    onClick={() => {
                                        navigate('/app-mgt')
                                    }}>
                            Manage App</AuthButton>
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Search
                        sx={{display: gameCtx.gid == null ? 'none' : 'flex',
                        width:{xs:'50%',md:'auto'}
                    }}
                        onClick={handleSearchClick}>
                        <SearchIconWrapper>
                            <SearchIcon fontSize='small'/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            autoFocus
                            placeholder="Search Stock…"
                            inputProps={{'aria-label': 'search'}}
                            onChange={handleSearchContent}
                            className="font size-13"
                        />
                    </Search>
                    <Popover
                        open={open}
                        anchorEl={anchorPopover}
                        onClose={handleClosePopover}
                        anchorOrigin={{vertical: 'bottom'}}
                        disableAutoFocus={true}
                        disableEnforceFocus={true}
                    >
                        <Box
                            sx={{
                                width: '30ch'
                            }}>
                            <Table aria-label="simple table">
                                {searchRes.length === 0 ?
                                    (<TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                {/*<CircularProgress/>*/}
                                                <strong class="font size-15">No Result</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>) : ''}
                                <TableBody>
                                    {searchRes.map((row) => (
                                        <StyledTableRow className='cursor-pointer' selected onClick={() => clickSearchItem(row)}>
                                            <TableCell className='font size-14'>{row.symbol}</TableCell>
                                            <TableCell className='font size-14' align="right">{row.stkName}</TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Popover>
                    <Box
                         sx={{display: {xs:'none',md:navbarStatus}}}>
                        <Button alignCenter className='font color-white size-14' size='medium'
                                onClick={handleProfileMenuOpen}
                        >

                            {userInfo == null ? '' : userInfo.displayName}
                            <AccountCircle size='medium' sx={{ ml: 1 }}/>
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isMenuOpen} onClose={handleMenuClose}
            >
                <MenuItem className='font size-14' onClick={() => navigate('/profile')}>
                    <PersonIcon size={"small"}/>&nbsp;Profile
                </MenuItem>
                <MenuItem className='font size-14'
                          onClick={() => window.open(process.env.REACT_APP_SWP_URL + '/login?token=' + JSON.parse(localStorage.getItem('userInfo'))?.authToken, '_blank')}>
                    <WorkspacePremiumIcon size={'small'}/>&nbsp;View Student Works
                </MenuItem>
                <MenuItem className='font size-14' onClick={handleLogout}><i className="fa fa-sign-out"></i>&nbsp;Logout</MenuItem>
                <MenuItem className='d-none'>
                    Theme:
                    <IconButton onClick={() => setTheme()}>
                        {mode === 'light' ? <Brightness7Icon/> : <Brightness4Icon/>}
                    </IconButton>
                </MenuItem>
            </Menu>
        </>
    );
}
