import React, {useState, useEffect, useContext} from 'react';
import {styled, useTheme} from "@mui/material/styles";

import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import httpClient from "../shared/HttpClient";
import {Button, TextField} from "@mui/material";
import {rules, validate} from "../shared/validator";
import {useNavigate} from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import LoginContext from "../contexts/LoginContext";

const StyledTableRow = styled(TableRow)(({theme}) => ({
    border: 0
}));

function UserType(type) {
    if (type === 'u') {
        return 'User'
    } else if (type === 'a') {
        return 'Administrator'
    } else if (type === 'r') {
        return 'System Maintainer'
    }
};

export default function Profile(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const loginCtx = useContext(LoginContext);

    const [userInfo, setUserInfo] = useState();

    const [disp, setDisp] = useState('');
    const [vldDisp, setVldDisp] = useState(true);
    const dispRules = [rules.required(), rules.minLength(1), rules.maxLength(60)];

    const [oldPw, setOldPw] = useState(null);
    const [newPw, setNewPw] = useState('');
    const [cfmPw, setCfmPw] = useState('');

    useEffect(() => {
        httpClient.get('/user/profile')
            .then(function (data) {
                setUserInfo(data);
                setDisp(data.displayName);
            });
    }, []);

    const resetDispName = () => {
        httpClient.post("/user/chg-display", {"dispName": disp})
            .then(function (data) {
                var info = JSON.parse(localStorage.getItem('userInfo'));
                info.displayName = disp;
                localStorage.setItem("userInfo", JSON.stringify(info));
                if (loginCtx.loginUser) {
                    loginCtx.setLoginUser(prev => {
                        prev.displayName = disp;
                        return prev;
                    });
                } else {
                    loginCtx.setLoginUser(info);
                }
                setUserInfo(prev => {
                    prev.displayName = disp;
                    return prev;
                });
                alert(data.msg);
                navigate('/profile', {replace: true});
            })
            .catch(function (error) {
                alert(error)
            });
    };

    return (
        <Stack sx={{m: 2, width: '50vw'}} spacing={2}>
            <Typography variant="h3" className='font' color={theme.palette.text.primary}
                        sx={{alignContent: 'center', alignItems: 'center'}}>
                Welcome @{userInfo?.username} !&nbsp;
                <span className="font size-15 badge badge-warning">{UserType(userInfo?.userType)}</span>
            </Typography>
            <Table>
                <TableBody>
                    <StyledTableRow>
                        <TableCell>
                            <Typography variant="h5" align={'left'} className='font'
                                        color={theme.palette.text.primary}>Email: {userInfo?.email}</Typography>
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>
                            <TextField
                                label={'Display Name'}
                                value={disp}
                                variant="standard"
                                onChange={(e) => setDisp(prev => {
                                    validate(e.target.value, dispRules, setVldDisp);
                                    return e.target.value;
                                })
                                }
                                onBlur={(e) => setDisp((prev => prev.trim().replace(/\s+/ug, ' ')))}
                                error={!vldDisp}
                                className="input-container mt-4"
                            />
                        </TableCell>
                        <TableCell>
                            <Button variant={"contained"} onClick={resetDispName}
                                    disabled={disp === userInfo?.displayName}>Save</Button>
                        </TableCell>
                    </StyledTableRow>
                    {/*<StyledTableRow>*/}
                    {/*    <TableCell>*/}
                    {/*        <TextField label={'Old Password'} value={oldPw} type={'password'} onChange={(e)=>setOldPw(e.target.value)}/>*/}
                    {/*        <TextField label={'New Password'} value={newPw} type={'password'} onChange={(e)=>setNewPw(e.target.value)}/>*/}
                    {/*        <TextField label={'Confirm Password'} value={cfmPw} type={'password'} onChange={(e)=>setCfmPw(e.target.value)}/>*/}
                    {/*    </TableCell>*/}
                    {/*    <TableCell>*/}
                    {/*        <Button variant={"contained"}*/}
                    {/*                disabled={oldPw && newPw && cfmPw && newPw === cfmPw}>Reset</Button>*/}
                    {/*    </TableCell>*/}
                    {/*</StyledTableRow>*/}
                </TableBody>
            </Table>
            <a style={{cursor: 'pointer'}} onClick={() => navigate('/forgot')}
               className="font color-blue  size-14 ">forgot password? reset here!
            </a>
        </Stack>
    );

}
