import React from "react";
import HistNetValModal from "./HistNetValModal";

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Skeleton, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";


export default function AccStat(props) {
    const theme = useTheme();

    function format(label, value, desc) {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Tooltip title={desc ? desc : label} placement="top" arrow>
                    <Typography fontWeight="bold" color="text.primary">{label}</Typography>
                </Tooltip>

                <Box>{
                    value == null
                        ?
                        <Skeleton variant="text"/>
                        :
                        <Typography variant="body2" color="text.primary">
                            {new Intl.NumberFormat('en-HK', {style: 'currency', currency: 'HKD'}).format(Number(value))}
                        </Typography>
                }</Box>
            </Box>
        )
    }

    return (
        <Box sx={{p: 2}}>
            <Stack spacing={1}>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Tooltip title="Your current Net Value" placement="top" arrow>
                                    <Typography  fontWeight="bold" className="font size-15" color="text.primary">Net Value</Typography>
                                </Tooltip>
                                &nbsp;
                                <HistNetValModal/>
                            </Box>
                            <Box>
                                {(props.stat.netVal || props.stat.netVal === 0)
                                    ?
                                    <Typography className="font size-14"
                                        sx={{color: Number(props.stat.netVal) < 0 ? theme.palette.error.main : Number(props.stat.netVal) == 0 ? theme.palette.primary.main : theme.palette.success.main}}
                                        variant="body2">
                                        {new Intl.NumberFormat('en-HK', {
                                            style: 'currency',
                                            currency: 'HKD'
                                        }).format(Number(props.stat.netVal))}
                                    </Typography>
                                    :
                                    <Skeleton variant="text"/>
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Tooltip title="Your Cash" placement="top" arrow>
                                <Typography fontWeight="bold" className="font size-15"  color="text.primary">Cash:</Typography>
                            </Tooltip>
                            {(props.stat?.cash || props.stat?.cash === 0)
                                ?
                                <Typography color="text.primary" className="font size-14"  variant="body2">
                                    {new Intl.NumberFormat('en-HK', {
                                        style: 'currency',
                                        currency: 'HKD'
                                    }).format(Number(props.stat?.cash))}
                                </Typography>
                                : <Skeleton variant="text"/>}
                        </Box>
                    </Grid>
                    <Grid item xs>

                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Tooltip title="Overall Returns" placement="top" arrow>
                                <Typography fontWeight="bold" className="font size-15" color="text.primary">Overall Returns:</Typography>
                            </Tooltip>
                            {(props.stat?.ret || props.stat?.ret === 0)
                                ?
                                <Typography
                                    className="font size-14"
                                    sx={{color: props.stat?.ret < 0 ? theme.palette.error.main : props.stat?.ret == 0 ? theme.palette.primary.main : theme.palette.success.main}}
                                    variant="body2">
                                    {new Intl.NumberFormat('en-HK', {
                                        style: 'currency',
                                        currency: 'HKD'
                                    }).format(Number(props.stat?.ret))}
                                </Typography>
                                : <Skeleton variant="text"/>}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Tooltip title="Profit/Loss of Today" placement="top" arrow>

                                <Typography className="font size-15" fontWeight="bold" color="text.primary">Today's Profit / Loss:</Typography>
                            </Tooltip>
                            {(props.stat.dayPL || props.stat.dayPL === 0)
                                ?
                                <Typography
                                    className="font size-14"
                                    sx={{color: props.stat.dayPL * 100 < 0 ? theme.palette.error.main : props.stat.dayPL * 100 == 0 ? theme.palette.primary.main : theme.palette.success.main}}
                                    variant="body2">
                                    {(props.stat.dayPL * 100).toFixed(2) + '%'}

                                </Typography>
                                : <Skeleton variant="text"/>}
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Tooltip title="Buying Power" placement="top" arrow>
                                <Typography className="font size-15" fontWeight="bold" color="text.primary">Buying Power:</Typography>
                            </Tooltip>
                            {(props.stat?.buyPow || props.stat?.buyPow === 0)
                                ?
                                <Typography className="font size-14" color="text.primary" variant="body2">
                                    {new Intl.NumberFormat('en-HK', {
                                        style: 'currency',
                                        currency: 'HKD'
                                    }).format(Number(props.stat?.buyPow))}
                                </Typography>
                                : <Skeleton variant="text"/>}
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Tooltip title="Profit/Loss of Today" placement="top" arrow>
                                <Typography className="font size-15" fontWeight="bold" color="text.primary">Overall Returns(%):</Typography>
                            </Tooltip>
                            {(props.stat.ret || props.stat.ret === 0)
                                ?
                                <Typography
                                    className="font size-14"
                                    sx={{color: (props.stat.ret / props.stat.netVal) * 100 < 0 ? theme.palette.error.main : (props.stat.ret / props.stat.netVal * 100) == 0 ? theme.palette.primary.main : theme.palette.success.main}}
                                    variant="body2" color="text.primary">
                                    {(props.stat.retRate * 100).toFixed(2) + '%'}
                                </Typography>
                                :
                                <Skeleton variant="text"/>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    );
}
