import React, {useState, useEffect, useContext} from "react";
import GameContext from "../contexts/GameContext";
import OwnerLabel from "./OwnerLabel";
import AccStat from "./AccStat";
import httpClient from "../shared/HttpClient";

export default function AccInfoPane() {
    const gameCtx = useContext(GameContext);
    const [state, setState] = useState({});

    useEffect(() => {
        if(gameCtx.viewAcc){
            httpClient.get(`/account/${gameCtx.viewAcc}/stat`)
                .then(function (data) {
                    setState({
                        netVal: data.netVal,
                        dayPL: data.dayPL,
                        cash: data.cash,
                        buyPow: data.buyPow,
                        ret: data.ret,
                        retRate: data.retRate,
                        updTime: data.updTime,
                    });
                });
        }
    }, [gameCtx.viewAcc]);

    return (
        <>
            <OwnerLabel
                owner={gameCtx.viewPlyr}
                updTime={state?.updTime}
            />
            <AccStat stat={state}/>
        </>
    );
}
