import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box, TableRow, TableCell, Typography } from "@mui/material";
import { useContext } from "react";
import GameContext from "../../contexts/GameContext";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";

export default function WatchlistItem({ item }) {
    const location = useLocation();
    const navigate = useNavigate();
    const gameCtx = useContext(GameContext);
    const [, setSearchParams] = useSearchParams();

    const theme = useTheme();

    const clickHandler = (id) => {
        if (location.pathname.search('/mkt') === -1) {
            navigate(`/mkt/${gameCtx.gid}?stk=${id}`);
        } else {
            setSearchParams({stk: id});
        }
    }

    return (
        <TableRow
            hover 
            selected={item.id === gameCtx.viewStk?.id}
            onClick={() => clickHandler(item.id)}
            >
            <TableCell>
                <Typography className="font size-13 " color={theme.palette.warning.main}>
                    {item.name}
                </Typography>
                <Typography className="font size-13 ">
                    {item.symbol}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography className="font size-13 " align='right' sx={{ color: item.chg >= 0 ? theme.palette.success.main : theme.palette.error.main }}>
                    ${item.price?.toFixed(3)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography className="font size-13 " align='right' sx={{ color: item.chg >= 0 ? theme.palette.success.main : theme.palette.error.main }}>
                    {Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                        signDisplay: 'always',
                    }).format(item.chg)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography 
                className="font size-13 "
                    align='center'
                    sx={{
                        background: item.chg >= 0 ? theme.palette.success.dark : theme.palette.error.dark,
                        pl: 0.5,
                        pr: 0.5,
                        borderRadius: 1,
                }}>
                    {Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        signDisplay: 'always',
                    }).format(item.chg / item.price * 100)}%
                </Typography>
            </TableCell>
        </TableRow>
    )
}
