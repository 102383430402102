import React, {useContext, useState} from "react";
import GameContext from "../contexts/GameContext";
import Holdings from "./Holdings";
import Watchlist from "./Watchlist/Watchlist";
import LayoutConst from "../const/layoutConst";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import {Tooltip, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import FavoriteIcon from '@mui/icons-material/Favorite';
import httpClient from "../shared/HttpClient";
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import WalletIcon from '@mui/icons-material/Wallet';
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export default function CustStkPane() {
    const [show, setShow] = useState(window.location.pathname.search('/acc') > -1 ? "Holdings" : "Watchlist");
    const theme = useTheme();
    const [tabSelected,setTabSelected]=useState(undefined)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open,status) => (event) => {
        
        setTabSelected(status)
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };
    
      const list = (anchor) => (
        <Box
          sx={{ width: {md:anchor === 'top' || anchor === 'bottom' ? 'auto' : 350,xs:450 }}}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          {tabSelected==0 ?<Watchlist /> : <Holdings/>}
          

          
        </Box>
      );

    const gameCtx = useContext(GameContext);

    const handleComponent = (
        _event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setShow(newAlignment);
        }
    };

    const exportHoldings = () => {
        if (gameCtx.viewAcc) {
            httpClient.get(`/account/${gameCtx.viewAcc}/holdings-export`,{responseType: 'blob'})
                .then((data) => {
                    const href = URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `account${gameCtx.viewAcc}_holdings.csv`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                });
        }
    }

    return (
        <Stack sx={{maxHeight: LayoutConst.page.height}}>
        {['left', 'right'].map((anchor='left',index) => (
        <React.Fragment key={anchor}>
          <Tooltip title={index==0? 'Watchlist':'Holdings'} placement="right-start">
          <ListItem key={index}  id={index.toString()+'list'} disablePadding onClick={toggleDrawer('left', true,index)}>
                <ListItemButton>
                  <ListItemIcon className="drawer-list">
                    {index === 0 ? <FavoriteIcon /> : <WalletIcon/>}
                  </ListItemIcon>
                 
                </ListItemButton>
              </ListItem>
            </Tooltip>

          
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{position:'relative'}}
          >
            <Box display="flex">
              <Typography className="font size-18 font-weight-bold" sx={{ ml: 2, my: "auto" }}>
                {
                  tabSelected == 0 ?
                    <Box><FavoriteIcon/> Watchlist</Box>
                  :
                    <Box>
                      <WalletIcon/> Holdings
                      <Tooltip title="Export your orders" placement="bottom" arrow>
                        <IconButton onClick={exportHoldings}>
                            <DownloadForOfflineIcon/>
                        </IconButton>
                      </Tooltip>
                    </Box>
                }
              </Typography>
              <IconButton size="medium" onClick={()=>setState({ ...state, [anchor]: false })} sx={{width:'fit-content',ml:'auto',mt:1,mb:1}}>
                <ChevronLeftIcon />
              </IconButton>
            </Box>
            <Divider ></Divider>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
            {/*{show === "Watchlist" ? <Watchlist/> : <Holdings/>}*/}
        </Stack>
    );

}
