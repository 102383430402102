import React, { useState, useContext } from 'react';
import NotificationModal from '../shared/NotificationModal';

import Typography from '@mui/material/Typography';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import Stack from "@mui/material/Stack";
import httpClient from "../shared/HttpClient";

export default function GameKeyModal(props) {
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");

    const [gameKey, setGameKey] = useState("");
    const [gameKeyError, setGameKeyError] = useState(false);
    const [gameKeyHelperText, setGameKeyHelperText] = useState("");

    const handleCloseNM = () => {
        setOpenNotificationModal(false);
        props.handleClose();
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle className='font size-16'>
                Join Game (id: {props.gid})
            </DialogTitle>
            <DialogContent>
                <Stack>
                    <Typography sx={{mb: 1}} className='font size-16'>GAME KEY:</Typography>
                    <TextField
                        type="text"
                        className='font input-container'
                        error={gameKeyError}
                        helperText={gameKeyHelperText}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={gameKey}
                        variant="outlined"
                        onChange={(e) => {
                            if (false) { //TODO
                                //setGameKey('');
                                setGameKeyError(true);
                                setGameKeyHelperText("can not start with ...");
                            } else {
                                setGameKey(e.target.value);
                                setGameKeyError(false);
                                setGameKeyHelperText("");
                            }
                        }}
                    />
                    <Typography variant="caption" className='font size-13'>
                        *This is a private game. To join this game, you need to input the KEY of this game.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="medium" className='font '
                        onClick={() => {
                            var body = {
                                gid: props.gid,
                                gameKey: gameKey,
                            };

                            httpClient.post("/game/join", body)
                                .then(function (data) {
                                    setNotification("Game joined successfully.");
                                    setLocation("/games");
                                    setButtonText("Back to game page")
                                    setOpenNotificationModal(true);
                                    props.refetch();
                                })
                                .catch(function (error) {
                                    setNotification("Failed to join. (" + error?.data?.msg + ")");
                                    setLocation("/games");
                                    setButtonText("Back to game page")
                                    setOpenNotificationModal(true);
                                    props.refetch();
                                });
                        }}
                >Join &nbsp;<i className='fa fa-paper-plane-o'></i></Button>
            </DialogActions>

            {openNotificationModal &&
            <NotificationModal open={openNotificationModal}
                               handleClose={handleCloseNM}
                               notification={notification}
                               location={location}
                               buttonText={buttonText}/>}
        </Dialog>
    );
}
