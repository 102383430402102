const LayoutConst = {
    navbar: {
        height: 48,
    },
    page: {
        height: window.innerHeight - 48
    },
}

export default LayoutConst;
