import React, {useState, useContext, useEffect} from "react";
import OrderModal from "../AccountPage/OrderModal"
import Const from "../const/OrderConst"
import GameContext from '../contexts/GameContext'
import {DataGrid} from '@mui/x-data-grid'
import httpClient from "../shared/HttpClient";

export default function OrdersPane() {
    const [orders, setOrders] = useState([]);
    const gameCtx = useContext(GameContext);

    useEffect(() => {
        if (gameCtx.viewAcc) {
            httpClient.get(`/trade/order-history/${gameCtx.viewAcc}`)
                .then(function (data) {
                    setOrders(data);
                });
        }
    }, [gameCtx.viewAcc]);

    const columns = [
        {
            field: 'createTimeUTC',
            headerName: 'Created Time',
            width: 200,
            valueFormatter: (params) => {
                return (new Date(params.value)).toLocaleString();
            },
        },
        {
            field: 'symbol',
            headerName: 'Symbol',
            width: 80,
        },
        {
            field: 'stkName',
            headerName: 'Stock ',
            width: 200,
        },
        {
            field: 'side',
            headerName: 'Side',
            width: 60,
            valueFormatter: (params) => {
                return Const.side[params.value];
            },
        },
        {
            field: 'type',
            width: 200,
            valueFormatter: (params) => {
                return Const.type[params.value];
            },
        },
        {field: 'lmtPrice'},
        {
            field: 'qty',
            width: 80,
        },
        {field: 'avgPrice'},
        {
            field: 'status',
            headerName: 'Status',
            width: 110,
            valueFormatter: (params) => {
                return Const.status[params.value];
            },
        },
        {
            field: '',
            headerName: 'Details',
            width: 120,

            renderCell: (params) => (
                <OrderModal
                    order={params.row}
                    cancelable={params.getValue(params.id, 'status') === 1 ? true : false}
                />
            ),
        },
    ];

    return (
        <DataGrid
            columns={columns}
            rows={orders}
            sx={{border:'1px solid red !important'}}
        />
    );
}
