import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import GameContext from "../contexts/GameContext";
import LayoutConst from "../const/layoutConst";

import Divider from '@mui/material/Divider';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import {TableBody, TableContainer} from "@mui/material";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from "@mui/material/IconButton";
import { notifyServiceDown } from "../shared/errors/sentry-handlers";
import Box from "@mui/material/Box";
import LanguageIcon from '@mui/icons-material/Language';
import httpClient from "../shared/HttpClient";

import Link from '@mui/material/Link';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';

import { pink, red, blue, indigo, grey } from '@mui/material/colors';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        border: 0
    },
}));

export default function CpyInfoPane(props) {
    const [cpy, setCpy] = useState({});
    const gameCtx = useContext(GameContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [companyColor, setCompanyColor] = useState(grey[500]); 
    const [twitterColor, setTwitterColor] = useState(grey[500]); 
    const [linkedinColor, setLinkedinColor] = useState(grey[500]);
    const [facebookColor, setFacebookColor] = useState(grey[500]); 
    const [instagramColor, setInstagramColor] = useState(grey[500]); 
    const [youtubeColor, setYoutubeColor] = useState(grey[500]); 

    useEffect(() => {
        if (gameCtx.viewStk && searchParams.get("stk")) {
            httpClient.get(`/stock/${gameCtx.viewStk.id}/cpy`)
                .then(function (data) {
                    setCpy(data);

                    if (data.link) setCompanyColor(blue[200]); 
                    else setCompanyColor(grey[500]);
                    if (data.twitterLink) setTwitterColor(blue[500]); 
                    else setTwitterColor(grey[500]);
                    if (data.linkedinLink) setLinkedinColor(blue[800]); 
                    else setLinkedinColor(grey[500]);
                    if (data.facebookLink) setFacebookColor(indigo[500]); 
                    else setFacebookColor(grey[500]);
                    if (data.instagramLink) setInstagramColor(pink[400]); 
                    else setInstagramColor(grey[500]);
                    if (data.youtubeLink) setYoutubeColor(red[500]); 
                    else setYoutubeColor(grey[500]);
                });
        } else {
            setCpy([]);
        }
    }, [gameCtx.viewStk]);


    const listItems = [
        {label: 'Name:', text: cpy.stkName,},
        {
            label: 'Listing:', text: cpy.listingDate,
            format: (value) => {
                return (new Date(value)).toLocaleDateString('en-HK', {year: 'numeric', month: 'long', day: 'numeric'})
            },
        },
        {
            label: 'Description:', text: cpy.desc ? cpy.desc :'N/A', //TODO: fetch company description
        },
    ];

    return (
        <Card>
            <CardContent sx={{textAlign:'left'}}>
                <Typography sx={{display:props.showTitle===false?'none':''}} variant="h6" className="font size-20 font-weight-bold">
                    Company Overview
                </Typography>
                <Divider sx={{display:props.showTitle===false?'none':''}} variant="middle"/>
                <TableContainer sx={{maxHeight: LayoutConst.page.height / 2}}>
                    <Table size="small" className="mt-2">
                        <TableBody>
                            {listItems.map((listItem) => (
                                <TableRow>
                                    <StyledTableCell >
                                        <strong className="font size-13">{listItem.label}</strong>
                                    </StyledTableCell>
                                    <StyledTableCell className="font size-13" sx={{minWidth: 30}}>
                                        {listItem.text ? (listItem.format ? listItem.format(listItem.text) : listItem.text)
                                            : "N/A"}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    <IconButton disabled={!cpy.link} onClick={() => window.open(cpy.link, '_blank')}>
                        <LanguageIcon sx={{ color: companyColor }}/>
                    </IconButton>
                    <IconButton disabled={!cpy.twitterLink} onClick={() => window.open(cpy.twitterLink, '_blank')}>
                        <TwitterIcon sx={{ color: twitterColor }}/>
                    </IconButton>
                    <IconButton disabled={!cpy.linkedinLink} onClick={() => window.open(cpy.linkedinLink, '_blank')}>
                        <LinkedInIcon sx={{ color: linkedinColor }}/>
                    </IconButton>
                    <IconButton disabled={!cpy.facebookLink} onClick={() => window.open(cpy.facebookLink, '_blank')}>
                        <FacebookIcon sx={{ color: facebookColor }}/>
                    </IconButton>
                    <IconButton disabled={!cpy.instagramLink} onClick={() => window.open(cpy.instagramLink, '_blank')}>
                        <InstagramIcon sx={{ color: instagramColor }}/>
                    </IconButton>
                    <IconButton disabled={!cpy.youtubeLink} onClick={() => window.open(cpy.youtubeLink, '_blank')}>
                        <YouTubeIcon sx={{ color: youtubeColor }}/>
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
}
