import React, {useContext, useEffect, useState} from "react";

import {Box, Divider, Skeleton} from '@mui/material';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import GameContext from '../contexts/GameContext'
import UtilContext from "../contexts/UtilContext";
import { notifyServiceDown } from "../shared/errors/sentry-handlers";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import httpClient from "../shared/HttpClient";

function details(label, value, format) {
    return (
        <Typography className="font size-13 mt-1" variant="body2" color="text.primary">
            <strong >{label}</strong>
            <br/>
            {
            value
            ?
            (format ?
                new Date(value).toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric'})
                + " " + new Date(value).toLocaleTimeString()
                : value)
            :
            "N/A"
            }
        </Typography>
    )
}

export default function GameInfoPane() {
    const gameCtx = useContext(GameContext);
    const [gameInfo, setGameInfo] = useState({});

    useEffect(() => {
        if (gameCtx.viewPlyr) {
            httpClient.get(`/player/${gameCtx.currPlyr.id}/game-info`)
                .then(function (data) {
                    setGameInfo({
                        gid: data.gid,
                        gname: data.gname,
                        desc: data.desc,
                        nPlyr: data.nPlyr,
                        rank: data.rank,
                        owner: data.owner,
                        startT: data.startT,
                        endT: data.endT,
                        joinT: data.joinT,
                        gameInfo: data.gameInfo,
                    });
                    gameCtx.setContext({ currPlyr: { ...gameCtx.currPlyr, rank: data.rank } });
                });
        }
    }, [gameCtx.viewPlyr]);

    return (
        <Box>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       
                    <Typography  variant="h4" className="font size-20 font-weight-bold pb-1" >
                        {gameInfo?.gname}
                    </Typography>&nbsp;
                    <Typography className="font font-13"  color="text.secondary" gutterBottom>
                        (
                            Game ID: {gameInfo.gid ? gameInfo.gid : <Skeleton variant="text" height={40}/>
                                })
                    </Typography>
        </AccordionSummary>
        <AccordionDetails className="text-left">
          <Typography>
                    {details('Game owner:', gameInfo.owner ? (gameInfo.owner?.dispName + ' @' + gameInfo.owner?.username) : null)}
                    {details('Description:', gameInfo.desc)}
                    {details('Join Time:', gameInfo.joinT, true)}
                    {details('Start Time:', gameInfo.startT, true)}
                    {details('End Time:', gameInfo.endT, true)}
                    <Typography variant="body2" className="mt-1" color="text.primary">
                        <strong className="font size-13 mt-1">No of Players: </strong>{gameInfo.nPlyr}
                    </Typography>
          </Typography>
        </AccordionDetails>
      </Accordion>
           
        </Box>
    );
}
