const OrderConst = {
    status: {
        "1": "New order",
        "2": "Partially filled",
        "3": "Filled",
        "4": "Cancelled",
        "5": "Rejected",
        "6": "Suspended"
    },
    side: {
        "1": "Buy",
        "2": "Sell"
    },
    type: {
        "1": "Enhanced Limit Order (ELO)",
        "2": "Market Order"
    },
}

export default OrderConst;
