import React, { useContext, useEffect, useState } from "react";
import GameContext from "../../contexts/GameContext";
import UtilContext from "../../contexts/UtilContext";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/TableBody";
import TableContainer from '@mui/material/TableContainer';
import WatchlistItem from "./WatchlistItem";
import httpClient from "../HttpClient";

export default function Watchlist() {
    const gameCtx = useContext(GameContext);

    return (
        <TableContainer >
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ alignItems: 'right' }}>
                            <Typography  className="font size-13 font-weight-bold">NAME</Typography>
                            <Typography className="font size-13 font-weight-bold">SYMBOL</Typography>
                        </TableCell>
                        <TableCell align='right' className="font size-13 font-weight-bold">
                            <strong className="font size-13 font-weight-bold">PRICE</strong>
                        </TableCell>
                        <TableCell align="right">
                            <strong className="font size-13 font-weight-bold">CHG</strong>
                        </TableCell>
                        <TableCell align='center'>
                            <strong className="font size-13 font-weight-bold">CHG%</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gameCtx.wl ?
                        (Object.keys(gameCtx.wl).map((key) => (
                            <WatchlistItem className="font size-13 " item={gameCtx.wl[key]} />
                        ))
                        ) : (
                            <Typography>Loading...</Typography>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
