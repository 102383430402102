export function validate(val, rules, setRes) {
    let res;
    if (rules.constructor === Array) {
        res = rules.every(r => r(val));
    } else if (typeof(rules) === 'function') {
        res = rules(val);
    } else {
        throw new Error('Invalid rule')
    }
    if (typeof(setRes) === 'function') {
        setRes(res);
    }
    return res;
}

export const rules = {

    required: () => (val) => {
        return !isEmpty(val);
    },

    /**
     * Alphabetical, numerical characters, or __underscores__
     * This rule is NOT active if `val` is empty. Use with `required` if needed.
     * @returns Validation result
     */
    alphanumericunder: () => (val) => {
        let regex = /^[a-zA-Z0-9_]+$/;
        return isEmpty(val) || regex.exec(val) != null;
    },

    /**
     * This rule is NOT active if `val` is empty. Use with `required` if needed.
     * @returns Validation result
     */
    email: () => (val) => {
        let regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return isEmpty(val) || regex.exec(val) != null;
    },

    /**
     * This rule is NOT active if `val` is empty. Use with `required` if needed.
     * @returns Validation result
     */
    password: () => (val) => {
        let regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;  // 1+ letters; 1+ numbers; 0+ special chars; total 8+ characters
        return isEmpty(val) || regex.exec(val);
    },

    /**
     * This rule is NOT active if `val` is empty. Use with `required` if needed.
     * @param {number} min 
     * @returns Validation result
     */
    minLength: (min) => (val) => {
        return isEmpty(val) || val.length >= min;
    },

    /**
     * This rule is NOT active if `val` is empty. Use with `required` if needed.
     * @param {number} max 
     * @returns Validation result
     */
    maxLength: (max) => (val) => {
        return isEmpty(val) || val.length <= max;
    },

}

function isEmpty(val) {
    if (val == null)
        return true;
    if (typeof(val) === 'string' && val.trim() === '')
        return true;
    return false;
}
