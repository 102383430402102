import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import GameContext from "../contexts/GameContext";

import TradeModal from "./TradeModal";
import NotificationModal from '../shared/NotificationModal';
import LayoutConst from "../const/layoutConst";
import httpClient from "../shared/HttpClient";

import Divider from '@mui/material/Divider';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {Skeleton, TableBody, TableContainer} from "@mui/material";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { useTheme } from "@emotion/react";
import statimg from '../assets/images/statimg.webp'
import { ShowChart } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        border: 0
    },
}));

export default function StkInfoPane(props) {
    const [quote, setQuote] = useState([]);
    const gameCtx = useContext(GameContext);
    const [openTradeModal, setOpenTradeModal] = useState(false);

    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [isWatching, setIsWatching] = useState(undefined);

    const [dsbl, setDsbl] = useState(false);
    const [IconComp, setIconComp] = useState(CircularProgress);
    const [btnTxt, setBtnTxt] = useState('');
    const [handler, setHandler] = useState(() => () => {
    });
    const [searchParams, setSearchParams] = useSearchParams();

    const handleCloseNM = () => setOpenNotificationModal(false);

    const handlePost = () => {
        setHandler(prev => () => {});  // Prevent duplicated fetch
        setIconComp(CircularProgress);
        httpClient.post(`/game/${gameCtx.gid}/watchlist/${gameCtx.viewStk.id}`)
            .then(function (data) {
                gameCtx.fetchWl();
            })
            .catch(function (error) {
                setNotification("Stock added unsuccessfully. (" + error?.data?.msg + ")");
                setLocation(`/mkt/${gameCtx.gid}`);
                setButtonText("Back to market page");
                setOpenNotificationModal(true);
            });
    };

    const handlePostRemove = () => {
        setHandler(prev => () => {
        });  // Prevent duplicated fetch
        setIconComp(CircularProgress);
        httpClient.post(`/game/${gameCtx.gid}/watchlist/${gameCtx.viewStk.id}/remove`)
            .then(function (data) {
                gameCtx.fetchWl();
            })
            .catch(function (error) {
                setNotification("Failed to unwatch");
                setLocation(`/mkt/${gameCtx.gid}`);
                setButtonText("Back to market page");
                setOpenNotificationModal(true);
            });
    };

    useEffect(() => {
        if (gameCtx.viewStk && searchParams.get("stk")) {
            httpClient.get('/stock/' + gameCtx.viewStk.id + '/get-quote')
                .then(function (data) {
                    setQuote(data);
                });
        } else {
            setQuote([]);
        }
    }, [gameCtx.viewStk]);

    const handleCloseTM = () => setOpenTradeModal(false);

    useEffect(() => {
        // Set isWatching state to control the "Watch" button
        if (gameCtx.wl == undefined) {
            setIsWatching(undefined);
        } else {
            setIsWatching(gameCtx.viewStk != undefined && Object.keys(gameCtx.wl).includes(gameCtx.viewStk.id.toString()));
        }
    }, [gameCtx.wl, gameCtx.viewStk]);

    useEffect(() => {
        switch (isWatching) {
            case true:
                setIconComp(HeartBrokenIcon);
                setBtnTxt('Unwatch');
                setDsbl(gameCtx.viewStk ? false : true);
                setHandler(prev => handlePostRemove);
                break;
            case false:
                setIconComp(FavoriteIcon);
                setBtnTxt('Watch');
                setDsbl(gameCtx.viewStk ? false : true);
                setHandler(prev => handlePost);
                break;
            case undefined:
                setIconComp(CircularProgress);
                setBtnTxt('Loading Watchlist');
                setDsbl(true);
                break;
        }
    }, [isWatching, gameCtx.viewStk]);

    const listItems = [
        {label: 'PRICE:', text: quote.curr,},
        {
            label: 'CHG:',
            text: quote.chg,
            format: (value) => {
                return Number(value).toFixed(3)
            },
        },
        {
            label: 'CHG%:',
            text: quote.chgPct * 100,
            format: (value) => {
                return `${Number(value).toFixed(3)}`
            },
        },
        {label: 'HIGH:', text: quote.high,},
        {label: 'LOW:', text: quote.low,},
        {label: 'OPEN:', text: quote.open,},
        {label: 'LAST CLOSE:', text: quote.lastClose,},
    ];

    const theme = useTheme();
    const showChart=(name)=>{
       if(document.getElementById(name)){
        document.getElementById(name).click()
       }
    }

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h6" component="div" align="left" className="font size-20 font-weight-bold">
                    {gameCtx.viewStk ? gameCtx.viewStk.stkName + ' (' + gameCtx.viewStk.symbol + ')' : 'No Stock Selected!'}
                </Typography>
                
                <Button size="medium" variant="outlined" className="font mb-1" sx={{display:!gameCtx.viewStk?'none':'block'}}
                        onClick={()=>showChart(gameCtx.viewStk.stkName )}
                        > View History:&nbsp;&nbsp; <img src={statimg}   width="30%"></img>
                        </Button>
                <Divider variant="middle"/>
                <TableContainer sx={{maxHeight: LayoutConst.page.height / 2}}>
                    <Table size="small" >
                        <TableBody>
                            {listItems.map((listItem) => (
                                <TableRow>
                                    <StyledTableCell >
                                        <strong className="font size-13">{listItem.label}</strong>
                                    </StyledTableCell>
                                    <StyledTableCell className="font size-13" sx={{
                                            minWidth: 30,
                                            color: Number.isNaN(Number(listItem.text)) ? undefined : (quote.chg >= 0 ? theme.palette.success.main : theme.palette.error.main )
                                        }}
                                    >
                                        {listItem.text ? (listItem.format ? listItem.format(listItem.text) : listItem.text)
                                            : "N/A"}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
            <CardActions disableSpacing>
                <Button size="medium" id="tradebtn" variant="outlined" className="font" disabled={!gameCtx.viewStk}
                        onClick={() => setOpenTradeModal(true)}
                        sx={{width: '30%', height: 40}}>Trade</Button>
                <Button size="medium" id="favbtn" className="font" variant="outlined" color="warning" disabled={dsbl} onClick={handler}
                        sx={{justifyContent: 'center', marginLeft: 'auto', width: '50%', height: 40}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}} width={20}>
                        <IconComp size={20} color={dsbl ? 'grey' : 'warning'}/>
                    </Box>
                    &nbsp;{btnTxt}
                </Button>
                {gameCtx.viewStk ?
                    <TradeModal open={openTradeModal} handleClose={handleCloseTM}
                                stock={gameCtx.viewStk}
                                quote={quote}/>
                    : ""
                }
            </CardActions>
            {openNotificationModal &&
            <NotificationModal
                open={openNotificationModal}
                handleClose={handleCloseNM} notification={notification}
                location={location} buttonText={buttonText}/>}
        </Card>
    );

}
