import * as Sentry from '@sentry/react';

export const notifyServiceDown = (err) => {
    console.error("Request ERROR: ",err);
    Sentry.captureException(err);
    window.alert("Service unavailable now. We'll be back soon!")
    window.location.replace('/');
}

export const notifyNegRes = (res) => {
    console.log(JSON.stringify(res));
    Sentry.captureMessage(JSON.stringify({status: res?.status, url: res?.url, detail: res}));
    // const clonedRes = res.clone();
    // clonedRes.arrayBuffer().then(buffer => {
    //     const base64 = window.btoa(String.fromCharCode(...new Uint8Array(buffer)))
    //     const resInfo = {
    //         status: clonedRes.status,
    //         statusText: clonedRes.statusText,
    //         url: clonedRes.url,
    //         type: clonedRes.type,
    //         redirected: clonedRes.redirected,
    //         headers: [...clonedRes.headers],
    //         bodyData: base64,
    //     };
    //     console.log(JSON.stringify(resInfo));
    //     Sentry.captureMessage(JSON.stringify({ status: resInfo.status, url: resInfo.url }));
    // });
}
