import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {alpha, styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow, {tableRowClasses} from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import {visuallyHidden} from '@mui/utils';
import CloseIcon from '@mui/icons-material/Close';
import LayoutConst from "../const/layoutConst";
import LinearProgress from "@mui/material/LinearProgress";
import TableFooter from "@mui/material/TableFooter";
import GameContext from "../contexts/GameContext";
import {useSearchParams} from "react-router-dom";
import Popover from "@mui/material/Popover";
import FormControl from "@mui/material/FormControl";
import {Button, InputLabel, Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import httpClient from "../shared/HttpClient";
//import side imgages
import { useTheme } from '@emotion/react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WalletIcon from '@mui/icons-material/Wallet';
import stateimg from '../assets/images/statimg.webp'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  cursor:'pointer',
  color: theme.palette.text.secondary,
}));

interface Data {
    symbol: string;
    stkName: string;
    curr: number;
    chg: number;
    chgPct: number;
    high: number;
    low: number;
    open: number;
    lastClose: number;
    icon:string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    var data1 = Number(a[orderBy])
    var data2 = Number(b[orderBy])
    if (orderBy === "stkName") {
        data1 = a[orderBy].toLowerCase();
        data2 = b[orderBy].toLowerCase();
    }
    if (data2 < data1) return -1;
    else if (data2 > data1) return 1;
    else return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function toConditions(condObjs) {
    const compare = (op, a, b) => {
        switch (op) {
            case '=':
                return a == b;
            case '>':
                return a > b;
            case '<':
                return a < b;
            case '>=':
                return a >= b;
            case '<=':
                return a <= b;
        }
    }
    return condObjs.map(obj => {
        return (row) => compare(obj.content[1], row[obj.content[0]], obj.content[2]);
    });
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'symbol',
        numeric: false,
        disablePadding: false,
        label: 'Symbol',
    },
    {
        id: 'stkName',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'curr',
        numeric: true,
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'chg',
        numeric: true,
        disablePadding: false,
        label: 'Chg',
    },
    {
        id: 'chgPct',
        numeric: true,
        disablePadding: false,
        label: 'Chg%',
    },
    {
        id: 'high',
        numeric: true,
        disablePadding: false,
        label: 'High',
    },
    {
        id: 'low',
        numeric: true,
        disablePadding: false,
        label: 'Low',
    },
    {
        id: 'open',
        numeric: true,
        disablePadding: false,
        label: 'Open',
    },
    {
        id: 'lastClose',
        numeric: true,
        disablePadding: false,
        label: 'Last Close',
    },
    {
        id: 'icon',
        numeric: false,
        disablePadding: false,
        label: 'View Stat',
    },
];

function Filter(props) {
    const {filterId, handleRemove, handleClear} = props;

    const gameCtx = useContext(GameContext);
    const [column, setColumn] = useState(() => {
        var list = gameCtx.filterList;
        if (!list || JSON.stringify(list) === '{}') return '';
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === filterId) {
                return list[i].content[0];
            }
        }
        return '';
    });
    const [operator, setOperator] = useState(() => {
        var list = gameCtx.filterList;
        if (!list || JSON.stringify(list) === '{}') return '';
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === filterId) {
                return list[i].content[1];
            }
        }
        return '';
    });
    const [val, setVal] = useState(() => {
        var list = gameCtx.filterList;
        if (!list || JSON.stringify(list) === '{}') return 0;
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === filterId) {
                return list[i].content[2];
            }
        }
        return 0;
    });

    useEffect(() => {
        if (column === '' || operator === '') return
        var list = gameCtx.filterList;
        if (!list || JSON.stringify(list) === '{}') list = [];
        var found = false;
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === filterId) {
                list[i].content = [column, operator, val];
                found = true;
                break;
            }
        }
        if (!found) list.push({id: filterId, content: [column, operator, val]})
        gameCtx.setContext({
            filterList: list
        });
        console.log("setFilter")
    }, [column, operator, val])

    const clearCondition = () => {
        gameCtx.setContext({filterList: []});
        handleClear();
    }

    const removeFilter = () => {
        var list = gameCtx.filterList;
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === filterId) {
                list.splice(i, 1);
                break;
            }
        }
        gameCtx.setContext({
            filterList: list
        });
        handleRemove(filterId);
    }
  
    return (
        <Stack direction='row' sx={{justifyContent: 'right'}}>
           
            <FormControl variant="standard" className='m-1' fullWidth>
                <InputLabel className="font size-15">Column</InputLabel>
                <Select value={column}
                        onChange={(event) => {
                            setColumn(event.target.value)
                        }}
                >
                    {headCells.map((headCell) => {
                        if (headCell.numeric) {
                            return <MenuItem value={headCell.id}
                                             className="font size-14">{headCell.label}</MenuItem>
                        }
                    })}
                </Select>
            </FormControl>
            <FormControl variant="standard" className='m-1'  fullWidth>
                <InputLabel className="font size-15">Operator</InputLabel>
                <Select value={operator}
                        onChange={(event) => {
                            setOperator(event.target.value)
                        }}
                >
                    <MenuItem value={'='} className="font size-14">=</MenuItem>
                    <MenuItem value={'>'} className="font size-14">{'>'}</MenuItem>
                    <MenuItem value={'<'} className="font size-14">{'<'}</MenuItem>
                    <MenuItem value={'>='} className="font size-14">{'>='}</MenuItem>
                    <MenuItem value={'<='} className="font size-14">{'<='}</MenuItem>
                </Select>
            </FormControl>
            <TextField
            className='m-1'
             fullWidth
                required
                label="Value"
                value={val}
                type="number"
                variant="standard"
                onChange={(e) => {
                    setVal(parseFloat(e.target.value));
                }}
            />
             {filterId === 0 ?
                <Button size="small" onClick={() => clearCondition()}>Clear Filter</Button> :
                <IconButton size="small" onClick={removeFilter}>
                    <CloseIcon/>
                </IconButton>
            }
        </Stack>
    );
}

export default function EnhancedTable(props) {
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        [`&.${tableRowClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontWeight: 'bold'
        }
    }));

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('symbol');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const gameCtx = useContext(GameContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [rows, setRows] = React.useState([]);
    const [conditions, setConditions] = React.useState([]);
    const [sector, setSector] = useState('hsi');
    const [filterListIdx, setFilterListIdx] = useState(() => {
        gameCtx.setContext({filterList: []});
        return [0];
    });
    const [counter, setCounter] = useState(1);
    const [connector, setConnector] = useState('and');

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleRemove = (id) => {
        setFilterListIdx(filterListIdx.filter(a => a !== id));
    };

    const handleClear = () => {
        setAnchorEl(null);
        setConditions([]);
        setFilterListIdx([0]);
    }

    useEffect(() => {
        var filterList = gameCtx.filterList;
        if (filterList) {
            console.log("Apply condition: " + JSON.stringify(filterList));
            setConditions(toConditions(filterList));
        }
    }, [gameCtx]);

    const filterFunc = (row) => {
        if (conditions.length == 0) return true;
        for (const cond of conditions) {
            if (connector == 'and') {
                if (!cond(row)) return false;
            } else {
                if (cond(row)) return true;
            }
        }
        if (connector == 'and') return true;
        else return false;
    }

    useEffect(() => {
        setRows([]);
        var url = '/stock/list?sector=' + sector;
        if (sector === 'top') {
            url = '/game/' + gameCtx.gid + '/top-stks?limit=' + rowsPerPage;
        }
        httpClient.get(url)
            .then(function (data) {
                setRows(data)
            });
    }, [sector]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    const clickHandler = (stockClicked) => {
       
        setSearchParams({stk: stockClicked.id})
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const openDrawer=(e)=>{
        document.getElementById(e)?.click()
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    
    const theme = useTheme();

    return (
        <>
        <Grid container spacing={2} p={2} sx={{display:{xs:'',md:'none'}}}>
        <Grid item xs={6}>
            <Item onClick={()=>openDrawer('0list')}>
            <FavoriteIcon></FavoriteIcon> WatchList
            </Item>
            
        </Grid>
        <Grid item xs={6}>
            <Item  onClick={()=>openDrawer('1list')}>
                <WalletIcon></WalletIcon> Holdings
            </Item>
        </Grid>
        
        </Grid>
        <TableContainer sx={{height: LayoutConst.page.height }}>
            <Table sx={{minWidth: 500,display: { xs: 'none', md: 'block' }}}  size="medium" stickyHeader >
                <TableHead>
                    <StyledTableRow>
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                className="font size-13 font-weight-bold"
                                width='10%'
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.length == 0 ? (
                        <TableRow>
                            <TableCell colSpan={10} align={"center"}>
                                <Typography variant={"overline"} className="font size-15" color={'inherit'}
                                            fontWeight={'bold'}>
                                    Loading ...
                                </Typography>
                                <LinearProgress color={'inherit'}/>
                            </TableCell>
                        </TableRow>) : ''
                    }
                    {stableSort(rows.filter(filterFunc), getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    onClick={() => clickHandler(row)}
                                    tabIndex={-1}
                                    key={row.symbol}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        className="font size-14"
                                        sx={{width:'10%'}}
                                    >
                                        {row.symbol}
                                    </TableCell>
                                    <TableCell className="font size-14" sx={{ color: theme.palette.warning.main ,width:'10%'}}>{row.stkName}</TableCell>
                                    <TableCell className="font size-14" align="right" sx={{ color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main ,width:'10%'}}>{row.curr}</TableCell>
                                    <TableCell className="font size-14"
                                        sx={{ color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main,width:'10%' }}
                                        align="right">{Number(row.chg).toFixed(3)}</TableCell>
                                    <TableCell className="font size-14" align="right"
                                        sx={{ color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main ,width:'10%'}}
                                    >
                                        {Number(row.chgPct).toFixed(3)}%

                                    </TableCell>
                                    <TableCell className="font size-14" align="right" sx={{ color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main ,width:'10%'}}>{row.high}</TableCell>
                                    <TableCell className="font size-14" align="right" sx={{ color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main ,width:'10%'}}>{row.low}</TableCell>
                                    <TableCell className="font size-14" align="right" sx={{ color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main ,width:'10%'}}>{row.open}</TableCell>
                                    <TableCell className="font size-14" align="right" sx={{ color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main ,width:'10%'}}>{row.lastClose}</TableCell>
                                    <TableCell  className="font size-14" sx={{width:'10%'}}align="left">
                                        <img id={row.stkName} src={stateimg} onClick={()=>props.events(row.stkName)}  width="100%"></img>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 33 * emptyRows,}}>
                            <TableCell colSpan={7}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={1}>
                            <IconButton size="small" onClick={(e) => {
                                setAnchorEl(e.currentTarget)
                            }}>
                                <FilterListIcon/> &nbsp; Filter
                            </IconButton>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Stack spacing={1} sx={{p: 2, minWidth: 400}}>
                                    {filterListIdx.map((filterIdx) => (
                                        <Filter filterId={filterIdx}
                                                handleRemove={handleRemove}
                                                handleClear={handleClear}/>
                                    ))}
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Button onClick={() => {
                                            setFilterListIdx(current => [...current, counter]);
                                            setCounter(current => current + 1);
                                        }}>+ ADD NEW FILTER</Button>
                                        {filterListIdx.length > 0 ?
                                            <FormControl variant="standard">
                                                <Select value={connector}
                                                        onChange={(event) => {
                                                            setConnector(event.target.value)
                                                        }}
                                                >
                                                    <MenuItem value={'and'} className="font size-14">AND</MenuItem>
                                                    <MenuItem value={'or'} className="font size-14">OR</MenuItem>
                                                </Select>
                                            </FormControl> : ''}
                                    </Box>
                                </Stack>
                            </Popover>
                        </TableCell>
                        <TableCell colSpan={3}>
                            <FormControl variant="standard">
                                <InputLabel className="font size-15">Sector </InputLabel>
                                <Select value={sector}
                                        onChange={(event) => {
                                            setSector(event.target.value)
                                        }}
                                >
                                    <MenuItem value={'hsi'} className="font size-14">HSI</MenuItem>
                                    <MenuItem value={'top'} className="font size-14">
                                        Top Records &nbsp;
                                        <Tooltip arrow
                                                 title="Ordered by the total value of traded stocks in this game!">
                                            <i className="fa fa-question-circle"/>
                                        </Tooltip>
                                    </MenuItem>
                                    <MenuItem value={''} className="font size-14">All Stocks</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell colSpan={6}>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={rows.filter(filterFunc).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            
            <Box sx={{width:'100%',margin:'5px',border:'1px solid',display:'none'}}>
             
             <FormControl variant="standard"  sx={{width:'100%'}}>
                     <InputLabel className="font size-15">Sector </InputLabel>
                     <Select value={sector}
                             onChange={(event) => {
                                 setSector(event.target.value)
                             }}
                     >
                         <MenuItem value={'hsi'} className="font size-14">HSI</MenuItem>
                         <MenuItem value={'top'} className="font size-14">
                             Top Records &nbsp;
                             <Tooltip arrow
                                      title="Ordered by the total value of traded stocks in this game!">
                                 <i className="fa fa-question-circle"/>
                             </Tooltip>
                         </MenuItem>
                         <MenuItem value={''} className="font size-14">All Stocks</MenuItem>
                     </Select>
                 </FormControl>
             
     </Box>
            <Table  sx={{minWidth: 500,display: { xs: 'block', md: 'none',marginTop:'5px',marginBottom:'10px' }}} size='small' className='mobile-table' stickyHeader>
               
                <TableHead>
                    <StyledTableRow>
                        {headCells.map((headCell) => (

                            headCell.id=='symbol'||
                            headCell.id=='icon' ||
                            headCell.id=='curr'
                      
                            
                           
                           

                            
                            


                            ?
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'left' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                className="font size-13 font-weight-bold"
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>:''
                        ))}
                        
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.length == 0 ? (
                        <TableRow>
                            <TableCell colSpan={10} align={"center"}>
                                <Typography variant={"overline"} className="font size-15" color={'inherit'}
                                            fontWeight={'bold'}>
                                    Loading ...
                                </Typography>
                                <LinearProgress color={'inherit'}/>
                            </TableCell>
                        </TableRow>) : ''
                    }
                    {stableSort(rows.filter(filterFunc), getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    onClick={() => clickHandler(row)}
                                    tabIndex={-1}
                                    key={row.symbol}
                                >
                                    <TableCell
                                        component="td"
                                        scope="row"
                                        className="font size-14"
                                    >
                                      
                                        <Box className="font size-14" sx={{ color: theme.palette.warning.main }}>{row.stkName}</Box>
                                   
                                        <span>{row.symbol}</span>
                                        
                                    </TableCell>
                                    <TableCell className="font size-14 text-white" align="right" >
                                        
                                        <Button variant="contained" sx={{width:'100%', backgroundColor: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main ,color:'#fff'}} size="small">
                                        {row.chg >= 0 ?<ArrowDropUpIcon></ArrowDropUpIcon>:<ArrowDropDownIcon></ArrowDropDownIcon>}
                                        {row.curr}

                                        
                                            </Button>
                                           
                                          
                                            <Box className="font size-14 "
                                        sx={{ display: 'inline',float:'left',color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main }}
                                        align="right">{Number(row.chg).toFixed(3)}</Box>

                                    <Box className="font size-14 " align="right"
                                        sx={{ display: 'inline',color: row.chg >= 0 ? theme.palette.success.main : theme.palette.error.main }}
                                    >
                                        {Number(row.chgPct).toFixed(3)}%
                                    </Box>
                                        </TableCell>
                                    <TableCell className="font size-14" align="left">
                                        <img src='../stat.png' onClick={()=>props.events(row.stkName)}  width="100%"></img>
                                    </TableCell>
                          
                                   
                                </TableRow>
                            );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 33 * emptyRows,}}>
                            <TableCell colSpan={7}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={1}>
                            <IconButton size="small" onClick={(e) => {
                                setAnchorEl(e.currentTarget)
                            }}>
                                <FilterListIcon/> &nbsp; Filter
                            </IconButton>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Stack spacing={1} sx={{p: 2, minWidth: 400}}>
                                    {filterListIdx.map((filterIdx) => (
                                        <Filter filterId={filterIdx}
                                                handleRemove={handleRemove}
                                                handleClear={handleClear}/>
                                    ))}
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Button onClick={() => {
                                            setFilterListIdx(current => [...current, counter]);
                                            setCounter(current => current + 1);
                                        }}>+ ADD NEW FILTER</Button>
                                        {filterListIdx.length > 0 ?
                                            <FormControl variant="standard">
                                                <Select value={connector}
                                                        onChange={(event) => {
                                                            setConnector(event.target.value)
                                                        }}
                                                >
                                                    <MenuItem value={'and'} className="font size-14">AND</MenuItem>
                                                    <MenuItem value={'or'} className="font size-14">OR</MenuItem>
                                                </Select>
                                            </FormControl> : ''}
                                    </Box>
                                </Stack>
                            </Popover>
                        </TableCell>
                        <TableCell sx={{display:'none'}} colSpan={3}>
                            <FormControl variant="standard">
                                <InputLabel className="font size-15">Sector </InputLabel>
                                <Select value={sector}
                                        onChange={(event) => {
                                            setSector(event.target.value)
                                        }}
                                >
                                    <MenuItem value={'hsi'} className="font size-14">HSI</MenuItem>
                                    <MenuItem value={'top'} className="font size-14">
                                        Top Records &nbsp;
                                        <Tooltip arrow
                                                 title="Ordered by the total value of traded stocks in this game!">
                                            <i className="fa fa-question-circle"/>
                                        </Tooltip>
                                    </MenuItem>
                                    <MenuItem value={''} className="font size-14">All Stocks</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell colSpan={6}>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={rows.filter(filterFunc).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        </>
    );
}
