import React, {useState, useEffect, useContext} from "react";
import UserMgtPane from "./UserMgtPane";
import StockUpdatePane from "./StockUpdatePane";
import LayoutConst from "../const/layoutConst";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

export default function AppMgtPage() {
    return (
        <Grid container spacing={1} sx={{display: 'flex', justifyContent: 'space-around'}}>
            <Grid item xs={5}>
                <Stack sx={{height: LayoutConst.page.height}}>
                    <UserMgtPane/>
                </Stack>
            </Grid>

            <Grid item xs={5}>
                <Stack sx={{height: LayoutConst.page.height}}>
                    <StockUpdatePane/>
                </Stack>
            </Grid>
        </Grid>
    );
}
