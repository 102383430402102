import React, {useEffect, useState} from "react";
import {format} from "d3-format";
import {timeFormat} from "d3-time-format";
import {
    elderRay,
    ema,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CurrentCoordinate,
    BarSeries,
    CandlestickSeries,
    ElderRaySeries,
    LineSeries,
    MovingAverageTooltip,
    OHLCTooltip,
    SingleValueTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    sma,
    bollingerBand,
    BollingerBandTooltip,
    BollingerSeries
} from "react-financial-charts";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Checkbox, Divider, FormControlLabel, FormGroup, Switch, Tooltip, Typography} from "@mui/material";

export default function StockChart(props) {
    const theme = useTheme();

    const initialData = props.data;

    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => new Date(d.date));
    const height = props.height;
    const width = props.width;
    const margin = {left: 0, right: 50, top: 10, bottom: 20};

    const [moreOpts, setMoreOpts] = useState(false);

    const [showEma12, setShowEma12] = useState(true);
    const ema12 = ema()
        .id(1)
        .options({windowSize: 12})
        .merge((d, c) => {
            d.ema12 = c;
        })
        .accessor((d) => d.ema12);
    const [showEma26, setShowEma26] = useState(true);
    const ema26 = ema()
        .id(2)
        .options({windowSize: 26})
        .merge((d, c) => {
            d.ema26 = c;
        })
        .accessor((d) => d.ema26);
    const [showSma50, setShowSma50] = useState(false);
    const sma50 = sma()
        .id(3)
        .options({windowSize: 50})
        .merge((d, c) => {
            d.smaVolume50 = c;
        })
        .accessor((d) => d.smaVolume50);
    const [showBollinger, setShowBollinger] = useState(false);
    const bollinger = bollingerBand()
        .id(4)
        .options({windowSize: 20, multiplier: 2, movingAverageType: "sma"})
        .merge((d, c) => {
            d.bollingerBand = c;
        })
        .accessor((d) => d.bollingerBand);
    const [showEma50, setShowEma50] = useState(true);
    const ema50 = ema()
        .id(5)
        .options({windowSize: 50})
        .merge((d, c) => {
            d.ema50 = c;
        })
        .accessor((d) => d.ema50);


    const [emaOpt, setEmaOpt] = useState([]);
    useEffect(() => {
        let options = [];
        if (showEma12) {
            options.push({
                yAccessor: ema12.accessor(),
                type: "EMA",
                stroke: ema12.stroke(),
                windowSize: ema12.options().windowSize,
            })
        }
        if (showEma26) {
            options.push({
                yAccessor: ema26.accessor(),
                type: "EMA",
                stroke: ema26.stroke(),
                windowSize: ema26.options().windowSize,
            })
        }
        if (showSma50) {
            options.push({
                yAccessor: sma50.accessor(),
                type: "SMA",
                stroke: sma50.stroke(),
                windowSize: sma50.options().windowSize,
            })
        }
        setEmaOpt(options);
        if (showEma50) {
            options.push({
                yAccessor: ema50.accessor(),
                type: "EMA",
                stroke: ema50.stroke(),
                windowSize: ema50.options().windowSize,
            })
        }
    }, [showEma12, showEma26, showSma50, showEma50]);

    const elder = elderRay();
    elder(ema12(initialData));
    elder(ema26(initialData));
    elder(sma50(initialData));
    elder(bollinger(initialData));
    elder(ema50(initialData));

    const {data, xScale, xAccessor, displayXAccessor} = ScaleProvider(initialData);
    const pricesDisplayFormat = format(".2f");
    const max = xAccessor(data[data.length - 1]);
    const min = xAccessor(data[Math.max(0, data.length - 100)]);
    const xExtents = [min, max + 5];

    const gridHeight = height - margin.top - margin.bottom;

    const elderRayHeight = 100;
    const elderRayOrigin = (_, h) => [0, h - elderRayHeight];
    const barChartHeight = gridHeight / 4;
    const barChartOrigin = (_, h) => [0, h - barChartHeight - elderRayHeight];
    const chartHeight = gridHeight - elderRayHeight;
    const yExtents = (data) => [data.high, data.low];

    const dateTimeFormat = "%d %b";
    const timeDisplayFormat = timeFormat(dateTimeFormat);

    const barChartExtents = (data) => {
        return data.volume;
    };

    const candleChartExtents = (data) => {
        return [data.high, data.low];
    };

    const yEdgeIndicator = (data) => {
        return data.close;
    };

    const volumeColor = (data) => {
        return data.close > data.open
            ? theme.palette.success.main
            : theme.palette.error.main;
    };

    const elderRayColor = {
        bearPower: theme.palette.error.main,
        bullPower: theme.palette.success.main
    };

    const volumeSeries = (data) => {
        return data.volume;
    };

    useEffect(() => {
        //hide refresh button
        document.getElementsByClassName("react-financial-charts-enable-interaction reset")[0].style.display = "none";
        document.getElementsByClassName("react-financial-charts-button")[2].style.display = "none";
        document.querySelector("path[d='M2.35 2.35A7.958 7.958 0 018 0a8 8 0 110 16c-3.73 0-6.84-2.55-7.73-6h2.08c.82 2.33 3.04 4 5.65 4A6 6 0 108 2c-1.66 0-3.14.69-4.22 1.78L7 7H0V0l2.35 2.35z']").style.display = "none";
    }, []);

    return (
        <Box>
            <ChartCanvas
                height={height}
                ratio={3}
                width={width}
                margin={margin}
                data={data}
                displayXAccessor={displayXAccessor}
                seriesName="Data"
                xScale={xScale}
                xAccessor={xAccessor}
                xExtents={xExtents}
                zoomAnchor={lastVisibleItemBasedZoomAnchor}
            >
                <Chart
                    id={2}
                    height={barChartHeight}
                    origin={barChartOrigin}
                    yExtents={barChartExtents}
                >
                    <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries}/>
                </Chart>
                <Chart id={3} height={chartHeight} yExtents={candleChartExtents}>
                    <XAxis showGridLines showTickLabel={false}
                           strokeStyle={theme.palette.text.primary}
                           gridLinesStrokeStyle={theme.palette.divider}/>
                    <YAxis showGridLines tickFormat={pricesDisplayFormat}
                           strokeStyle={theme.palette.text.primary}
                           tickStrokeStyle={theme.palette.text.primary}
                           tickLabelFill={theme.palette.text.primary}
                           gridLinesStrokeStyle={theme.palette.divider}/>
                    <CandlestickSeries fill={volumeColor} wickStroke={volumeColor}/>

                    {showEma12 && <>
                        <LineSeries yAccessor={ema12.accessor()} strokeStyle={ema12.stroke()}/>
                        <CurrentCoordinate
                            yAccessor={ema12.accessor()}
                            fillStyle={ema12.stroke()}
                        />
                    </>}
                    {showEma26 && <>
                        <LineSeries yAccessor={ema26.accessor()} strokeStyle={ema26.stroke()}/>
                        <CurrentCoordinate
                            yAccessor={ema26.accessor()}
                            fillStyle={ema26.stroke()}
                        />
                    </>}
                    {showSma50 && <>
                        <LineSeries yAccessor={sma50.accessor()} strokeStyle={sma50.stroke()}/>
                        <CurrentCoordinate
                            yAccessor={sma50.accessor()}
                            fillStyle={sma50.stroke()}
                        />
                    </>}
                    {showEma50 && <>
                        <LineSeries yAccessor={ema50.accessor()} strokeStyle={ema50.stroke()}/>
                        <CurrentCoordinate
                            yAccessor={ema50.accessor()}
                            fillStyle={ema50.stroke()}
                        />
                    </>}
                    <MovingAverageTooltip
                        options={emaOpt}
                        textFill={theme.palette.text.primary}
                        labelFill={theme.palette.primary.dark}
                    />
                    {showBollinger && <>
                        <BollingerSeries yAccessor={bollinger.accessor()}/>
                        <BollingerBandTooltip
                            origin={[width - 270, 0]}
                            options={bollinger.options()}
                            yAccessor={bollinger.accessor()}
                            textFill={theme.palette.text.primary}
                            labelFill={theme.palette.primary.dark}
                        />
                    </>}

                    <MouseCoordinateY
                        rectWidth={margin.right}
                        displayFormat={pricesDisplayFormat}
                        arrowWidth={5}
                    />
                    <EdgeIndicator
                        itemType="last"
                        rectWidth={margin.right}
                        fill={volumeColor}
                        lineStroke={volumeColor}
                        displayFormat={pricesDisplayFormat}
                        yAccessor={yEdgeIndicator}
                        arrowWidth={5}
                    />
                    <ZoomButtons/>
                    <OHLCTooltip
                        textFill={theme.palette.text.primary}
                        labelFill={theme.palette.primary.dark}
                    />
                </Chart>
                <Chart
                    id={4}
                    height={elderRayHeight}
                    yExtents={[0, elder.accessor()]}
                    origin={elderRayOrigin}
                    padding={{top: 5, bottom: 5}}
                >
                    <XAxis showGridLines
                           strokeStyle={theme.palette.text.primary}
                           tickStrokeStyle={theme.palette.text.primary}
                           tickLabelFill={theme.palette.text.primary}
                           gridLinesStrokeStyle={theme.palette.divider}/>
                    <YAxis ticks={4} tickFormat={pricesDisplayFormat}
                           strokeStyle={theme.palette.text.primary}
                           tickStrokeStyle={theme.palette.text.primary}
                           tickLabelFill={theme.palette.text.primary}
                           gridLinesStrokeStyle={theme.palette.divider}/>

                    <MouseCoordinateX displayFormat={timeDisplayFormat}/>
                    <MouseCoordinateY rectWidth={margin.right}
                                      displayFormat={pricesDisplayFormat}/>

                    <ElderRaySeries yAccessor={elder.accessor()}
                                    straightLineStrokeStyle={theme.palette.text.secondary}
                                    fillStyle={elderRayColor}/>

                    <SingleValueTooltip
                        yAccessor={elder.accessor()}
                        yLabel="Elder Ray"
                        labelFill={theme.palette.primary.dark}
                        yDisplayFormat={(d) =>
                            `${pricesDisplayFormat(d.bullPower)}, ${pricesDisplayFormat(d.bearPower)}`
                        }
                        origin={[0, 10]}
                        valueFill={theme.palette.text.primary}
                    />
                </Chart>
                <CrossHairCursor/>
            </ChartCanvas>

            <Divider orientation="horizontal" className="mt-2" variant="fullWidth" flexItem light/>

            <FormGroup row sx={{ml: 2, pt: '5px', pb: '5px'}}>
                <FormControlLabel
                    label={<Typography variant="subtitle2" className="font size-15 color-white">EMA(50)&nbsp;
                        <Tooltip arrow title="50-period exponential moving average">
                            <i className="fa fa-question-circle"></i>
                        </Tooltip>
                    </Typography>}
                    control={<Checkbox defaultChecked size="small" checked={showEma50}
                                       onChange={(event) => {
                                           setShowEma50(event.target.checked)
                                       }}/>}/>
                <FormControlLabel
                    label={<Typography variant="subtitle2" className="font size-15 color-white">EMA(12)&nbsp;
                        <Tooltip arrow title="12-period exponential moving average">
                            <i className="fa fa-question-circle"></i>
                        </Tooltip>
                    </Typography>}
                    control={<Checkbox defaultChecked size="small" checked={showEma12}
                                       onChange={(event) => {
                                           setShowEma12(event.target.checked)
                                       }}/>}/>
                <FormControlLabel
                    label={<Typography variant="subtitle2" className="font size-15 color-white">EMA(26)&nbsp;
                        <Tooltip arrow title="26-period exponential moving average">
                            <i className="fa fa-question-circle"></i>
                        </Tooltip>
                    </Typography>}

                    control={<Checkbox defaultChecked size="small" checked={showEma26}
                                       onChange={(event) => {
                                           setShowEma26(event.target.checked)
                                       }}/>}/>
                <FormControlLabel
                    label={<Typography variant="subtitle2" className="font size-15 color-white">SMA(50)&nbsp;
                        <Tooltip arrow title="50-period simple moving average">
                            <i className="fa fa-question-circle"></i>
                        </Tooltip>
                    </Typography>}
                    control={<Checkbox size="small" checked={showSma50}
                                       onChange={(event) => {
                                           setShowSma50(event.target.checked)
                                       }}/>}/>
                <FormControlLabel
                    label={<Typography variant="subtitle2" className="font size-15 color-white">Bollinger Band&nbsp;
                        <Tooltip arrow title="Bollinger Band, indicating the volatility">
                            <i className="fa fa-question-circle"></i>
                        </Tooltip>
                    </Typography>}
                    control={<Checkbox size="small" checked={showBollinger}
                                       onChange={(event) => {
                                           setShowBollinger(event.target.checked)
                                       }}/>}/>
            </FormGroup>
        </Box>
    );
}
