import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import ExitGameModal from "./ExitGameModal";
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.selected,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function get_game_status(start,end){
    const current_date=new Date()
    const start_date=new Date(start);
    const end_date=new Date(end);
    return current_date < start_date ? (<span className="text-primary weight-600">Not Started</span>) : (current_date > end_date ? <span className="text-danger weight-600">Ended</span> : <span className="text-info weight-600">In Process</span>)

}
export default function GameListPane(props) {
    const navigate = useNavigate();

    const clickHandler = (item) => navigate(`/acc/${item.id}`);

    const [openEGModal, setOpenEGModal] = useState(false);
    const handleCloseEGM = () => setOpenEGModal(false);

    const [gid, setGID] = useState(1);

    return (
        <>
            <Typography variant="h5" className='font size-18 color-white text-left' sx={{p: 1}}>
                Your Games (Click to Enter Game):
            </Typography>
            <div >
            {props.games.map((row) => (
                               <div className='mt-3'>
           
                               <div class="card boxshadow  allgames-section" style={{cursor:'pointer'}} title="Click to enter game" onClick={() => clickHandler(row)}>
                               <div class="card-body">
                                   <div className='row'>
                                       <div className='col-8 color-white text-left'><strong className='font size-15 weight-600'>{row.name}&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                      
                                       <i class="fa fa-clock-o"></i><small className='text-uppercase font size-13'> &nbsp;{get_game_status(((row.start_time_utc)),((row.end_time_utc)))}</small>
                                       &nbsp;&nbsp;&nbsp;
                                       <i class="fa fa-users"></i>&nbsp;
                                       <small className=' text-uppercase size-13 font'>{row.player_num} </small>
                                       </div>
                                       <div className='col-4 text-right '><span class="badge badge-pill badge-secondary p-2 pl-3 pr-3 font size-13 weight-500  d-none">Creator</span>
                                       &nbsp;&nbsp;
                                       <span class="badge badge-pill badge-primary p-2 pl-3 pr-3 font size-13 weight-500" style={{background:'#1b75bb',cursor:'pointer'}} onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setGID(row.id);
                                                                    setOpenEGModal(true);
                                                                }}>
                                       <button className='btn btn-primary btn-nostyle border-0' style={{background: '#1b75bb',cursor:'pointer'}}    type='button'>
                                                    
                                
                                                    
                                                  Exit  <i className='fa fa-paper-plane-o' ></i></button>
                                       </span>
                                       </div>
                                   </div>
                                   <div className='row'><div className='col-12'><hr className='border-color-secondary'></hr></div></div>
                                   <div className='row d-none'><div className='col-12'><p className='color-white text-left size-15 font '>Description</p></div></div>
                                   <div className='row color-white text-left'>
                                     <div className='col-12'>
                                         <div className='row font size-15'>
                                             <div className='col'>Game Id</div>
                                             <div className='col'>Starts On</div>
                                             <div className='col'>Ends In</div>
                                             <div className="col">Inital Cash</div>
                                             
                                     
                                         </div>
                                         <div className='row font size-14 mt-2'>
                                           <div className="col">{row.id}</div>
                                           <div className='col text-primary weight-600 size-14'>{(new Date(row.start_time_utc)).toLocaleString()}</div>
                                           <div className='col text-danger weight-600 size-14'>{(new Date(row.end_time_utc)).toLocaleString()}</div>
                                      
                                           <div className="col">${(Number(row.init_cash)).toLocaleString()}</div>
                                          
                                           
                                   
                                       </div>
                                    
                                     
                                     </div>
                                  
                                     
                                   </div>
                                   <div className='row mt-2'>
                                   <div className='col-12 text-right'>
                                    
                                     </div>
                                   </div>
                                   
                                  
                               </div>
                               
                               </div>
                               
                               </div>
                        ))}
             <ExitGameModal open={openEGModal} handleClose={handleCloseEGM} gid={gid} refetch={props.refetch}/>
            </div>


         
















        
        </>
    );

}
