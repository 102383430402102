import React, { useEffect } from "react";
import httpClient from "../shared/HttpClient";


export default function FAQ(){

    const [faqList, setFaqList] = React.useState([]);
    const fetchFaqList = () => {
        httpClient.get('/faq')
            .then(data => {
                setFaqList(data);
            });
    }
    const faq=(e)=>{
       
        let child=e.target.children[0].classList;
        if(child.contains('fa-angle-down')){
            child.remove('fa-angle-down')
            child.add('fa-angle-up')
        }
        else{
            child.remove('fa-angle-up')
            child.add('fa-angle-down')
        }
    }
    useEffect(fetchFaqList, []);
    const renderFAQ = () => {
        return (
            <div className="col-12 font color-white">
                {faqList.map((item, index) => (
                    <div>
                        <button type="button"  data-toggle="collapse" data-target={`#q-${index}`} onClick={(e)=>faq(e)} className="mb-1 size-14 w-100 btn btn-outline-secondary color-white text-left p-2">{index+1}. {item.question}&nbsp;<i class="fa fa-angle-down float-right p-1" aria-hidden="true"></i></button>
                        <div id={`q-${index}`} className="collapse size-13 p-2">
                            {item.answer}
                        </div>
                    </div>

                ))}
            </div>
        )
    }
    return(
        <div class="card boxshadow allgames-section pt-3 pb-5" title="Click to enter game" >
        <div class="card-body  p-2">
            <div className="row text-left font text-white">
               
                         <div className="col-12 ">
                         <h6 className="p-1 sise-15">Frequenctly Asked Questions </h6>
                         <hr className="border-secondary"></hr>
                         </div>
                         
                         {renderFAQ()}
                 
             
            </div>
        </div>
        </div>
    )
}