import React, {useState} from 'react';

import Box from '@mui/material/Box';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NotificationModal(props) {

    const navigate = useNavigate();

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <DialogContentText variant={"h6"} className="font size-16">
                    {props.notification}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size="medium" className='font' onClick={() => {
                    console.log(props.location);
                    props.handleClose();
                    navigate(props.location);
                }}>
                    {props.buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
