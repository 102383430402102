import React, {useContext, useState} from 'react';
import Const from "../const/OrderConst";
import NotificationModal from "../shared/NotificationModal";
import GameContext from "../contexts/GameContext";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from '@mui/material/TableContainer';
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {IconButton, Collapse} from "@mui/material";
import Box from "@mui/material/Box";
import httpClient from "../shared/HttpClient";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    color: theme.palette.info.dark,
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function FormDialog(props) {
    const gameCtx = useContext(GameContext);
    const descriptionElementRef = React.useRef(null);

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [expanded, setExpanded] = React.useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");

    const handleCloseNM = () => {
        setOpenNotificationModal(false);
        window.location.reload();
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cancelOrder = (orderId) => {
        httpClient.post('/trade/cancel-order', {order: orderId})
            .then(function (data) {
                setNotification(data.msg);
                setLocation(`/acc/${gameCtx.gid}`);
                setButtonText("Back to account page");
                setOpenNotificationModal(true);
            })
            .catch(function (error) {
                setNotification('Error: ' + error?.data?.msg);
                setLocation(`/acc/${gameCtx.gid}`);
                setButtonText("Back to account page");
                setOpenNotificationModal(true);
            });
    }

    const columns = [
        {id: 'id', label: 'Id', align: 'right'},
        {id: 'exePrice', label: 'Exe Price'},
        {id: 'exeQty', label: 'Exe Qty',},
        {
            id: 'txnTimeUTC',
            label: 'Txn Time',
            format: (value) => {
                return (new Date(value)).toLocaleString()
            },
        },
    ];

    const listItems = [
        {
            label: 'CREATE TIME:',
            text: props.order.createTimeUTC,
            format: (value) => {
                return (new Date(value)).toLocaleString()
            },
        },
        {
            label: 'SIDE:',
            text: props.order.side,
            format: (value) => {
                return Const.side[value]
            },
        },
        {
            label: 'TYPE:',
            text: props.order.type,
            format: (value) => {
                return Const.type[value]
            },
        },
        {
            label: 'STATUS:',
            text: props.order.status,
            format: (value) => {
                return Const.status[value]
            },
        },
        {label: 'SYMBOL:', text: props.order.symbol,},
        {label: 'Name:', text: props.order.stkName,},
        {label: 'QUANTITY:', text: props.order.qty,},
        {label: 'LIMIT PRICE:', text: props.order.lmtPrice,},
        {label: 'AVG PRICE:', text: props.order.avgPrice,},
    ];

    return (
        <Box>
            <Button color='primary' variant="outlined" onClick={handleClickOpen('paper')}>
                Details
            </Button>
            <Dialog open={open}
                    onClose={handleClose}
                    scroll={scroll}>
                <DialogTitle className='font size-16'>Order Details - (Order ID: {props.order.id}) </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <Stack spacing={1}>
                        <Card
                            ref={descriptionElementRef}
                            tabIndex={-1}
                            sx={{minWidth: 500}}>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">

                                </Typography>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                    <List>
                                        {listItems.map((listItem) => (
                                            <ListItem>
                                                <ListItemText align='right'>
                                                    <strong className='font size-13'>{listItem.label}</strong>
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <List>
                                        {listItems.map((listItem) => (
                                            <ListItem>
                                                <ListItemText >
                                                    <span className='font size-13'>{listItem.text ? (listItem.format ? listItem.format(listItem.text) : listItem.text) : "N/A"}</span>
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Stack>
                            </CardContent>
                            <CardActions disableSpacing>
                                <Button variant="contained" className='font' color="error" disabled={!props.cancelable}
                                        onClick={(e) => cancelOrder(props.order.id)}>
                                    Cancel Order
                                </Button>
                                <ExpandMore
                                    expand={expanded}
                                    onClick={handleExpandClick}
                                >
                                    {expanded ? '' : <Typography className='font size-14' variant="body1">Transactions</Typography>}
                                    <ExpandMoreIcon/>
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <TableContainer component={Paper}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className='font size-15 font-weight-bold '  colSpan={4}>
                                                    Transactions Details:
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        align={column.align}
                                                    >
                                                        <strong className='font  size-13'>{column.label}</strong>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.order.transactions.map((row) => {
                                                return (
                                                    <TableRow selected>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell className='font size-14' align={column.align}>
                                                                    {column.format ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Collapse>
                        </Card>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color="primary" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {openNotificationModal &&
            <NotificationModal
                open={openNotificationModal}
                handleClose={handleCloseNM} notification={notification}
                location={location} buttonText={buttonText}/>}
        </Box>
    );
}
