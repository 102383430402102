import axios from "axios";
import errorInterceptor from "./interceptors/error";
import updateHeaderInterceptor from "./interceptors/updateHeader";

const altApiUrl = localStorage.getItem('altApiUrl');

const httpClient = axios.create({
    baseURL: altApiUrl ? altApiUrl : process.env.REACT_APP_SERVER_URL,
});
updateHeaderInterceptor(httpClient);
errorInterceptor(httpClient);

export default httpClient;
