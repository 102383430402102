import React, { useState, useEffect, useContext } from 'react';
import LayoutConst from "../const/layoutConst";
import UtilContext from "../contexts/UtilContext";
import NotificationModal from '../shared/NotificationModal';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import { Button, ToggleButtonGroup, ToggleButton, TextField } from '@mui/material';
import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Papa from "papaparse";
import httpClient from "../shared/HttpClient";


export default function StockUpdatePane(props) {
    const { BASE_URL } = useContext(UtilContext);

    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [notification, setNotification] = useState("");

    const [fileInput, setFileInput] = useState(false);

    const handleCloseNM = () => {
        setOpenNotificationModal(false);
    };

    const [formData, setFormData] = useState([]);

    const handleChange=(e)=>{
        let file = e.target.files[0];

        if (file) {
            let reader = new FileReader();

            reader.readAsText(file);

            reader.onerror = function() {
                console.log(reader.error);
            };

            reader.onload = () => {
                const text = reader.result;
                const resultData = Papa.parse(text);
                setFormData(resultData.data);
                console.log(formData);
            };
            setFileInput(true);
        }
    }

    const handleSubmit = () => {
        console.log("formData: " + formData);
        httpClient.post("/stock/cpy/modify", { formData: formData })
            .then(data => {
                console.log('DATA: ' + data);
                setNotification("Company information updated");
                setButtonText("OK");
                setOpenNotificationModal(true);
            }).catch(err => {
                setNotification("Failed to update company information");
                setButtonText("OK");
                setOpenNotificationModal(true);
            })
            var fileInput = document.getElementById("myFile");
            fileInput.value = "";
            setFileInput(false);
    };

    const handleReset = () => {
        var fileInput = document.getElementById("myFile");
        fileInput.value = "";
        setFormData([]);
        setFileInput(false);
    };

    const handleExport = () => {
        httpClient.get('/stock/export',{responseType: 'blob'})
        .then((data) => {
            const href = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Company_Information.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    };

    return (
        <Grid>
            <TableContainer component={Paper} sx={{height: LayoutConst.page.height}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography component='span' variant='h6'>
                        Update Stocks  
                    </Typography>
                </Box>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <input id="myFile" type="file" name="myFile" accept=".csv" onChange={handleChange}/>
                            </TableCell>
                            <TableCell>
                                <Button sx={{ m: 1 }} variant='contained' disabled={!fileInput} onClick={handleSubmit}>
                                    Submit
                                </Button>                                
                                <Button variant='contained' disabled={!fileInput} onClick={handleReset}>
                                    Reset
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography component='span' variant='subtitle1'>
                                        Company Information in csv
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Button sx={{ m: 1 }} variant="outlined" color="warning" type="submit" onClick={handleExport}>
                                        Export
                                    </Button>
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {openNotificationModal && <NotificationModal open={openNotificationModal}
                            handleClose={handleCloseNM} notification={notification} location={location} buttonText={buttonText} />}
        </Grid>
    );
}
