import React, {useContext, useEffect, useState} from "react";
import GameContext from "../contexts/GameContext";
import {useSearchParams} from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useTheme} from "@mui/material/styles";
import httpClient from "../shared/HttpClient";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import IconButton from "@mui/material/IconButton";
import {Tooltip} from "@mui/material";

export default function RankingsPane() {
    const gameCtx = useContext(GameContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();

    const [rankings, setRankings] = useState({
        rankTime: null,
        rankings: [],
    });

    const switchViewPlyr = (row) => {
        setSearchParams({pid: row.pid});
    };

    const exportRankings = () => {
        if (gameCtx.gid) {
            httpClient.get(`/game/${gameCtx.gid}/rankings-export`,{responseType: 'blob'})
                .then((data) => {
                    const href = URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `game${gameCtx.gid}_rankings.csv`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                });
        }
    }

    useEffect(() => {
        if (gameCtx.gid) {
            httpClient.get(`/game/${gameCtx.gid}/rankings`)
                .then(function (data) {
                    setRankings(data);
                });
        }
    }, [gameCtx.gid]);

    return (
        <>
            <Box sx={{mt: 1}} className="text-left pl-3">
                <Typography variant="h5" className="font size-20 font-weight-bold " component="div"
                            color="text.primary">
                    Player Rankings
                    <Tooltip title="Export Rankings" placement="top" arrow>
                        <IconButton onClick={exportRankings}>
                            <DownloadForOfflineIcon/>
                        </IconButton>
                    </Tooltip>
                    <br/>
                    <span className="font badge badge-warning mb-1">Your Rank: {gameCtx?.currPlyr?.rank}</span>
                </Typography>
                <Typography className="font size-13" color="text.secondary" gutterBottom>
                    Update at: {(new Date(rankings.rankTime)).toLocaleString()}
                </Typography>
            </Box>

            <TableContainer component={Paper}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell padding={"none"}>
                                <strong className="font size-13">Name</strong>
                            </TableCell>
                            <TableCell>
                                <strong className="font size-13">Return/Value</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rankings.rankings ? rankings.rankings.map((row) =>
                            <TableRow key={row.pid} hover
                                      sx={{
                                          cursor: 'pointer',
                                          bgcolor: gameCtx?.viewPlyr?.id === row.pid ? theme.palette.warning.light : ''
                                      }}
                                      onClick={(e) => switchViewPlyr(row)}>
                                <TableCell className="font size-13"><strong>{row.rank}</strong></TableCell>
                                <TableCell className="font size-13" padding={"none"}>{row.dispName}</TableCell>
                                <TableCell className="font size-13">
                                    <Box className="font size-13"
                                         sx={{color: row.retRate > 0 ? theme.palette.success.main : theme.palette.error.main}}>
                                        {(row.retRate * 100).toFixed(2)}%<br/>
                                    </Box>
                                    {row.val.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </TableCell>
                            </TableRow>
                        ) : ''}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}
