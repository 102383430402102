import React, { useEffect } from "react";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export default function NotAuth() {
    const navigate = useNavigate();

    const redirect = () => navigate('/');

    return (
        <Alert severity="error" onClick={redirect} action={
            <Button color="inherit" variant="outlined" onClick={redirect}>
                Login
            </Button>
        }>
            Not authenticated to view this page! Please login or change another account!
        </Alert>
    );
}
