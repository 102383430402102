import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@mui/material";

export default function MsgDialog(props) {

    const closeWindow = () => {
        window.close();
    };

    return (
        <Dialog open={props.open}
                onClose={props.closable ? props.handleClose : () => {/* Do nothing */}}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Typography>{props.content}</Typography>
            </DialogContent>
            <DialogActions>
                {props.closable ? <Button color="secondary" onClick={props.handleClose}>Back</Button> : ''}
                <Button color="primary" onClick={closeWindow}>Close Window</Button>
            </DialogActions>
        </Dialog>
    );
}
