import { Box } from "@mui/system";
import { validate, rules } from '../shared/validator';
import { useState, useContext } from "react";
import { TextField } from '@mui/material';
import { Button, Stack } from '@mui/material';
import MsgDialog from './MsgDialog';
import { useNavigate } from "react-router-dom";
import httpClient from "../shared/HttpClient";

export default function ForgotPwPage(props) {
    const navigate = useNavigate();

    const back = () => {
        navigate('/');
    };

    const [email, setEmail] = useState('');
    const [vldEmail, setVldEmail] = useState(true);
    const emailRules = [rules.required(), rules.email()];

    const [openMsg, setOpenMsg] = useState(false);
    const [msgClosable, setMsgClosable] = useState(true);
    const [msgTitle, setMsgTitle] = useState('');
    const [msgContent, setMsgContent] = useState('');


    function validateFields() {
        let res = [];
        res.push(validate(email, emailRules, setVldEmail));
        return res.every(e => e);
    }

    function promptErr(res) {
        setMsgClosable(false);
        setMsgTitle("Error");
        setMsgContent('Error ' + res?.status + ': ' + res?.statusText);
        setOpenMsg(true);
    }

    function promptSuccess() {
        setMsgClosable(false);
        setMsgTitle("Recovery Link");
        setMsgContent('Please check your email for resetting your Simutrade password.: ' + email);
        setOpenMsg(true);
    }

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        httpClient.post("/user/forgot-pw", {email: email})
            .then(function (data) {
                promptSuccess();
            })
            .catch(function (error) {
                promptErr(error);
            });
    }

    return (






<Box sx={{display: 'flex',
       flexWrap: 'wrap',
       alignContent: 'center', }}>

            <div class="container  mt-5">
            <div class="row ">
                <div class="col-12 col-sm-5 ml-auto mr-auto">
                <div class="card text-left login-box">

                <div class="card-body">
                <div className="color-blue"><i className="fa fa-bar-chart"></i>&nbsp;<span class="size-16 font weight-bold ">simutrade</span></div>
            <div><h3 className="title color-white size-25 font weight-600 mt-2">Forgot Password </h3></div>

                <Stack spacing={3} justifyContent="center" alignItems="center">
                <TextField
                    label={'Email'}
                    value={email}
                    variant="standard"
                    className="input-container mt-2"
                    onChange={(e) => setEmail(prev => {
                        validate(e.target.value, emailRules, setVldEmail);
                        return e.target.value;
                    })
                    }
                    error={!vldEmail}
                    sx={{ m: 2 }} />


            </Stack>
            <div className="text-right">
            <Button  className="color-white font " onClick={back}  sx={{ display: 'inline' ,m:2}} variant="outlined" size="large" >Close</Button>
            <Button color="info"  sx={{ display: 'inline' }} className="color-white font bg-blue " variant="contained" onClick={handleSubmit} size="large" >Send Link</Button>

            </div>


            <MsgDialog open={openMsg}
                         closable={msgClosable}
                         onClose={() => setOpenMsg(false)}
                         title={msgTitle}
                         content={msgContent} />




                </div>



            </div>
            </div>

     </div></div>





        </Box>

    );
}
